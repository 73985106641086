<template>
  <div class="newTestReports">
    <!-- v-print="'#newTestReport'" -->
    <div
      class="title_right"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="pdfs"
    >
      <img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />
    </div>
    <!-- <div>导出PDF</div> -->
    <div class="newTestReport" id="newTestReport">
      <div class="title">
        <div class="title_left">
          <dl>
            <dt><img :src="scaleReport.qRCode" alt="" /></dt>
            <dd>
              <!-- <img src="../../assets/img/report/erweima.png" alt="" /> -->
              <p>扫描二维码</p>
              <p>关注“上谷健康”公众号</p>
              <p>查看报告</p>
            </dd>
          </dl>
        </div>
        <div class="title_content">
          <p>{{ scaleReport.hospitalName }}</p>
          <p>{{ scaleReport.scaleName }}</p>
        </div>
        <div></div>
      </div>
      <div class="model_top">
        <div class="model_top_div">
          <div class="model_top_div_one">
            <div class="model_top_item">
              <div class="model_top_item_label">
                <span> 姓名：{{ scaleReport.patientName }} </span>
              </div>
            </div>
            <div class="model_top_item">
              <div class="model_top_item_label">
                <span> 性别：{{ scaleReport.sexStr }}</span>
              </div>
              <div class="model_top_item_value"></div>
            </div>
            <div class="model_top_item">
              <div class="model_top_item_label">
                <span> 年龄：{{ scaleReport.age }}岁 </span>
              </div>
            </div>
            <div class="model_top_item">
              <div class="model_top_item_label">
                <span> 教育年限：{{ scaleReport.educationYears }}年 </span>
              </div>
            </div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">
              <p
                :class="
                  scaleReport.profession.length !== 0 &&
                  scaleReport.profession.length > 8
                    ? 'widt'
                    : ''
                "
              >
                职业： {{ scaleReport.profession }}
              </p>
            </div>
          </div>
        </div>
        <div class="model_top_div">
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 编号：{{ scaleReport.serialNumber }} </span>
            </div>
          </div>
          <!-- <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 测评人：{{ scaleReport.cpDoctorName }} </span>
            </div>
          </div> -->
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 测评时间：{{ scaleReport.cpTimeStr }} </span>
            </div>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>维度</td>
            <td>子维度</td>
            <td>得分</td>
            <td>得分范围</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in scaleReport.dimensionalityList">
            <tr :key="index">
              <td
                :rowspan="item.dimensions.length"
                style="display: table-cell; vertical-align: middle"
              >
                {{ item.name }}
              </td>
              <td>{{ item.dimensions[0].name }}</td>
              <td>{{ item.dimensions[0].score }}</td>
              <td>{{ item.dimensions[0].scoreRange }}</td>
            </tr>
            <tr
              v-for="(ele, inx) in item.dimensions.length - 1"
              :key="index + '-' + inx"
            >
              <td>{{ item.dimensions[ele].name }}</td>
              <td>{{ item.dimensions[ele].score }}</td>
              <td>{{ item.dimensions[ele].scoreRange }}</td>
            </tr>
          </template>
          <tr>
            <td colspan="2">总分</td>
            <td>{{ scaleReport.totalPoints }}</td>
            <td>{{ scaleReport.pointsRange }}</td>
          </tr>
        </tbody>
      </table>
      <div class="modal-desc-content" v-if="scaleReport.reportComment !== ''">
        <div v-html="scaleReport.reportComment"></div>
      </div>
      <div class="modal-desc bor">
        <!--<h1>测评结果说明：</h1>-->
        <!-- <div
          v-html="item.content"
          v-for="(item, index) in scaleReport.scaleReport"
          :key="index"
        ></div> -->
        <div v-html="scaleReport.scaleReport"></div>
      </div>
      <div class="modal-desc">
        <!-- <div
          v-html="item.content"
          v-for="(item, index) in scaleReport.resultContents2"
          :key="index"
        ></div> -->
        <div v-html="scaleReport.totalScoreDesc"></div>
        <!-- <div
          v-html="item.content"
          v-for="(item, index) in scaleReport.resultContents2"
          :key="index"
        ></div> -->
        <div v-html="scaleReport.dimensionsOfDesc"></div>
      </div>

      <div class="modal-descsay">
        <h1>建议</h1>
        <!-- <div
          v-html="item.content"
          v-for="(item, index) in scaleReport.resultContents2"
          :key="index"
        ></div> -->
        <div v-html="scaleReport.suggest"></div>
      </div>
      <!-- <echarts></echarts> -->
      <div class="footerpage">
        <p>{{ scaleReport.signatureName }}：____________</p>
      </div>
      <!-- <div class="modal-desc">
        <h1>脑健康指导建议</h1>
        <div
          v-html="item.content"
          v-for="(item, index) in scaleReport.adviceContents"
          :key="index"
        ></div>
      </div> -->
      <!-- <div class="pageFooter">
        <p>{{ scaleReport.noticeText }}</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import htmlToPdf from "@/assets/js/htmlToPdf";
// import echarts from "./newreports/echarts.vue";
import "../../../assets/font/songti/font.css";
export default {
  // components: { echarts },
  data() {
    return {
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",

      masterId: "",
      utype: "P",
      scaleReport: {
        profession: [],
      }, // 接口数据
    };
  },
  computed: {
    // times() {
    //   let time = "";
    //   if (this.scaleReport.time !== undefined) {
    //     console.log(this.scaleReport.time + "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    //     time = this.scaleReport.time.substring(
    //       0,
    //       this.scaleReport.time.lastIndexOf(":")
    //     );
    //   }
    //   return time;
    // },
  },
  mounted() {
    let afterUrl = window.location.search.substring(1);
    let path = afterUrl.substring(afterUrl.indexOf("=") + 1);
    console.log(path);
    this.masterId = path;
    // masterId = 63ad13b53ee945c6980a11d75e95dd4e
    this.$http
      .get(`report/getOptimizeResult?masterId=${path}&utype=${this.utype}`)
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          console.log(res.data);
          this.scaleReport = res.data;
          if (
            this.scaleReport.profession === "" ||
            this.scaleReport.profession === undefined ||
            this.scaleReport.profession === null
          ) {
            this.scaleReport.profession = "--";
          }
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
    // console.log(t);
    // if (t !== 1) {
    //   // 如果进行了缩放，也就是不是1
    //   document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
    // }
  },
  methods: {
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector("#newTestReport"),
          `${this.scaleReport.patientName}_${this.scaleReport.scaleName}`
        );
      }
    },
  },
};
</script>


<style scoped lang='scss'>
/*stration*/
.newTestReports {
  width: 100%;
  position: relative;
  .title_right {
    position: absolute;
    top: 200px;
    right: 200px;
    img {
      width: 88px;
      height: 88px;
    }
  }
}

#newTestReport {
  font-family: "songti";
  padding: 155px 142px 129px 142px;
  color: #333333;
  .widt {
    width: 404px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .title {
    font-family: "songti";
    height: 143px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_left {
      height: 129px;
      dl {
        display: flex;
        width: 452px;
        dt {
          img {
            width: 129px;
            height: 129px;
          }
        }
        dd {
          // img {
          //   width: calc(109 * 2px);
          //   height: calc(70 * 2px);
          //   margin: calc(5 * 2px) 0 0 calc(3 * 2px);
          // }
          p {
            white-space: nowrap;
            margin-top: 2px;
            margin-left: 10px;
            font-size: 26px;
          }
        }
      }
    }

    .title_content {
      font-family: "songti";
      text-align: center;
      margin-left: -400px;
      p:nth-child(1) {
        font-size: 65px;
        font-family: "songti" !important;
      }
      p:nth-child(2) {
        margin-top: 26px;
        font-size: 52px;
        font-family: "songti" !important;
      }
    }
  }

  .model_top {
    font-family: "songti";
    margin-top: 90px;
    padding-bottom: 26px;
    border-bottom: 3px solid #999;
    .model_top_div {
      display: flex;
      justify-content: space-between;
      .model_top_item {
        .model_top_item_label {
          font-size: 39px;
          color: #000;
          p {
            span {
              width: 3px;
            }
          }
        }
      }
    }

    .model_top_div:nth-child(1) {
      margin-bottom: 14px;
      .model_top_div_one {
        display: flex;
        .model_top_item {
          margin-left: 81px;
        }
        .model_top_item:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }
  table {
    font-family: "songti";
    width: 100%;
    margin-top: 39px;
    border-bottom: none;
    tr {
      td {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
      }
    }

    thead {
      tr {
        td {
          border-top: solid 3px #999;
          border-left: 3px solid #999999;
          border-bottom: 3px solid #999999;
          padding: 10px 65px;
          font-size: 45px;
          font-weight: 400;
          color: #000;
        }
        td:last-child {
          border-right: 3px solid #999999;
        }
      }
    }

    tbody {
      tr {
        td {
          border-left: 3px solid #999999;
          border-bottom: 3px solid #999999;
          font-size: 37px;
          color: #000;
          line-height: 60px;
          padding: 12px 65px;
        }
        td:last-child {
          border-right: 3px solid #999999;
        }
      }
      tr:last-child {
        td:nth-child(1) {
          border-right: none;
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
  //   .modal-desc-content {
  //     margin-top: 8*2px;
  //     border-bottom: 2*2px solid #ccc;
  //     padding-bottom: 8*2px;
  //     font-size: 12*2px;
  //     div {
  //       line-height: 23*2px;
  //     }
  //   }
  .modal-desc {
    font-family: "songti";
    // margin-top: 20*2px;
    border: 3px solid #999;
    font-size: 39px;
    margin-top: -4px;
    margin-bottom: 52px;
    padding: 19px 34px 52px 39px;
    h1 {
      width: 100%;
      display: block;
      font-size: 39px;
      color: #333333;
      font-weight: 400;
    }
    div {
      margin-top: 13px;
      line-height: 60px;
    }
  }
  .bor {
    border-top: none;
  }
  .modal-descsay {
    font-family: "songti";
    color: #000000;
    border: 3px solid #999;

    h1 {
      font-size: 45px;
      border-bottom: 3px solid #999;
      font-weight: 400;
      padding: 10px 39px;
    }
    div {
      font-size: 45px;
      line-height: 68px;
      padding: 13px 39px 52px 39px;
    }
  }
  .footerpage {
    font-family: "songti";
    margin-top: 90px;
    font-size: 39px;
    text-align: right;
  }
  // .pageFooter {
  //   margin-top: calc(20 * 2px);
  //   font-size: calc(14 * 2px);
  //   text-align: center;
  //   color: #777;
  // }
}
</style>
