<template>
  <div>
    <div
      class="image"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="pdfs"
    >
      <img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />
    </div>
    <div class="page flex-col">
      <div class="outer1 flex-col">
        <div class="section1 flex-row">
          <span class="txt1">{{ constants.hospitalName }}</span>
        </div>
        <span class="word1">{{ constants.title }}</span>
        <div class="section2 flex-row">
          <span class="word2">姓名：{{ constants.name }}</span>
          <span class="txt2">性别：{{ constants.sex }}</span>
          <span class="txt3">年龄：{{ constants.age }}岁</span>
          <span class="txt4">教育年限：{{ constants.eduTotal }}年</span>
          <span class="word3">职业：{{ constants.job }}</span>
        </div>
        <div class="section3 flex-row">
          <span class="word4">编号：{{ constants.reportNo }}</span>
          <span class="word5">测评时间：{{ constants.reportDate }}</span>
        </div>
        <div class="section4 flex-col"></div>
        <div class="section5 flex-col">
          <div class="box1 flex-row">
            <div class="main1 flex-col">
              <span class="info1">序号</span>
              <span class="word6">1</span>
              <span class="txt5">2</span>
              <span class="info2">3</span>
              <span class="word7">4</span>
            </div>
            <div class="main2 flex-col"></div>
            <div class="main3 flex-col">
              <span class="txt6">{{ constants.object.psqi3 }}</span>
            </div>
            <div class="main4 flex-col">
              <span class="txt7">实际回答</span>
              <div class="bd1 flex-row">
                <div class="bd2 flex-col">
                  <span class="word8">{{ constants.object.psqi1 }}</span>
                </div>
                <div class="bd3 flex-col">
                  <span class="word9">{{ constants.object.psqi1S2 }}</span>
                </div>
              </div>
              <div class="bd4 flex-col">
                <span class="word10">{{ constants.object.psqi2 }}</span>
              </div>
              <div class="bd5 flex-col">
                <span class="txt8">{{ constants.object.psqi3S2 }}</span>
              </div>
              <div class="bd6 flex-col">
                <span class="word11">{{ constants.object.psqi4 }}</span>
              </div>
            </div>
          </div>
          <div class="box2 flex-col"></div>
          <span
            class="txt9"
            v-html="
              '近一个月，晚上上床睡觉通常是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;时&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;分'
            "
          >
          </span>
          <div class="box3 flex-col"></div>
          <span
            class="info3"
            v-html="
              '近一个月，从上床到入睡通常需要&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;分钟'
            "
          >
          </span>
          <div class="box4 flex-col"></div>
          <span
            class="word12"
            v-html="
              '近一个月，通常早上&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;时&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;分起床'
            "
          >
          </span>
          <div class="box5 flex-col"></div>
          <span
            class="word13"
            v-html="
              '近一个月，每夜通常实际睡眠&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;小时&nbsp;&nbsp;&nbsp;&nbsp;(不等于卧床时间)'
            "
          >
          </span>
        </div>
        <div class="section6 flex-col">
          <div class="outer2 flex-row">
            <div class="section7 flex-col">
              <span class="info4">序号</span>
              <span class="info5">5</span>
              <span class="info6">6</span>
              <span class="txt10">7</span>
              <span class="txt11">8</span>
              <span class="txt12">9</span>
              <span class="word14">10</span>
              <span class="info7">11</span>
              <span class="word15">12</span>
              <span class="txt13">13</span>
              <span class="word16">14</span>
            </div>
            <div class="section8 flex-col"></div>
            <div class="section9 flex-col">
              <span class="txt14">题目</span>
              <span class="txt15">
                近1个月，因入睡困难（30分钟内不能入睡）影响睡眠而烦恼。
              </span>
              <span class="word17">
                近1个月，因夜间易醒或早醒影响睡眠而烦恼。
              </span>
              <span class="txt16">近1个月，因夜间去厕所影响睡眠而烦恼。</span>
              <span class="txt17">近1个月，因呼吸不畅影响睡眠而烦恼。</span>
              <span class="info8">近1个月，因咳嗽或鼾声高影响睡眠而烦恼。</span>
              <span class="word18">近1个月，因感觉冷影响睡眠而烦恼。</span>
              <span class="word19">近1个月，因感觉热影响睡眠而烦恼。</span>
              <span class="word20">近1个月，因做恶梦影响睡眠而烦恼。</span>
              <span class="txt18">近1个月，因疼痛不适影响睡眠而烦恼。</span>
              <span class="word21"
                >近1个月，其他影响睡眠、让您烦恼的事情。</span
              >
            </div>
            <div class="section10 flex-col"></div>
            <div class="section11 flex-col">
              <span class="word22">无</span>
              <div class="icon1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi51 === 0"
                />
              </div>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi52 === 0"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi53 === 0"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi54 === 0"
                />
              </div>
              <div class="icon5">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi55 === 0"
                />
              </div>
              <div class="icon6">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi56 === 0"
                />
              </div>
              <div class="icon7">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi57 === 0"
                />
              </div>
              <div class="icon8">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi58 === 0"
                />
              </div>
              <div class="icon9">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi59 === 0"
                />
              </div>
              <div class="icon10">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi510 === 0"
                />
              </div>
            </div>
            <div class="section12 flex-col"></div>
            <div class="section13 flex-col">
              <span class="info9">＜1次/周</span>
              <div class="icon1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi51 === 1"
                />
              </div>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi52 === 1"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi53 === 1"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi54 === 1"
                />
              </div>
              <div class="icon5">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi55 === 1"
                />
              </div>
              <div class="icon6">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi56 === 1"
                />
              </div>
              <div class="icon7">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi57 === 1"
                />
              </div>
              <div class="icon8">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi58 === 1"
                />
              </div>
              <div class="icon9">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi59 === 1"
                />
              </div>
              <div class="icon10">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi510 === 1"
                />
              </div>
            </div>
            <div class="section14 flex-col"></div>
            <div class="section15 flex-col">
              <span class="word23">1-2次/周</span>
              <div class="icon1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi51 === 2"
                />
              </div>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi52 === 2"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi53 === 2"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi54 === 2"
                />
              </div>
              <div class="icon5">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi55 === 2"
                />
              </div>
              <div class="icon6">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi56 === 2"
                />
              </div>
              <div class="icon7">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi57 === 2"
                />
              </div>
              <div class="icon8">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi58 === 2"
                />
              </div>
              <div class="icon9">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi59 === 2"
                />
              </div>
              <div class="icon10">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi510 === 2"
                />
              </div>
            </div>
            <div class="section16 flex-col"></div>
            <div class="section47 flex-col">
              <span class="word24">≥3次/周</span>
              <div class="icon1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi51 === 3"
                />
              </div>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi52 === 3"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi53 === 3"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi54 === 3"
                />
              </div>
              <div class="icon5">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi55 === 3"
                />
              </div>
              <div class="icon6">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi56 === 3"
                />
              </div>
              <div class="icon7">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi57 === 3"
                />
              </div>
              <div class="icon8">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi58 === 3"
                />
              </div>
              <div class="icon9">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi59 === 3"
                />
              </div>
              <div class="icon10">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi510 === 3"
                />
              </div>
            </div>
          </div>
          <div class="outer3 flex-col"></div>
          <div class="outer4 flex-col"></div>
          <div class="outer5 flex-col"></div>
          <div class="outer6 flex-col"></div>
          <div class="outer7 flex-col"></div>
          <div class="outer8 flex-col"></div>
          <div class="outer9 flex-col"></div>
          <div class="outer10 flex-col"></div>
          <div class="outer11 flex-col"></div>
          <div class="outer12 flex-col"></div>
        </div>
        <span class="word25">{{ constants.hospitalName }}</span>
        <span class="word26">{{ constants.title }}</span>
        <div class="section17 flex-col">
          <div class="group1 flex-row">
            <div class="outer13 flex-col">
              <span class="info10">15</span>
              <span class="info11">16</span>
              <span class="word27">17</span>
              <span class="word28">18</span>
            </div>
            <div class="outer14 flex-col"></div>
            <div class="outer15 flex-col">
              <span class="info12">
                近1个月，总的来说，您认为自己的睡眠质量。
              </span>
              <span class="txt19">近1个月，您用药物催眠的情况。</span>
              <span class="txt20">近1个月，您常感到困倦吗。</span>
              <span class="txt21">近1个月，您做事情的精力不足吗。</span>
            </div>
            <div class="outer16 flex-col"></div>
            <div class="outer17 flex-col">
              <div class="icon1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi6 === 0"
                />
              </div>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi7 === 0"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi8 === 0"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi9 === 0"
                />
              </div>
            </div>
            <div class="outer18 flex-col"></div>
            <div class="outer19 flex-col">
              <div class="icon1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi6 === 1"
                />
              </div>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi7 === 1"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi8 === 1"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi9 === 1"
                />
              </div>
            </div>
            <div class="outer20 flex-col"></div>
            <div class="outer21 flex-col">
              <div class="icon1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi6 === 2"
                />
              </div>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi7 === 2"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi8 === 2"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi9 === 2"
                />
              </div>
            </div>
            <div class="outer22 flex-col"></div>
            <div class="outer23 flex-col">
              <div class="icon1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi6 === 3"
                />
              </div>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi7 === 3"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi8 === 3"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.psqi9 === 3"
                />
              </div>
            </div>
          </div>
          <div class="group2 flex-col"></div>
          <div class="group3 flex-col"></div>
          <div class="group4 flex-col"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../assets/font/songti/font.css";
import htmlToPdf from "@/assets/js/htmlToPdf";
export default {
  data() {
    return {
      constants: [],
      dui: require("../../../assets/img/dui.png"),
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",
    };
  },
  methods: {
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t, document.body.style.zoom);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector(".page"),
          `${this.constants.name}_${this.constants.title}(回显)`
        );
      }
    },
  },
  mounted() {
    // console.log(this.$route.query.masterId);
    let afterUrl = window.location.search.substring(1);
    let path = afterUrl.substring(afterUrl.indexOf("=") + 1);
    this.$http
      .get(`report/getViewResult?masterId=${path}`)
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          this.constants = res.data;
          if (
            this.constants.job === "" ||
            this.constants.job === undefined ||
            this.constants.job === null
          ) {
            this.constants.job = "--";
          }
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style scoped lang='scss'>
/*stration*/
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: "songti";
}
button {
  margin: 0;
  padding: 0;
  border: 1 * 3.22px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.image {
  position: absolute;
  top: 200px;
  right: 200px;
  z-index: 352;
  img {
    width: 22 * 3.22px;
    height: 22 * 3.22px;
  }
}
.page {
  z-index: 1;
  position: relative;
  width: 595 * 3.22px;
  height: 1470 * 3.22px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  justify-content: flex-start;
  padding-top: 48 * 3.22px;
  align-items: center;
  .outer1 {
    z-index: auto;
    width: 507 * 3.22px;
    height: 1014 * 3.22px;
    .section1 {
      z-index: auto;
      width: 363 * 3.22px;
      height: 22 * 3.22px;
      margin-left: 144 * 3.22px;
      justify-content: space-between;
      .txt1 {
        z-index: 3;
        width: 220 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 20 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 20 * 3.22px;
        text-align: center;
      }
      .label1 {
        z-index: 5;
        width: 22 * 3.22px;
        height: 22 * 3.22px;
      }
    }
    .word1 {
      z-index: 4;
      width: 128 * 3.22px;
      display: flex;
      justify-content: center;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 6 * 3.22px;
    }
    .section2 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 28 * 3.22px;
      .word2 {
        z-index: 10;
        width: 72 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .txt2 {
        z-index: 11;
        width: 48 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .txt3 {
        z-index: 12;
        width: 60 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .txt4 {
        z-index: 13;
        width: 84 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 28 * 3.22px;
      }
      .word3 {
        z-index: 14;
        width: 454px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 15 * 3.22px;
      }
    }
    .section3 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 8 * 3.22px;
      justify-content: space-between;
      .word4 {
        z-index: 9;
        width: 96 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .word5 {
        z-index: 8;
        width: 156 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
      }
    }
    .section4 {
      z-index: 7;
      width: 507 * 3.22px;
      height: 1 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-top: 8 * 3.22px;
    }
    .section5 {
      z-index: 26;
      height: 150 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 12 * 3.22px;
      width: 507 * 3.22px;
      align-items: flex-start;
      padding-left: 12 * 3.22px;
      position: relative;
      .box1 {
        z-index: auto;
        width: 317 * 3.22px;
        height: 150 * 3.22px;
        .main1 {
          z-index: auto;
          width: 28 * 3.22px;
          height: 132 * 3.22px;
          margin-top: 11 * 3.22px;
          .info1 {
            z-index: 32;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .word6 {
            z-index: 43;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 19 * 3.22px;
          }
          .txt5 {
            z-index: 45;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .info2 {
            z-index: 53;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .word7 {
            z-index: 57;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
        }
        .main2 {
          z-index: 27;
          width: 1 * 3.22px;
          height: 150 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-left: 12 * 3.22px;
        }
        .main3 {
          z-index: 47;
          height: 14 * 3.22px;
          background-color: rgba(255, 255, 255, 1);
          box-shadow: inset 0 * 3.22px -1 * 3.22px 0 * 3.22px 0 * 3.22px rgba(0, 0, 0, 1);
          width: 42 * 3.22px;
          justify-content: flex-start;
          align-items: center;
          margin: 102 * 3.22px 0 0 120 * 3.22px;
          .txt6 {
            z-index: 50;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
        }
        .main4 {
          z-index: auto;
          width: 108 * 3.22px;
          height: 132 * 3.22px;
          margin: 11 * 3.22px 0 0 6 * 3.22px;
          .txt7 {
            z-index: 33;
            width: 56 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: left;
            align-self: flex-end;
            margin-right: 21 * 3.22px;
          }
          .bd1 {
            z-index: auto;
            width: 96 * 3.22px;
            height: 14 * 3.22px;
            justify-content: space-between;
            margin: 17 * 3.22px 0 0 12 * 3.22px;
            .bd2 {
              z-index: 40;
              height: 14 * 3.22px;
              background-color: rgba(255, 255, 255, 1);
              box-shadow: inset 0 * 3.22px -1 * 3.22px 0 * 3.22px 0 * 3.22px rgba(0, 0, 0, 1);
              width: 42 * 3.22px;
              justify-content: flex-start;
              align-items: center;
              .word8 {
                z-index: 188;
                width: 12 * 3.22px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 12 * 3.22px;
                font-family: "songti";
                white-space: nowrap;
                line-height: 12 * 3.22px;
                text-align: center;
              }
            }
            .bd3 {
              z-index: 41;
              height: 14 * 3.22px;
              background-color: rgba(255, 255, 255, 1);
              box-shadow: inset 0 * 3.22px -1 * 3.22px 0 * 3.22px 0 * 3.22px rgba(0, 0, 0, 1);
              width: 42 * 3.22px;
              justify-content: flex-start;
              align-items: center;
              margin-left: 85px;
              .word9 {
                z-index: 189;
                width: 12 * 3.22px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 12 * 3.22px;
                font-family: "songti";
                white-space: nowrap;
                line-height: 12 * 3.22px;
                text-align: center;
              }
            }
          }
          .bd4 {
            z-index: 48;
            height: 14 * 3.22px;
            background-color: rgba(255, 255, 255, 1);
            box-shadow: inset 0 * 3.22px -1 * 3.22px 0 * 3.22px 0 * 3.22px rgba(0, 0, 0, 1);
            align-self: flex-start;
            width: 42 * 3.22px;
            justify-content: flex-start;
            align-items: center;
            margin: 13 * 3.22px 0 0 24 * 3.22px;
            .word10 {
              z-index: 49;
              width: 12 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .bd5 {
            z-index: 51;
            height: 14 * 3.22px;
            background-color: rgba(255, 255, 255, 1);
            box-shadow: inset 0 * 3.22px -1 * 3.22px 0 * 3.22px 0 * 3.22px rgba(0, 0, 0, 1);
            align-self: flex-start;
            width: 42 * 3.22px;
            justify-content: flex-start;
            align-items: center;
            margin: 13 * 3.22px 0 0 20 * 3.22px;
            .txt8 {
              z-index: 52;
              width: 12 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .bd6 {
            z-index: 55;
            height: 14 * 3.22px;
            background-color: rgba(255, 255, 255, 1);
            box-shadow: inset 0 * 3.22px -1 * 3.22px 0 * 3.22px 0 * 3.22px rgba(0, 0, 0, 1);
            align-self: flex-start;
            margin-top: 13 * 3.22px;
            width: 42 * 3.22px;
            justify-content: flex-start;
            align-items: center;
            .word11 {
              z-index: 56;
              width: 6 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
        }
      }
      .box2 {
        z-index: 28;
        position: absolute;
        left: 0;
        top: 42 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .txt9 {
        z-index: 39;
        position: absolute;
        left: 65 * 3.22px;
        top: 50 * 3.22px;
        width: 430 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .box3 {
        z-index: 29;
        position: absolute;
        left: 0;
        top: 69 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .info3 {
        z-index: 44;
        position: absolute;
        left: 65 * 3.22px;
        top: 77 * 3.22px;
        width: 430 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .box4 {
        z-index: 30;
        position: absolute;
        left: 0;
        top: 96 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .word12 {
        z-index: 46;
        position: absolute;
        left: 65 * 3.22px;
        top: 104 * 3.22px;
        width: 430 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .box5 {
        z-index: 31;
        position: absolute;
        left: 0;
        top: 123 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .word13 {
        z-index: 54;
        position: absolute;
        left: 65 * 3.22px;
        top: 131 * 3.22px;
        width: 430 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
    }
    .section6 {
      z-index: 15;
      height: 424 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 16 * 3.22px;
      width: 507 * 3.22px;
      align-items: flex-end;
      padding-right: 8 * 3.22px;
      position: relative;
      .outer2 {
        z-index: auto;
        width: 487 * 3.22px;
        height: 424 * 3.22px;
        justify-content: space-between;
        .section7 {
          z-index: auto;
          width: 28 * 3.22px;
          height: 398 * 3.22px;
          margin-top: 11 * 3.22px;
          .info4 {
            z-index: 24;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .info5 {
            z-index: 58;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 27 * 3.22px;
          }
          .info6 {
            z-index: 59;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 31 * 3.22px;
          }
          .txt10 {
            z-index: 62;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 31 * 3.22px;
          }
          .txt11 {
            z-index: 63;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 31 * 3.22px;
          }
          .txt12 {
            z-index: 136;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 31 * 3.22px;
          }
          .word14 {
            z-index: 84;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 23 * 3.22px;
          }
          .info7 {
            z-index: 93;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .word15 {
            z-index: 111;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .txt13 {
            z-index: 127;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 23 * 3.22px;
          }
          .word16 {
            z-index: 120;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 31 * 3.22px;
          }
        }
        .section8 {
          z-index: 16;
          width: 1 * 3.22px;
          height: 424 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .section9 {
          z-index: auto;
          width: 204 * 3.22px;
          height: 406 * 3.22px;
          margin-top: 11 * 3.22px;
          .txt14 {
            z-index: 25;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: left;
            align-self: center;
          }
          .txt15 {
            z-index: 38;
            width: 204 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 19 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .word17 {
            z-index: 42;
            width: 204 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .txt16 {
            z-index: 61;
            width: 204 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .txt17 {
            z-index: 135;
            width: 204 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .info8 {
            z-index: 83;
            width: 204 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .word18 {
            z-index: 92;
            width: 204 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word19 {
            z-index: 100;
            width: 204 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word20 {
            z-index: 110;
            width: 204 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .txt18 {
            z-index: 118;
            width: 204 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .word21 {
            z-index: 119;
            width: 204 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .section10 {
          z-index: 17;
          width: 1 * 3.22px;
          height: 424 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .section11 {
          z-index: auto;
          width: 16 * 3.22px;
          height: 188 * 3.22px;
          margin-top: 13 * 3.22px;
          .word22 {
            z-index: 34;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 17 * 3.22px;
            text-align: center;
          }
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon1 {
            z-index: 76;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 24 * 3.22px;
          }
          .icon2 {
            z-index: 70;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon3 {
            z-index: 64;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 29 * 3.22px;
          }
          .icon4 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon5 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon6 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 20 * 3.22px;
          }
          .icon7 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon8 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
          .icon9 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 18 * 3.22px;
          }
          .icon10 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
        }
        .section12 {
          z-index: 19;
          width: 1 * 3.22px;
          height: 424 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .section13 {
          z-index: auto;
          width: 48 * 3.22px;
          height: 293 * 3.22px;
          margin-top: 13 * 3.22px;
          display: flex;
          align-items: center;
          .info9 {
            z-index: 35;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 17 * 3.22px;
            text-align: center;
          }
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon1 {
            z-index: 76;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 24 * 3.22px;
          }
          .icon2 {
            z-index: 70;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon3 {
            z-index: 64;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 29 * 3.22px;
          }
          .icon4 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon5 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon6 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 20 * 3.22px;
          }
          .icon7 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon8 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
          .icon9 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 18 * 3.22px;
          }
          .icon10 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
        }
        .section14 {
          z-index: 18;
          width: 1 * 3.22px;
          height: 424 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .section15 {
          z-index: auto;
          width: 46 * 3.22px;
          height: 396 * 3.22px;
          margin-top: 13 * 3.22px;
          display: flex;
          align-items: center;
          .word23 {
            z-index: 36;
            width: 46 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 17 * 3.22px;
            text-align: center;
          }
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon1 {
            z-index: 76;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 24 * 3.22px;
          }
          .icon2 {
            z-index: 70;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon3 {
            z-index: 64;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 29 * 3.22px;
          }
          .icon4 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon5 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon6 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 20 * 3.22px;
          }
          .icon7 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon8 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
          .icon9 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 18 * 3.22px;
          }
          .icon10 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
        }
        .section16 {
          z-index: 19;
          width: 1 * 3.22px;
          height: 424 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .section47 {
          z-index: auto;
          width: 46 * 3.22px;
          height: 396 * 3.22px;
          margin-top: 13 * 3.22px;
          display: flex;
          align-items: center;
          .word24 {
            z-index: 37;
            width: 46 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 17 * 3.22px;
            text-align: center;
          }
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon1 {
            z-index: 76;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 24 * 3.22px;
          }
          .icon2 {
            z-index: 70;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon3 {
            z-index: 64;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 29 * 3.22px;
          }
          .icon4 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon5 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
          .icon6 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 20 * 3.22px;
          }
          .icon7 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon8 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
          .icon9 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 18 * 3.22px;
          }
          .icon10 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 27 * 3.22px;
          }
        }
      }
      .outer3 {
        z-index: 21;
        position: absolute;
        left: 0;
        top: 42 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .outer4 {
        z-index: 22;
        position: absolute;
        left: 0;
        top: 85 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .outer5 {
        z-index: 23;
        position: absolute;
        left: 0;
        top: 128 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .outer6 {
        z-index: 60;
        position: absolute;
        left: 0;
        top: 171 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .outer7 {
        z-index: 134;
        position: absolute;
        left: 0;
        top: 214 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .outer8 {
        z-index: 82;
        position: absolute;
        left: 0;
        top: 257 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .outer9 {
        z-index: 91;
        position: absolute;
        left: 0;
        top: 284 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .outer10 {
        z-index: 107;
        position: absolute;
        left: 0;
        top: 311 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .outer11 {
        z-index: 108;
        position: absolute;
        left: 0;
        top: 338 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .outer12 {
        z-index: 109;
        position: absolute;
        left: 0;
        top: 381 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
    .word25 {
      z-index: 145;
      width: 220 * 3.22px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 20 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 20 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 104 * 3.22px;
    }
    .word26 {
      z-index: 146;
      width: 128 * 3.22px;
      display: flex;
      justify-content: center;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 8 * 3.22px;
    }
    .section17 {
      z-index: 147;
      height: 123 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 28 * 3.22px;
      width: 507 * 3.22px;
      align-items: flex-start;
      padding-left: 20 * 3.22px;
      position: relative;
      .group1 {
        z-index: auto;
        width: 463 * 3.22px;
        height: 123 * 3.22px;
        justify-content: space-between;
        .outer13 {
          z-index: auto;
          width: 12 * 3.22px;
          height: 101 * 3.22px;
          margin-top: 15 * 3.22px;
          .info10 {
            z-index: 155;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
          .info11 {
            z-index: 158;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 23 * 3.22px;
          }
          .word27 {
            z-index: 159;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
          .word28 {
            z-index: 181;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
        }
        .outer14 {
          z-index: 148;
          width: 1 * 3.22px;
          height: 123 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-left: 20 * 3.22px;
        }
        .outer15 {
          z-index: auto;
          width: 204 * 3.22px;
          height: 110 * 3.22px;
          margin: 7 * 3.22px 0 0 12 * 3.22px;
          .info12 {
            z-index: 154;
            width: 204 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .txt19 {
            z-index: 157;
            width: 204 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 14 * 3.22px;
          }
          .txt20 {
            z-index: 180;
            width: 204 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 13 * 3.22px;
          }
          .txt21 {
            z-index: 172;
            width: 204 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 13 * 3.22px;
          }
        }
        .outer16 {
          z-index: 17;
          width: 1 * 3.22px;
          height: 123 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-right: 25px;
        }
        .outer17 {
          z-index: auto;
          width: 51.52px;
          height: 605.36px;
          margin-top: 41.86px;
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon1 {
            z-index: 76;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon2 {
            z-index: 70;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 16 * 3.22px;
          }
          .icon3 {
            z-index: 64;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 12 * 3.22px;
          }
          .icon4 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
        }
        .outer18 {
          z-index: 19;
          width: 1 * 3.22px;
          height: 123 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-left: 39px;
        }
        .outer19 {
          z-index: auto;
          width: 48 * 3.22px;
          height: 293 * 3.22px;
          margin-top: 13 * 3.22px;
          display: flex;
          align-items: center;
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon1 {
            z-index: 76;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon2 {
            z-index: 70;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 16 * 3.22px;
          }
          .icon3 {
            z-index: 64;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 12 * 3.22px;
          }
          .icon4 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
        }
        .outer20 {
          z-index: 18;
          width: 1 * 3.22px;
          height: 123 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .outer21 {
          z-index: auto;
          width: 46 * 3.22px;
          height: 396 * 3.22px;
          margin-top: 13 * 3.22px;
          display: flex;
          align-items: center;
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon1 {
            z-index: 76;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon2 {
            z-index: 70;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 16 * 3.22px;
          }
          .icon3 {
            z-index: 64;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 12 * 3.22px;
          }
          .icon4 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
        }
        .outer22 {
          z-index: 17;
          width: 1 * 3.22px;
          height: 123 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .outer23 {
          z-index: auto;
          width: 46 * 3.22px;
          height: 396 * 3.22px;
          margin-top: 13 * 3.22px;
          margin-right: -50px;
          margin-left: 22px;
          display: flex;
          align-items: center;
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon1 {
            z-index: 76;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon2 {
            z-index: 70;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 16 * 3.22px;
          }
          .icon3 {
            z-index: 64;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 12 * 3.22px;
          }
          .icon4 {
            z-index: 137;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
        }
      }
      .group2 {
        z-index: 153;
        position: absolute;
        left: 0;
        top: 42 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .group3 {
        z-index: 156;
        position: absolute;
        left: 0;
        top: 69 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .group4 {
        z-index: 179;
        position: absolute;
        left: 0;
        top: 96 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
  }
}
</style>