<template>
  <div>
    <div
      class="image"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="pdfs"
    >
      <img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />
    </div>
    <div class="page flex-col">
      <div class="box1 flex-col">
        <div class="section1 flex-row">
          <span class="word1">{{ constants.hospitalName }}</span>
        </div>
        <span class="word2">{{ constants.title }}</span>
        <div class="section2 flex-row">
          <span class="word3">姓名：{{ constants.name }}</span>
          <span class="txt1">性别：{{ constants.sex }}</span>
          <span class="word4">年龄：{{ constants.age }}岁</span>
          <span class="txt2">教育年限：{{ constants.eduTotal }}年</span>
          <span class="info1">职业：{{ constants.job }}</span>
        </div>
        <div class="section3 flex-row">
          <span class="txt3">编号：{{ constants.reportNo }}</span>
          <span class="info2">测评时间：{{ constants.reportDate }}</span>
        </div>
        <div class="section4 flex-col"></div>
        <div class="section5 flex-col">
          <div class="mod1 flex-row">
            <div class="block1 flex-col">
              <span class="word5">题目</span>
              <span class="info3">
                现在我会给您播放一些词语，请您仔细听，听完后请您再说出这些词，可以不按顺序说
              </span>
            </div>
            <div class="block2 flex-col"></div>
            <div class="block3 flex-col">
              <span class="info4">词语</span>
              <span class="word6">
                大衣、司机、海棠、木工、长裤、百合、头巾、腊梅、士兵、玉兰、律师、手套
              </span>
            </div>
          </div>
          <div class="mod2 flex-col"></div>
        </div>
        <div class="section6 flex-col">
          <div class="group1 flex-row">
            <div class="block4 flex-col">
              <span class="info5">第一次提问</span>
              <span class="txt4">第二次提问</span>
              <span class="txt5">第三次提问</span>
              <span class="word7">第四次提问（间隔5分钟后）</span>
              <span class="word8">第五次提问（间隔20分钟后）</span>
            </div>
            <div class="block5 flex-col"></div>
            <div class="block6 flex-col">
              <span class="word9">实际回答</span>
              <span class="paragraph1">
                {{ constants.object.avlt1Data }}
              </span>
              <span class="infoBox1">
                {{ constants.object.avlt2Data }}
              </span>
              <span class="paragraph2">
                {{ constants.object.avlt3Data }}
              </span>
              <span class="paragraph3">
                {{ constants.object.avlt4Data }}
              </span>
              <span class="paragraph4">
                {{ constants.object.avlt5Data }}
              </span>
            </div>
          </div>
          <div class="group2 flex-col"></div>
          <div class="group3 flex-col"></div>
          <div class="group4 flex-col"></div>
          <div class="group5 flex-col"></div>
          <div class="group6 flex-col"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../assets/font/songti/font.css";
import htmlToPdf from "@/assets/js/htmlToPdf";
export default {
  data() {
    return {
      constants: [],
      dui: require("../../../assets/img/dui.png"),
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",
    };
  },
  methods: {
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t, document.body.style.zoom);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector(".page"),
          `${this.constants.name}_${this.constants.title}(回显)`
        );
      }
    },
  },
  mounted() {
    // console.log(this.$route.query.masterId);
    let afterUrl = window.location.search.substring(1);
    let path = afterUrl.substring(afterUrl.indexOf("=") + 1);
    this.$http
      .get(`report/getViewResult?masterId=${path}`)
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          this.constants = res.data;
          if (
            this.constants.job === "" ||
            this.constants.job === undefined ||
            this.constants.job === null
          ) {
            this.constants.job = "--";
          }
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style scoped lang='scss'>
/*stration*/
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}

body {
  font-family: "songti";
}
button {
  margin: 0;
  padding: 0;
  border: 1 * 3.22px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.image {
  position: absolute;
  top: 200px;
  right: 200px;
  z-index: 352;
  img {
    width: 22 * 3.22px;
    height: 22 * 3.22px;
  }
}
.page {
  z-index: 1;
  position: relative;
  width: 595 * 3.22px;
  height: 842 * 3.22px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  justify-content: flex-start;
  padding-top: 48 * 3.22px;
  align-items: center;
  .box1 {
    z-index: auto;
    width: 507 * 3.22px;
    height: 735 * 3.22px;
    .section1 {
      z-index: auto;
      width: 363 * 3.22px;
      height: 22 * 3.22px;
      margin-left: 144 * 3.22px;
      justify-content: space-between;
      .word1 {
        z-index: 23;
        width: 220 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-family: "songti";
        font-size: 20 * 3.22px;
        white-space: nowrap;
        line-height: 20 * 3.22px;
        text-align: center;
      }
      .icon1 {
        z-index: 25;
        width: 22 * 3.22px;
        height: 22 * 3.22px;
      }
    }
    .word2 {
      z-index: 24;
      width: 128 * 3.22px;
      display: flex;
      justify-content: center;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-family: "songti";
      font-size: 16 * 3.22px;
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 6 * 3.22px;
    }
    .section2 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 28 * 3.22px;
      .word3 {
        z-index: 37;
        width: 72 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-family: "songti";
        font-size: 12 * 3.22px;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .txt1 {
        z-index: 38;
        width: 48 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-family: "songti";
        font-size: 12 * 3.22px;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .word4 {
        z-index: 39;
        width: 60 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-family: "songti";
        font-size: 12 * 3.22px;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .txt2 {
        z-index: 40;
        width: 84 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-family: "songti";
        font-size: 12 * 3.22px;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 28 * 3.22px;
      }
      .info1 {
        z-index: 41;
        width: 454px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 36px;
        font-family: "songti";
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 36px;
        text-align: right;
        margin-left: 49px;
      }
    }
    .section3 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 8 * 3.22px;
      justify-content: space-between;
      .txt3 {
        z-index: 36;
        width: 96 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-family: "songti";
        font-size: 12 * 3.22px;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .info2 {
        z-index: 35;
        width: 156 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-family: "songti";
        font-size: 12 * 3.22px;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
      }
    }
    .section4 {
      z-index: 26;
      width: 507 * 3.22px;
      height: 1 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-top: 8 * 3.22px;
    }
    .section5 {
      z-index: 28;
      height: 93 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 12 * 3.22px;
      width: 507 * 3.22px;
      align-items: center;
      position: relative;
      .mod1 {
        z-index: auto;
        width: 483 * 3.22px;
        height: 93 * 3.22px;
        justify-content: space-between;
        .block1 {
          z-index: auto;
          width: 162 * 3.22px;
          height: 78 * 3.22px;
          margin-top: 3 * 3.22px;
          .word5 {
            z-index: 33;
            width: 162 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 14 * 3.22px;
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .info3 {
            z-index: 29;
            width: 162 * 3.22px;
            height: 42 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 16 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .block2 {
          z-index: 30;
          width: 1 * 3.22px;
          height: 93 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .block3 {
          z-index: auto;
          width: 296 * 3.22px;
          height: 69 * 3.22px;
          margin-top: 5 * 3.22px;
          .info4 {
            z-index: 34;
            width: 296 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 14 * 3.22px;
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .word6 {
            z-index: 31;
            width: 296 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 21 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .mod2 {
        z-index: 32;
        position: absolute;
        left: 0;
        top: 26 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
    .section6 {
      z-index: 2;
      height: 501 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 16 * 3.22px;
      width: 507 * 3.22px;
      align-items: center;
      position: relative;
      .group1 {
        z-index: auto;
        width: 483 * 3.22px;
        height: 501 * 3.22px;
        justify-content: space-between;
        .block4 {
          z-index: auto;
          width: 162 * 3.22px;
          height: 392 * 3.22px;
          margin-top: 68 * 3.22px;
          .info5 {
            z-index: 5;
            width: 162 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .txt4 {
            z-index: 6;
            width: 162 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 83 * 3.22px;
          }
          .txt5 {
            z-index: 7;
            width: 162 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 83 * 3.22px;
          }
          .word7 {
            z-index: 8;
            width: 162 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 83 * 3.22px;
          }
          .word8 {
            z-index: 9;
            width: 162 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 83 * 3.22px;
          }
        }
        .block5 {
          z-index: 3;
          width: 1 * 3.22px;
          height: 501 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .block6 {
          z-index: auto;
          width: 296 * 3.22px;
          height: 484 * 3.22px;
          margin-top: 5 * 3.22px;
          .word9 {
            z-index: 21;
            width: 296 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 14 * 3.22px;
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .paragraph1 {
            z-index: 10;
            width: 296 * 3.22px;
            height: 70 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 14 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .infoBox1 {
            z-index: 13;
            width: 296 * 3.22px;
            height: 70 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 25 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .paragraph2 {
            z-index: 15;
            width: 296 * 3.22px;
            height: 70 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 25 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .paragraph3 {
            z-index: 17;
            width: 296 * 3.22px;
            height: 70 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 25 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .paragraph4 {
            z-index: 18;
            width: 296 * 3.22px;
            height: 70 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-family: "songti";
            font-size: 12 * 3.22px;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 25 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .group2 {
        z-index: 11;
        position: absolute;
        left: 0;
        top: 26 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .group3 {
        z-index: 12;
        position: absolute;
        left: 0;
        top: 121 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .group4 {
        z-index: 14;
        position: absolute;
        left: 0;
        top: 216 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .group5 {
        z-index: 16;
        position: absolute;
        left: 0;
        top: 311 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .group6 {
        z-index: 19;
        position: absolute;
        left: 0;
        top: 406 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
  }
}
</style>

