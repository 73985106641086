<template>
  <div>
    <div
      class="image"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="pdfs"
    >
      <img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />
    </div>
    <div class="page flex-col" v-if="constants">
      <div class="main1 flex-col">
        <div class="layer1 flex-row">
          <span class="info1">{{ constants.hospitalName }}</span>
        </div>
        <span class="info2">{{ constants.title }}</span>
        <div class="layer2 flex-row">
          <span class="info3">姓名：{{ constants.name }}</span>
          <span class="info4">性别：{{ constants.sex }}</span>
          <span class="txt1">年龄：{{ constants.age }}岁</span>
          <span class="word1">教育年限：{{ constants.eduTotal }}年</span>
          <span class="word2">职业：{{ constants.job }}</span>
        </div>
        <div class="layer3 flex-row">
          <span class="info5">编号：{{ constants.reportNo }}</span>
          <span class="word3">测评时间：{{ constants.reportDate }}</span>
        </div>
        <div class="layer4 flex-col"></div>
        <div class="layer5 flex-col">
          <div class="box1 flex-col">
            <div class="main2 flex-row">
              <span class="word4">序号</span>
              <span class="info6">题目</span>
              <span class="info7">实际回答</span>
              <span class="word5">结果</span>
            </div>
            <div class="main3 flex-col"></div>
            <div class="main4 flex-row">
              <span class="word6">1</span>
              <span class="txt2">
                请您按照从数字到汉字并且逐渐递增的规律连出一条线从连接、从连接，后面请您继续连下去，一直连到
              </span>
              <span class="info8">{{ constants.object.montrealNo1 }}</span>
              <img
                class="icon2"
                referrerpolicy="no-referrer"
                :src="
                  constants.object.montrealNo1Score === 1 ? this.dui : this.cuo
                "
              />
            </div>
          </div>
          <div class="box2 flex-col"></div>
        </div>
        <div class="layer6 flex-col">
          <div class="bd1 flex-row">
            <span class="info9">序号</span>
            <span class="info10">题目</span>
            <span class="word7">判评</span>
          </div>
          <div class="bd2 flex-row">
            <span class="info11">请您按照这幅图在方框内再画一遍</span>
          </div>
          <div class="bd3 flex-row"><div class="wrap1 flex-col"></div></div>
          <div class="bd4 flex-row">
            <span class="txt3">图形为三维结构</span>
            <span class="txt4">{{
              constants.object.montrealNo2One === 1 ? "是" : "否"
            }}</span>
          </div>
          <div class="bd5 flex-row"><div class="main5 flex-col"></div></div>
          <div class="bd6 flex-row">
            <span class="info12">所有线都存在</span>
            <span class="txt5">{{
              constants.object.montrealNo2Two === 1 ? "是" : "否"
            }}</span>
          </div>
          <div class="bd7 flex-row">
            <span class="txt6">2</span>
            <div class="group1 flex-col"></div>
          </div>
          <div class="bd8 flex-row">
            <span class="info13">无多余的线</span>
            <span class="txt7">{{
              constants.object.montrealNo2Three === 1 ? "是" : "否"
            }}</span>
          </div>
          <div class="bd9 flex-row">
            <span class="txt8">相对的边基本平行</span>
            <span class="word8">{{
              constants.object.montrealNo2Four === 1 ? "是" : "否"
            }}</span>
          </div>
          <div class="bd10 flex-row">
            <span class="word9">长度基本一致（长方形或棱柱体也算正）</span>
            <span class="txt9">{{
              constants.object.montrealNo2Five === 1 ? "是" : "否"
            }}</span>
          </div>
          <div class="bd11 flex-row">
            <span class="txt10">
              请您在方框内画一个钟表的表盘，填上所有的数字并指出&nbsp;11点10分
            </span>
          </div>
          <div class="bd12 flex-row"><div class="group2 flex-col"></div></div>
          <div class="bd13 flex-row">
            <span class="word10">
              轮廓：表面必须是个圆，允许有轻微的缺陷&nbsp;(如，圆没有闭合)
            </span>
            <span class="word11">{{
              constants.object.montrealNo3One === 1 ? "正确" : "错误"
            }}</span>
          </div>
          <div class="bd14 flex-row">
            <span class="word12">3</span>
            <span class="txt11">
              数字：所有的数字必须完整且无多余的数字，数字顺序必须正确且在所属的象限内，可以是罗马数字，数字可以放在圆圈之外
            </span>
            <span class="info14">{{
              constants.object.montrealNo3Two === 1 ? "正确" : "错误"
            }}</span>
          </div>
          <div class="bd15 flex-row">
            <span class="txt12">
              指针：必须有两个指针且一起指向正确的时间，时针必须短于分针，指针的中心交点必须在表内且接近于钟表的中心
            </span>
            <span class="info15">{{
              constants.object.montrealNo3Three === 1 ? "正确" : "错误"
            }}</span>
          </div>
          <div class="bd16 flex-row">
            <span class="word13">请问受试者是否正确说出图中动物名称</span>
          </div>
          <div class="bd17 flex-row">
            <span class="info16">4</span>
            <div class="box3 flex-col">
              <span class="word14">狮子</span>
              <span class="txt13">犀牛</span>
              <span class="word15">骆驼或单峰骆驼</span>
            </div>
            <div class="box4 flex-col"></div>
            <div class="box5 flex-col">
              <span class="info17">{{
                constants.object.montrealNo4 === 1 ? "正确" : "错误"
              }}</span>
              <span class="info18">{{
                constants.object.montrealNo5 === 1 ? "正确" : "错误"
              }}</span>
              <span class="word16">{{
                constants.object.montrealNo6 === 1 ? "正确" : "错误"
              }}</span>
            </div>
          </div>
          <div class="bd18 flex-row">
            <span class="info19">请您说出刚才听到的词</span>
          </div>
          <div class="bd19 flex-row"><div class="layer7 flex-col"></div></div>
          <div class="bd20 flex-row">
            <span class="info20">面孔</span>
            <span class="word17">{{
              constants.object.montrealNo7One === 1 ? "是" : "否"
            }}</span>
          </div>
          <div class="bd21 flex-col"></div>
          <div class="bd22 flex-col"></div>
          <div class="bd23 flex-col"></div>
          <div class="bd24 flex-col"></div>
          <div class="bd25 flex-col"></div>
          <div class="bd26 flex-col"></div>
          <div class="bd27 flex-col"></div>
          <div class="bd28 flex-col"></div>
          <div class="bd29 flex-col"></div>
          <div class="bd30 flex-col"></div>
          <div class="bd31 flex-col"></div>
          <div class="bd32 flex-col"></div>
          <div class="bd33 flex-col"></div>
          <div class="bd34 flex-col"></div>
          <div class="bd35 flex-col"></div>
        </div>
        <span class="word18">{{ constants.hospitalName }}</span>
        <span class="txt14">{{ constants.title }}</span>
        <div class="layer8 flex-col">
          <div class="bd36 flex-row">
            <div class="wrap2 flex-col">
              <span class="word19">5</span>
              <span class="info21">6</span>
              <span class="txt15">7</span>
              <span class="txt16">8</span>
            </div>
            <div class="wrap3 flex-col"></div>
            <div class="wrap4 flex-col">
              <div class="box6 flex-row">
                <span class="txt17">天鹅绒</span>
                <span class="word20">{{
                  constants.object.montrealNo7Two === 1 ? "是" : "否"
                }}</span>
              </div>
              <div class="box7 flex-row">
                <span class="word21">教堂</span>
                <span class="word22">{{
                  constants.object.montrealNo7Three === 1 ? "是" : "否"
                }}</span>
              </div>
              <div class="box8 flex-row">
                <span class="info22">菊花</span>
                <span class="txt18">{{
                  constants.object.montrealNo7Four === 1 ? "是" : "否"
                }}</span>
              </div>
              <div class="box9 flex-row">
                <span class="info23">红色</span>
                <span class="word23">{{
                  constants.object.montrealNo7Five === 1 ? "是" : "否"
                }}</span>
              </div>
              <span class="word24">
                下面将再给您听一遍刚才的几个词，请您仔细听并努力记住这些词，等一下要问您，请听题
              </span>
              <div class="box10 flex-row">
                <span class="word25">面孔</span>
                <span class="txt19">{{
                  constants.object.montrealNo7One2 === 1 ? "是" : "否"
                }}</span>
              </div>
              <div class="box11 flex-row">
                <span class="info24">天鹅绒</span>
                <span class="word26">{{
                  constants.object.montrealNo7Two2 === 1 ? "是" : "否"
                }}</span>
              </div>
              <div class="box12 flex-row">
                <span class="word27">教堂</span>
                <span class="word28">{{
                  constants.object.montrealNo7Three2 === 1 ? "是" : "否"
                }}</span>
              </div>
              <div class="box13 flex-row">
                <span class="info25">菊花</span>
                <span class="info26">{{
                  constants.object.montrealNo7Four2 === 1 ? "是" : "否"
                }}</span>
              </div>
              <div class="box14 flex-row">
                <span class="word29">红色</span>
                <span class="txt20">{{
                  constants.object.montrealNo7Five2 === 1 ? "是" : "否"
                }}</span>
              </div>
              <span class="word30">请您按顺序说出刚才听到的数字</span>
              <div class="box15 flex-row">
                <span class="word31">2-1-8-5-4</span>
                <span class="txt21">{{
                  constants.object.montrealNo8 === 1 ? "正确" : "错误"
                }}</span>
              </div>
              <span class="info27">请您倒序说出刚才听到的数字</span>
              <div class="box16 flex-col"></div>
              <div class="box17 flex-row">
                <span class="word32">7-4-2</span>
                <span class="txt22">{{
                  constants.object.montrealNo9 === 1 ? "正确" : "错误"
                }}</span>
              </div>
              <div class="box18 flex-row">
                <span class="info28">
                  下面将再给您听一遍刚才的几个词，请您仔细听并努力记住这些词，等一下要问您，请听题
                </span>
                <span class="txt23">{{ constants.object.montrealNo10 }}次</span>
              </div>
            </div>
          </div>
          <div class="bd37 flex-col"></div>
          <div class="bd38 flex-col"></div>
          <div class="bd39 flex-col"></div>
          <div class="bd40 flex-col"></div>
          <div class="bd41 flex-col"></div>
          <div class="bd42 flex-col"></div>
          <div class="bd43 flex-col"></div>
          <div class="bd44 flex-col"></div>
          <div class="bd45 flex-col"></div>
          <div class="bd46 flex-col"></div>
          <div class="bd47 flex-col"></div>
          <div class="bd48 flex-col"></div>
          <div class="bd49 flex-col"></div>
          <div class="bd50 flex-col"></div>
          <div class="bd51 flex-col"></div>
        </div>
        <div class="layer9 flex-col">
          <div class="main6 flex-row">
            <div class="wrap5 flex-col">
              <span class="word33">序号</span>
              <span class="word34">9</span>
            </div>
            <div class="wrap6 flex-col"></div>
            <div class="wrap7 flex-col">
              <div class="bd52 flex-row">
                <span class="word35">题目</span>
                <span class="word36">实际回答</span>
                <span class="txt24">结果</span>
              </div>
              <span class="word37">
                现在请您从100减去7，把答案填到相应的位置。得到的数字再减7，如此一直下去，直到出现“停止”。如果您准备好了，点击“继续”开始答题
              </span>
              <div class="bd53 flex-row">
                <span class="word38">100-7=93</span>
                <span class="txt25">{{
                  constants.object.montrealNo11One
                }}</span>
                <img
                  class="label1"
                  referrerpolicy="no-referrer"
                  :src="
                    constants.object.montrealNo11OneResult === 1
                      ? this.dui
                      : this.cuo
                  "
                />
              </div>
              <div class="bd54 flex-row">
                <span class="txt26">再减7</span>
                <span class="word39">{{
                  constants.object.montrealNo11Two
                }}</span>
                <img
                  class="icon3"
                  referrerpolicy="no-referrer"
                  :src="
                    constants.object.montrealNo11TwoResult === 1
                      ? this.dui
                      : this.cuo
                  "
                />
              </div>
              <div class="bd55 flex-row">
                <span class="word40">再减7</span>
                <span class="info29">{{
                  constants.object.montrealNo11Three
                }}</span>
                <img
                  class="label2"
                  referrerpolicy="no-referrer"
                  :src="
                    constants.object.montrealNo11ThreeResult === 1
                      ? this.dui
                      : this.cuo
                  "
                />
              </div>
              <div class="bd56 flex-row">
                <span class="word41">再减7</span>
                <span class="word42">{{
                  constants.object.montrealNo11Four
                }}</span>
                <img
                  class="label3"
                  referrerpolicy="no-referrer"
                  :src="
                    constants.object.montrealNo11FourResult === 1
                      ? this.dui
                      : this.cuo
                  "
                />
              </div>
              <div class="bd57 flex-row">
                <span class="word43">再减7</span>
                <span class="word44">{{
                  constants.object.montrealNo11Five
                }}</span>
                <img
                  class="icon4"
                  referrerpolicy="no-referrer"
                  :src="
                    constants.object.montrealNo11FiveResult === 1
                      ? this.dui
                      : this.cuo
                  "
                />
              </div>
            </div>
          </div>
          <div class="main7 flex-col"></div>
          <div class="main8 flex-col"></div>
          <div class="main9 flex-col"></div>
          <div class="main10 flex-col"></div>
          <div class="main11 flex-col"></div>
          <div class="main12 flex-col"></div>
          <div class="main13 flex-col"></div>
          <div class="main14 flex-col"></div>
          <div class="main15 flex-col"></div>
          <div class="main16 flex-col"></div>
        </div>
        <span class="word45">{{ constants.hospitalName }}</span>
        <span class="txt27">{{ constants.title }}</span>
        <div class="layer10 flex-col">
          <div class="section1 flex-col">
            <div class="section2 flex-row">
              <span class="txt28">序号</span>
              <span class="info30">题目</span>
              <span class="info31">判评</span>
            </div>
            <div class="section3 flex-col"></div>
            <span class="txt29">请您说出刚才听的那句话</span>
            <span class="info32">10</span>
            <div class="section4 flex-row">
              <span class="word46">我只知道今天张亮是来帮过忙的人</span>
              <span class="word47">{{
                constants.object.montrealNo12 === 1 ? "正确" : "错误"
              }}</span>
            </div>
            <span class="info33">请您说出刚才听的那句话</span>
            <span class="txt30">11</span>
            <div class="section5 flex-row">
              <span class="txt31">狗在房间的时候，猫总是躲在沙发下面</span>
              <span class="word48">{{
                constants.object.montrealNo13 === 1 ? "正确" : "错误"
              }}</span>
            </div>
            <div class="section6 flex-row">
              <span class="txt32">12</span>
              <span class="txt33">
                下面请您在1分钟内尽可能多的说出动物的名字，请问受试者说了多少个？
              </span>
              <span class="info34">{{ constants.object.montrealNo14 }}个</span>
            </div>
            <div class="section7 flex-row">
              <span class="txt34">13</span>
              <span class="txt35">
                下面有一些词，请您说说它们有什么相似之处，例如：香蕉和桔子，他们都是水果，火车和自行车呢
              </span>
            </div>
            <div class="section8 flex-row">
              <span class="info35">运输工具；交通工具；旅行用的</span>
              <span class="word49">{{
                constants.object.montrealNo15 === 1 ? "正确" : "错误"
              }}</span>
            </div>
            <span class="word50">手表和尺子呢</span>
            <span class="info36">14</span>
            <div class="section9 flex-row">
              <span class="info37">测量仪器；测量用的</span>
              <span class="word51">{{
                constants.object.montrealNo16 === 1 ? "正确" : "错误"
              }}</span>
            </div>
            <span class="info38">
              还记得刚才您听过的那几个词吗？请您把那些词说出来
            </span>
            <div class="section10 flex-row">
              <span class="info39">面孔</span>
              <span class="info40">{{
                constants.object.montrealFace === 1 ? "是" : "否"
              }}</span>
            </div>
            <div class="section11 flex-row">
              <span class="word52">天鹅绒</span>
              <span class="txt36">{{
                constants.object.montrealVelvet === 1 ? "是" : "否"
              }}</span>
            </div>
            <div class="section12 flex-row">
              <span class="word53">14</span>
              <div class="section13 flex-col"></div>
            </div>
            <div class="section14 flex-row">
              <span class="word54">教堂</span>
              <span class="word55">{{
                constants.object.montrealChurch === 1 ? "是" : "否"
              }}</span>
            </div>
            <div class="section15 flex-row">
              <span class="info41">菊花</span>
              <span class="word56">{{
                constants.object.montrealChrysanthemum === 1 ? "是" : "否"
              }}</span>
            </div>
            <div class="section16 flex-row">
              <span class="word57">红色</span>
              <span class="word58">{{
                constants.object.montrealRed === 1 ? "是" : "否"
              }}</span>
            </div>
          </div>
          <div class="section17 flex-col"></div>
          <div class="section18 flex-col"></div>
          <div class="section19 flex-col"></div>
          <div class="section20 flex-col"></div>
          <div class="section21 flex-col"></div>
          <div class="section22 flex-col"></div>
          <div class="section23 flex-col"></div>
          <div class="section24 flex-col"></div>
          <div class="section25 flex-col"></div>
          <div class="section26 flex-col"></div>
          <div class="section27 flex-col"></div>
          <div class="section28 flex-col"></div>
          <div class="section29 flex-col"></div>
          <div class="section30 flex-col"></div>
          <div class="section31 flex-col"></div>
          <div class="section32 flex-col"></div>
          <div class="section33 flex-col"></div>
          <div class="section34 flex-col"></div>
        </div>
        <div class="layer11 flex-col">
          <div class="section35 flex-row">
            <div class="box19 flex-col">
              <span class="txt37">序号</span>
              <span class="txt38">15</span>
              <span class="info42">16</span>
              <span class="txt39">17</span>
              <span class="txt40">18</span>
              <span class="word59">19</span>
              <span class="info43">20</span>
            </div>
            <div class="box20 flex-col"></div>
            <div class="box21 flex-col">
              <span class="info44">题目</span>
              <span class="txt41">今天是几号</span>
              <span class="word60">现在是几月</span>
              <span class="word61">今年是哪一年</span>
              <span class="txt42">今天是星期几</span>
              <span class="word62">我们现在是在什么地方</span>
              <span class="info45">现在您在哪个城市</span>
            </div>
            <div class="box22 flex-col"></div>
            <div class="box23 flex-col">
              <span class="word63">实际回答</span>
              <span class="word64">{{ constants.object.montrealNo17 }}号</span>
              <span class="word65">{{ constants.object.montrealNo18 }}月</span>
              <span class="word66">{{ constants.object.montrealNo19 }}年</span>
              <span class="word67"
                >星期{{
                  constants.object.montrealNo20 === 1
                    ? "一"
                    : constants.object.montrealNo20 === 2
                    ? "二"
                    : constants.object.montrealNo20 === 3
                    ? "三"
                    : constants.object.montrealNo20 === 4
                    ? "四"
                    : constants.object.montrealNo20 === 5
                    ? "五"
                    : constants.object.montrealNo20 === 6
                    ? "六"
                    : "日"
                }}</span
              >

              <span class="info46">{{
                constants.object.montrealNo21 === 1 ? "正确" : "错误"
              }}</span>
              <span class="word68">{{
                constants.object.montrealNo22 === 1 ? "正确" : "错误"
              }}</span>
            </div>
            <div class="box24 flex-col"></div>
            <div class="box25 flex-col">
              <span class="word69">结果</span>
              <img
                class="label4"
                referrerpolicy="no-referrer"
                :src="
                  constants.object.montrealNo17Result === 1
                    ? this.dui
                    : this.cuo
                "
              />
              <img
                class="icon5"
                referrerpolicy="no-referrer"
                :src="
                  constants.object.montrealNo18Result === 1
                    ? this.dui
                    : this.cuo
                "
              />
              <img
                class="icon6"
                referrerpolicy="no-referrer"
                :src="
                  constants.object.montrealNo19Result === 1
                    ? this.dui
                    : this.cuo
                "
              />
              <img
                class="icon7"
                referrerpolicy="no-referrer"
                :src="
                  constants.object.montrealNo20Result === 1
                    ? this.dui
                    : this.cuo
                "
              />
              <img
                class="label5"
                referrerpolicy="no-referrer"
                :src="constants.object.montrealNo21 === 1 ? this.dui : this.cuo"
              />
              <img
                class="icon8"
                referrerpolicy="no-referrer"
                :src="constants.object.montrealNo22 === 1 ? this.dui : this.cuo"
              />
            </div>
          </div>
          <div class="section36 flex-col"></div>
          <div class="section37 flex-col"></div>
        </div>
      </div>
      <div class="main17 flex-col"></div>
      <div class="main18 flex-col"></div>
      <div class="main19 flex-col"></div>
      <div class="main20 flex-col"></div>
      <div class="main21 flex-col"></div>
      <div class="main22 flex-col"></div>
      <div class="main23 flex-col"></div>
      <div class="main24 flex-col"></div>
      <div class="main25 flex-col"></div>
      <div class="main26 flex-col"></div>
      <div class="main27 flex-col"></div>
    </div>
  </div>
</template>
<script>
import "../../../assets/font/songti/font.css";
import htmlToPdf from "@/assets/js/htmlToPdf";

export default {
  data() {
    return {
      constants: [],
      dui: require("../../../assets/img/dui.png"),
      cuo: require("../../../assets/img/cuo.png"),
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",
    };
  },
  methods: {
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t, document.body.style.zoom);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector(".page"),
          `${this.constants.name}_${this.constants.title}(回显)`
        );
      }
    },
  },
  mounted() {
    let afterUrl = window.location.search.substring(1);
    let path = afterUrl.substring(afterUrl.indexOf("=") + 1);
    // console.log(this.$route.query.masterId);
    this.$http
      .get(`report/getViewResult?masterId=${path}`)
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          this.constants = res.data;
          if (
            this.constants.job === "" ||
            this.constants.job === undefined ||
            this.constants.job === null
          ) {
            this.constants.job = "--";
          }
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style scoped lang='scss'>
/*stration*/
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: "songti";
}
button {
  margin: 0;
  padding: 0;
  border: 1 * 3.22px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.image {
  position: absolute;
  top: 200px;
  right: 200px;
  z-index: 352;
  img {
    width: 22 * 3.22px;
    height: 22 * 3.22px;
  }
}
.page {
  z-index: 1;
  position: relative;
  width: 595 * 3.22px;
  height: 2493 * 3.22px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  justify-content: flex-start;
  padding-top: 48 * 3.22px;
  align-items: center;
  .main1 {
    z-index: auto;
    width: 507 * 3.22px;
    height: 2389 * 3.22px;
    .layer1 {
      z-index: auto;
      width: 363 * 3.22px;
      height: 22 * 3.22px;
      margin-left: 144 * 3.22px;
      justify-content: space-between;
      .info1 {
        z-index: 79;
        width: 220 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 20 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 20 * 3.22px;
        text-align: center;
      }
      .icon1 {
        z-index: 89;
        width: 22 * 3.22px;
        height: 22 * 3.22px;
      }
    }
    .info2 {
      z-index: 80;
      width: 160 * 3.22px;
      display: flex;
      justify-content: center;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 6 * 3.22px;
    }
    .layer2 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 28 * 3.22px;
      .info3 {
        z-index: 84;
        width: 72 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .info4 {
        z-index: 85;
        width: 48 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .txt1 {
        z-index: 86;
        width: 60 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .word1 {
        z-index: 87;
        width: 84 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 28 * 3.22px;
      }
      .word2 {
        z-index: 88;
        width: 454px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 15 * 3.22px;
      }
    }
    .layer3 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 8 * 3.22px;
      justify-content: space-between;
      .info5 {
        z-index: 83;
        width: 96 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .word3 {
        z-index: 82;
        width: 156 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
      }
    }
    .layer4 {
      z-index: 81;
      width: 507 * 3.22px;
      height: 1 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-top: 8 * 3.22px;
    }
    .layer5 {
      z-index: 2;
      height: 83 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 12 * 3.22px;
      width: 507 * 3.22px;
      justify-content: flex-start;
      padding-top: 3 * 3.22px;
      position: relative;
      .box1 {
        z-index: auto;
        width: 507 * 3.22px;
        height: 73 * 3.22px;
        .main2 {
          z-index: auto;
          width: 482 * 3.22px;
          height: 20 * 3.22px;
          margin-left: 12 * 3.22px;
          .word4 {
            z-index: 62;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .info6 {
            z-index: 63;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: left;
            margin-left: 145 * 3.22px;
          }
          .info7 {
            z-index: 64;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
            margin-left: 146 * 3.22px;
          }
          .word5 {
            z-index: 65;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
            margin-left: 26 * 3.22px;
          }
        }
        .main3 {
          z-index: 3;
          width: 507 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: 3 * 3.22px;
        }
        .main4 {
          z-index: auto;
          width: 465 * 3.22px;
          height: 42 * 3.22px;
          margin: 7 * 3.22px 0 0 23 * 3.22px;
          .word6 {
            z-index: 67;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
          .txt2 {
            z-index: 66;
            width: 269 * 3.22px;
            height: 42 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-left: 36 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .info8 {
            z-index: 68;
            width: 81 * 3.22px;
            height: 42 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: center;
            margin-left: 25 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .icon2 {
            z-index: 69;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin: 13 * 3.22px 0 0 32 * 3.22px;
          }
        }
      }
      .box2 {
        z-index: 75;
        position: absolute;
        left: 52 * 3.22px;
        top: 0;
        width: 1 * 3.22px;
        height: 83 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
    .layer6 {
      z-index: 4;
      height: 490 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 16 * 3.22px;
      width: 507 * 3.22px;
      position: relative;
      .bd1 {
        z-index: auto;
        width: 482 * 3.22px;
        height: 20 * 3.22px;
        justify-content: space-between;
        margin: 3 * 3.22px 0 0 12 * 3.22px;
        .info9 {
          z-index: 5;
          width: 28 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 14 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 20 * 3.22px;
          text-align: center;
        }
        .info10 {
          z-index: 6;
          width: 28 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 14 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 20 * 3.22px;
          text-align: left;
        }
        .word7 {
          z-index: 7;
          width: 28 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 14 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 20 * 3.22px;
          text-align: center;
        }
      }
      .bd2 {
        z-index: auto;
        width: 430 * 3.22px;
        height: 12 * 3.22px;
        margin: 11 * 3.22px 0 0 65 * 3.22px;
        .info11 {
          z-index: 18;
          width: 430 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
      }
      .bd3 {
        z-index: auto;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        margin: 7 * 3.22px 0 0 53 * 3.22px;
        .wrap1 {
          z-index: 11;
          width: 454 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
      }
      .bd4 {
        z-index: auto;
        width: 421 * 3.22px;
        height: 12 * 3.22px;
        justify-content: space-between;
        margin: 7 * 3.22px 0 0 65 * 3.22px;
        .txt3 {
          z-index: 46;
          width: 375 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
        .txt4 {
          z-index: 57;
          width: 12 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
        }
      }
      .bd5 {
        z-index: auto;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        margin: 7 * 3.22px 0 0 53 * 3.22px;
        .main5 {
          z-index: 12;
          width: 454 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
      }
      .bd6 {
        z-index: auto;
        width: 421 * 3.22px;
        height: 12 * 3.22px;
        justify-content: space-between;
        margin: 7 * 3.22px 0 0 65 * 3.22px;
        .info12 {
          z-index: 47;
          width: 375 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
        .txt5 {
          z-index: 58;
          width: 12 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
        }
      }
      .bd7 {
        z-index: auto;
        width: 484 * 3.22px;
        height: 12 * 3.22px;
        justify-content: space-between;
        margin: 2 * 3.22px 0 0 23 * 3.22px;
        .txt6 {
          z-index: 35;
          width: 6 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
        }
        .group1 {
          z-index: 13;
          width: 454 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: 5 * 3.22px;
        }
      }
      .bd8 {
        z-index: auto;
        width: 421 * 3.22px;
        height: 12 * 3.22px;
        justify-content: space-between;
        margin: 1 * 3.22px 0 0 65 * 3.22px;
        .info13 {
          z-index: 48;
          width: 375 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
        .txt7 {
          z-index: 59;
          width: 12 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
        }
      }
      .bd9 {
        z-index: auto;
        width: 421 * 3.22px;
        height: 14 * 3.22px;
        justify-content: space-between;
        margin: 15 * 3.22px 0 0 65 * 3.22px;
        .txt8 {
          z-index: 54;
          width: 269 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
        .word8 {
          z-index: 56;
          width: 12 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
          margin-top: 2 * 3.22px;
        }
      }
      .bd10 {
        z-index: auto;
        width: 421 * 3.22px;
        height: 14 * 3.22px;
        justify-content: space-between;
        margin: 13 * 3.22px 0 0 65 * 3.22px;
        .word9 {
          z-index: 55;
          width: 375 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
        .txt9 {
          z-index: 60;
          width: 12 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
          margin-top: 2 * 3.22px;
        }
      }
      .bd11 {
        z-index: auto;
        width: 430 * 3.22px;
        height: 12 * 3.22px;
        margin: 13 * 3.22px 0 0 65 * 3.22px;
        .txt10 {
          z-index: 19;
          width: 430 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
      }
      .bd12 {
        z-index: auto;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        margin: 7 * 3.22px 0 0 53 * 3.22px;
        .group2 {
          z-index: 14;
          width: 454 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
      }
      .bd13 {
        z-index: auto;
        width: 427 * 3.22px;
        height: 12 * 3.22px;
        justify-content: space-between;
        margin: 7 * 3.22px 0 0 65 * 3.22px;
        .word10 {
          z-index: 40;
          width: 375 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
        .word11 {
          z-index: 41;
          width: 24 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
        }
      }
      .bd14 {
        z-index: auto;
        width: 469 * 3.22px;
        height: 37 * 3.22px;
        margin: 15 * 3.22px 0 0 23 * 3.22px;
        .word12 {
          z-index: 36;
          width: 6 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
          margin-top: 25 * 3.22px;
        }
        .txt11 {
          z-index: 42;
          width: 375 * 3.22px;
          height: 28 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          line-height: 14 * 3.22px;
          text-align: left;
          margin-left: 36 * 3.22px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .info14 {
          z-index: 43;
          width: 24 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
          margin: 8 * 3.22px 0 0 28 * 3.22px;
        }
      }
      .bd15 {
        z-index: auto;
        width: 427 * 3.22px;
        height: 28 * 3.22px;
        justify-content: space-between;
        margin: 6 * 3.22px 0 0 65 * 3.22px;
        .txt12 {
          z-index: 44;
          width: 375 * 3.22px;
          height: 28 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          line-height: 14 * 3.22px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .info15 {
          z-index: 45;
          width: 24 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
          margin-top: 8 * 3.22px;
        }
      }
      .bd16 {
        z-index: auto;
        width: 430 * 3.22px;
        height: 12 * 3.22px;
        margin: 15 * 3.22px 0 0 65 * 3.22px;
        .word13 {
          z-index: 20;
          width: 430 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
      }
      .bd17 {
        z-index: auto;
        width: 469 * 3.22px;
        height: 82 * 3.22px;
        margin: 7 * 3.22px 0 0 23 * 3.22px;
        .info16 {
          z-index: 37;
          width: 6 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
          margin-top: 22 * 3.22px;
        }
        .box3 {
          z-index: auto;
          width: 375 * 3.22px;
          height: 66 * 3.22px;
          margin: 8 * 3.22px 0 0 36 * 3.22px;
          .word14 {
            z-index: 25;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .txt13 {
            z-index: 33;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word15 {
            z-index: 32;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
        }
        .box4 {
          z-index: 17;
          width: 1 * 3.22px;
          height: 82 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-left: 12 * 3.22px;
        }
        .box5 {
          z-index: auto;
          width: 24 * 3.22px;
          height: 66 * 3.22px;
          margin: 8 * 3.22px 0 0 15 * 3.22px;
          .info17 {
            z-index: 26;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
          .info18 {
            z-index: 31;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
          .word16 {
            z-index: 34;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
        }
      }
      .bd18 {
        z-index: auto;
        width: 430 * 3.22px;
        height: 12 * 3.22px;
        margin: 7 * 3.22px 0 0 65 * 3.22px;
        .info19 {
          z-index: 21;
          width: 430 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
      }
      .bd19 {
        z-index: auto;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        margin: 7 * 3.22px 0 0 53 * 3.22px;
        .layer7 {
          z-index: 27;
          width: 454 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
      }
      .bd20 {
        z-index: auto;
        width: 421 * 3.22px;
        height: 12 * 3.22px;
        justify-content: space-between;
        margin: 7 * 3.22px 0 0 65 * 3.22px;
        .info20 {
          z-index: 29;
          width: 375 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
        }
        .word17 {
          z-index: 30;
          width: 12 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
        }
      }
      .bd21 {
        z-index: 8;
        position: absolute;
        left: 2 * 3.22px;
        top: 26 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd22 {
        z-index: 15;
        position: absolute;
        left: 452 * 3.22px;
        top: 53 * 3.22px;
        width: 1 * 3.22px;
        height: 136 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd23 {
        z-index: 49;
        position: absolute;
        left: 53 * 3.22px;
        top: 134 * 3.22px;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd24 {
        z-index: 50;
        position: absolute;
        left: 53 * 3.22px;
        top: 161 * 3.22px;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd25 {
        z-index: 51;
        position: absolute;
        left: 0;
        top: 188 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd26 {
        z-index: 38;
        position: absolute;
        left: 53 * 3.22px;
        top: 242 * 3.22px;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd27 {
        z-index: 10;
        position: absolute;
        left: 52 * 3.22px;
        top: 0;
        width: 1 * 3.22px;
        height: 490 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd28 {
        z-index: 16;
        position: absolute;
        left: 452 * 3.22px;
        top: 215 * 3.22px;
        width: 1 * 3.22px;
        height: 114 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd29 {
        z-index: 39;
        position: absolute;
        left: 53 * 3.22px;
        top: 285 * 3.22px;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd30 {
        z-index: 52;
        position: absolute;
        left: 0;
        top: 328 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd31 {
        z-index: 23;
        position: absolute;
        left: 53 * 3.22px;
        top: 355 * 3.22px;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd32 {
        z-index: 22;
        position: absolute;
        left: 53 * 3.22px;
        top: 382 * 3.22px;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd33 {
        z-index: 24;
        position: absolute;
        left: 53 * 3.22px;
        top: 409 * 3.22px;
        width: 454 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd34 {
        z-index: 53;
        position: absolute;
        left: 0;
        top: 436 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd35 {
        z-index: 28;
        position: absolute;
        left: 452 * 3.22px;
        top: 463 * 3.22px;
        width: 1 * 3.22px;
        height: 27 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
    .word18 {
      z-index: 203;
      width: 220 * 3.22px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 20 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 20 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 104 * 3.22px;
    }
    .txt14 {
      z-index: 204;
      width: 160 * 3.22px;
      display: flex;
      justify-content: center;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 8 * 3.22px;
    }
    .layer8 {
      z-index: 91;
      height: 436 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 28 * 3.22px;
      width: 507 * 3.22px;
      align-items: flex-end;
      position: relative;
      .bd36 {
        z-index: auto;
        width: 493 * 3.22px;
        height: 436 * 3.22px;
        .wrap2 {
          z-index: auto;
          width: 24 * 3.22px;
          height: 298 * 3.22px;
          margin-top: 123 * 3.22px;
          .word19 {
            z-index: 171;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
          }
          .info21 {
            z-index: 190;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 172 * 3.22px;
          }
          .txt15 {
            z-index: 195;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 42 * 3.22px;
          }
          .txt16 {
            z-index: 196;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 36 * 3.22px;
          }
        }
        .wrap3 {
          z-index: 153;
          width: 1 * 3.22px;
          height: 436 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-left: 14 * 3.22px;
        }
        .wrap4 {
          z-index: auto;
          width: 454 * 3.22px;
          height: 422 * 3.22px;
          margin-top: 7 * 3.22px;
          .box6 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            margin-left: 12 * 3.22px;
            justify-content: space-between;
            .txt17 {
              z-index: 162;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .word20 {
              z-index: 163;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .box7 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            justify-content: space-between;
            margin: 15 * 3.22px 0 0 12 * 3.22px;
            .word21 {
              z-index: 161;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .word22 {
              z-index: 165;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .box8 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            justify-content: space-between;
            margin: 15 * 3.22px 0 0 12 * 3.22px;
            .info22 {
              z-index: 160;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .txt18 {
              z-index: 164;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .box9 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 14 * 3.22px;
            justify-content: space-between;
            margin: 14 * 3.22px 0 0 12 * 3.22px;
            .info23 {
              z-index: 167;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 14 * 3.22px;
              text-align: left;
            }
            .word23 {
              z-index: 168;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin-top: 1 * 3.22px;
            }
          }
          .word24 {
            z-index: 169;
            width: 430 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            align-self: center;
            margin-top: 14 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .box10 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            justify-content: space-between;
            margin: 15 * 3.22px 0 0 12 * 3.22px;
            .word25 {
              z-index: 173;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .txt19 {
              z-index: 181;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .box11 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            justify-content: space-between;
            margin: 15 * 3.22px 0 0 12 * 3.22px;
            .info24 {
              z-index: 179;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .word26 {
              z-index: 180;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .box12 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            justify-content: space-between;
            margin: 15 * 3.22px 0 0 12 * 3.22px;
            .word27 {
              z-index: 182;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .word28 {
              z-index: 183;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .box13 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            justify-content: space-between;
            margin: 15 * 3.22px 0 0 12 * 3.22px;
            .info25 {
              z-index: 184;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .info26 {
              z-index: 186;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .box14 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            justify-content: space-between;
            margin: 15 * 3.22px 0 0 12 * 3.22px;
            .word29 {
              z-index: 185;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .txt20 {
              z-index: 187;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .word30 {
            z-index: 189;
            width: 430 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .box15 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            justify-content: space-between;
            margin: 15 * 3.22px 0 0 12 * 3.22px;
            .word31 {
              z-index: 198;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .txt21 {
              z-index: 200;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .info27 {
            z-index: 201;
            width: 430 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .box16 {
            z-index: 192;
            width: 454 * 3.22px;
            height: 1 * 3.22px;
            background-color: rgba(153, 153, 153, 1);
            margin-top: 7 * 3.22px;
          }
          .box17 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 12 * 3.22px;
            justify-content: space-between;
            margin: 7 * 3.22px 0 0 12 * 3.22px;
            .word32 {
              z-index: 199;
              width: 375 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
            }
            .txt22 {
              z-index: 194;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
            }
          }
          .box18 {
            z-index: auto;
            width: 427 * 3.22px;
            height: 28 * 3.22px;
            justify-content: space-between;
            margin: 15 * 3.22px 0 0 12 * 3.22px;
            .info28 {
              z-index: 170;
              width: 375 * 3.22px;
              height: 28 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              line-height: 14 * 3.22px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .txt23 {
              z-index: 197;
              width: 25 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin-top: 8 * 3.22px;
            }
          }
        }
      }
      .bd37 {
        z-index: 156;
        position: absolute;
        left: 52 * 3.22px;
        top: 26 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd38 {
        z-index: 155;
        position: absolute;
        left: 52 * 3.22px;
        top: 53 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd39 {
        z-index: 205;
        position: absolute;
        left: 452 * 3.22px;
        top: 0;
        width: 1 * 3.22px;
        height: 107 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd40 {
        z-index: 154;
        position: absolute;
        left: 52 * 3.22px;
        top: 80 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd41 {
        z-index: 158;
        position: absolute;
        left: 52 * 3.22px;
        top: 150 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background: #999999;
      }
      .bd42 {
        z-index: 159;
        position: absolute;
        left: 52 * 3.22px;
        top: 177 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd43 {
        z-index: 174;
        position: absolute;
        left: 52 * 3.22px;
        top: 204 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd44 {
        z-index: 206;
        position: absolute;
        left: 452 * 3.22px;
        top: 151 * 3.22px;
        width: 1 * 3.22px;
        height: 135 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd45 {
        z-index: 175;
        position: absolute;
        left: 52 * 3.22px;
        top: 231 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd46 {
        z-index: 176;
        position: absolute;
        left: 52 * 3.22px;
        top: 258 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd47 {
        z-index: 177;
        position: absolute;
        left: 0;
        top: 285 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd48 {
        z-index: 188;
        position: absolute;
        left: 52 * 3.22px;
        top: 312 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd49 {
        z-index: 207;
        position: absolute;
        left: 452 * 3.22px;
        top: 312 * 3.22px;
        width: 1 * 3.22px;
        height: 28 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd50 {
        z-index: 191;
        position: absolute;
        left: 0;
        top: 339 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .bd51 {
        z-index: 193;
        position: absolute;
        left: 0;
        top: 393 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background: #999999;
      }
    }
    .layer9 {
      z-index: 94;
      height: 206 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 16 * 3.22px;
      width: 507 * 3.22px;
      align-items: center;
      position: relative;
      .main6 {
        z-index: auto;
        width: 484 * 3.22px;
        height: 206 * 3.22px;
        justify-content: space-between;
        .wrap5 {
          z-index: auto;
          width: 28 * 3.22px;
          height: 120 * 3.22px;
          margin-top: 3 * 3.22px;
          .word33 {
            z-index: 97;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .word34 {
            z-index: 152;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 88 * 3.22px;
          }
        }
        .wrap6 {
          z-index: 95;
          width: 1 * 3.22px;
          height: 206 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .wrap7 {
          z-index: auto;
          width: 431 * 3.22px;
          height: 198 * 3.22px;
          margin-top: 3 * 3.22px;
          .bd52 {
            z-index: auto;
            width: 309 * 3.22px;
            height: 20 * 3.22px;
            margin-left: 120 * 3.22px;
            .word35 {
              z-index: 98;
              width: 28 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 14 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 20 * 3.22px;
              text-align: left;
            }
            .word36 {
              z-index: 99;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 14 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 20 * 3.22px;
              text-align: center;
              margin-left: 146 * 3.22px;
            }
            .txt24 {
              z-index: 102;
              width: 28 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 14 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 20 * 3.22px;
              text-align: center;
              margin-left: 26 * 3.22px;
            }
          }
          .word37 {
            z-index: 104;
            width: 430 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            align-self: flex-end;
            margin-top: 11 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .bd53 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 15 * 3.22px;
            .word38 {
              z-index: 110;
              width: 269 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .txt25 {
              z-index: 124;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 25 * 3.22px;
            }
            .label1 {
              z-index: 112;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .bd54 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
            .txt26 {
              z-index: 111;
              width: 269 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .word39 {
              z-index: 125;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 25 * 3.22px;
            }
            .icon3 {
              z-index: 118;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .bd55 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
            .word40 {
              z-index: 127;
              width: 269 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .info29 {
              z-index: 134;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 25 * 3.22px;
            }
            .label2 {
              z-index: 128;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .bd56 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
            .word41 {
              z-index: 136;
              width: 269 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .word42 {
              z-index: 143;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 25 * 3.22px;
            }
            .label3 {
              z-index: 137;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .bd57 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
            .word43 {
              z-index: 144;
              width: 269 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .word44 {
              z-index: 151;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 25 * 3.22px;
            }
            .icon4 {
              z-index: 145;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
        }
      }
      .main7 {
        z-index: 100;
        position: absolute;
        left: 346 * 3.22px;
        top: 0;
        width: 1 * 3.22px;
        height: 27 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main8 {
        z-index: 101;
        position: absolute;
        left: 452 * 3.22px;
        top: 0;
        width: 1 * 3.22px;
        height: 27 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main9 {
        z-index: 96;
        position: absolute;
        left: 0;
        top: 26 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main10 {
        z-index: 107;
        position: absolute;
        left: 52 * 3.22px;
        top: 69 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main11 {
        z-index: 105;
        position: absolute;
        left: 52 * 3.22px;
        top: 98 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main12 {
        z-index: 106;
        position: absolute;
        left: 52 * 3.22px;
        top: 125 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main13 {
        z-index: 108;
        position: absolute;
        left: 347 * 3.22px;
        top: 70 * 3.22px;
        width: 1 * 3.22px;
        height: 136 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main14 {
        z-index: 109;
        position: absolute;
        left: 453 * 3.22px;
        top: 70 * 3.22px;
        width: 1 * 3.22px;
        height: 136 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main15 {
        z-index: 126;
        position: absolute;
        left: 52 * 3.22px;
        top: 152 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main16 {
        z-index: 135;
        position: absolute;
        left: 52 * 3.22px;
        top: 179 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
    .word45 {
      z-index: 337;
      width: 220 * 3.22px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 20 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 20 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 104 * 3.22px;
    }
    .txt27 {
      z-index: 338;
      width: 160 * 3.22px;
      display: flex;
      justify-content: center;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 8 * 3.22px;
    }
    .layer10 {
      z-index: 211;
      height: 459 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 28 * 3.22px;
      width: 507 * 3.22px;
      justify-content: flex-start;
      padding-top: 3 * 3.22px;
      align-items: flex-end;
      position: relative;
      .section1 {
        z-index: auto;
        width: 506 * 3.22px;
        height: 449 * 3.22px;
        .section2 {
          z-index: auto;
          width: 482 * 3.22px;
          height: 20 * 3.22px;
          margin-left: 11 * 3.22px;
          justify-content: space-between;
          .txt28 {
            z-index: 212;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .info30 {
            z-index: 213;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: left;
          }
          .info31 {
            z-index: 214;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
        }
        .section3 {
          z-index: 215;
          width: 506 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: 3 * 3.22px;
        }
        .txt29 {
          z-index: 342;
          width: 430 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
          align-self: flex-end;
          margin: 7 * 3.22px 12 * 3.22px 0 0;
        }
        .info32 {
          z-index: 341;
          width: 19 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
          align-self: flex-start;
          margin: 2 * 3.22px 0 0 16 * 3.22px;
        }
        .section4 {
          z-index: auto;
          width: 428 * 3.22px;
          height: 12 * 3.22px;
          justify-content: space-between;
          margin: 1 * 3.22px 0 0 64 * 3.22px;
          .word46 {
            z-index: 221;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .word47 {
            z-index: 220;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
        }
        .info33 {
          z-index: 229;
          width: 430 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
          align-self: flex-end;
          margin: 16 * 3.22px 12 * 3.22px 0 0;
        }
        .txt30 {
          z-index: 228;
          width: 19 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
          align-self: flex-start;
          margin: 1 * 3.22px 0 0 16 * 3.22px;
        }
        .section5 {
          z-index: auto;
          width: 428 * 3.22px;
          height: 12 * 3.22px;
          justify-content: space-between;
          margin: 1 * 3.22px 0 0 64 * 3.22px;
          .txt31 {
            z-index: 227;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .word48 {
            z-index: 230;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
        }
        .section6 {
          z-index: auto;
          width: 473 * 3.22px;
          height: 28 * 3.22px;
          justify-content: space-between;
          margin: 15 * 3.22px 0 0 19 * 3.22px;
          .txt32 {
            z-index: 305;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 8 * 3.22px;
          }
          .txt33 {
            z-index: 232;
            width: 375 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .info34 {
            z-index: 233;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 8 * 3.22px;
          }
        }
        .section7 {
          z-index: auto;
          width: 475 * 3.22px;
          height: 32 * 3.22px;
          justify-content: space-between;
          margin: 15 * 3.22px 0 0 19 * 3.22px;
          .txt34 {
            z-index: 306;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 160.4px;
          }
          .txt35 {
            z-index: 308;
            width: 430 * 3.22px;
            height: 90.28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .section8 {
          z-index: auto;
          width: 428 * 3.22px;
          height: 12 * 3.22px;
          justify-content: space-between;
          margin: 7 * 3.22px 0 0 64 * 3.22px;
          .info35 {
            z-index: 313;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .word49 {
            z-index: 314;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
        }
        .word50 {
          z-index: 315;
          width: 375 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
          align-self: flex-start;
          margin: 15 * 3.22px 0 0 64 * 3.22px;
        }
        .info36 {
          z-index: 307;
          width: 12 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: #fff;
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: center;
          align-self: flex-start;
          margin: 2 * 3.22px 0 0 19 * 3.22px;
        }
        .section9 {
          z-index: auto;
          width: 428 * 3.22px;
          height: 12 * 3.22px;
          justify-content: space-between;
          margin: 1 * 3.22px 0 0 64 * 3.22px;
          .info37 {
            z-index: 316;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .word51 {
            z-index: 317;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
        }
        .info38 {
          z-index: 321;
          width: 430 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
          align-self: flex-end;
          margin: 15 * 3.22px 12 * 3.22px 0 0;
        }
        .section10 {
          z-index: auto;
          width: 428 * 3.22px;
          height: 12 * 3.22px;
          justify-content: space-between;
          margin: 15 * 3.22px 0 0 64 * 3.22px;
          .info39 {
            z-index: 331;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .info40 {
            z-index: 333;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
        }
        .section11 {
          z-index: auto;
          width: 428 * 3.22px;
          height: 12 * 3.22px;
          justify-content: space-between;
          margin: 15 * 3.22px 0 0 64 * 3.22px;
          .word52 {
            z-index: 335;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .txt36 {
            z-index: 334;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
        }
        .section12 {
          z-index: auto;
          width: 493 * 3.22px;
          height: 12 * 3.22px;
          justify-content: space-between;
          margin: 2 * 3.22px 0 0 13 * 3.22px;
          .word53 {
            z-index: 322;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
          .section13 {
            z-index: 324;
            width: 454 * 3.22px;
            height: 1 * 3.22px;
            background-color: rgba(153, 153, 153, 1);
            margin-top: 5 * 3.22px;
          }
        }
        .section14 {
          z-index: auto;
          width: 428 * 3.22px;
          height: 12 * 3.22px;
          justify-content: space-between;
          margin: 1 * 3.22px 0 0 64 * 3.22px;
          .word54 {
            z-index: 332;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .word55 {
            z-index: 327;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
        }
        .section15 {
          z-index: auto;
          width: 428 * 3.22px;
          height: 14 * 3.22px;
          justify-content: space-between;
          margin: 14 * 3.22px 0 0 64 * 3.22px;
          .info41 {
            z-index: 234;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 14 * 3.22px;
            text-align: left;
          }
          .word56 {
            z-index: 328;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 1 * 3.22px;
          }
        }
        .section16 {
          z-index: auto;
          width: 428 * 3.22px;
          height: 12 * 3.22px;
          justify-content: space-between;
          margin: 14 * 3.22px 0 0 64 * 3.22px;
          .word57 {
            z-index: 329;
            width: 375 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .word58 {
            z-index: 330;
            width: 25 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
        }
      }
      .section17 {
        z-index: 216;
        position: absolute;
        left: 452 * 3.22px;
        top: 0;
        width: 1 * 3.22px;
        height: 27 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section18 {
        z-index: 223;
        position: absolute;
        left: 52 * 3.22px;
        top: 53 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background: #999999;
      }
      .section19 {
        z-index: 224;
        position: absolute;
        left: 452 * 3.22px;
        top: 53 * 3.22px;
        width: 1 * 3.22px;
        height: 28 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section20 {
        z-index: 219;
        position: absolute;
        left: 0;
        top: 80 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background: #999999;
      }
      .section21 {
        z-index: 222;
        position: absolute;
        left: 52 * 3.22px;
        top: 107 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background: #999999;
      }
      .section22 {
        z-index: 218;
        position: absolute;
        left: 0;
        top: 134 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background: #999999;
      }
      .section23 {
        z-index: 339;
        position: absolute;
        left: 452 * 3.22px;
        top: 108 * 3.22px;
        width: 1 * 3.22px;
        height: 69 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section24 {
        z-index: 225;
        position: absolute;
        left: 0;
        top: 177 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section25 {
        z-index: 226;
        position: absolute;
        left: 52 * 3.22px;
        top: 216 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section26 {
        z-index: 217;
        position: absolute;
        left: 52 * 3.22px;
        top: 0;
        width: 1 * 3.22px;
        height: 458 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section27 {
        z-index: 319;
        position: absolute;
        left: 452 * 3.22px;
        top: 216 * 3.22px;
        width: 1 * 3.22px;
        height: 27 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section28 {
        z-index: 309;
        position: absolute;
        left: 0;
        top: 243 * 3.22px;
        width: 1460px;
        height: 1 * 3.22px;
        margin-left: 170px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section29 {
        z-index: 310;
        position: absolute;
        left: 52 * 3.22px;
        top: 270 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section30 {
        z-index: 320;
        position: absolute;
        left: 452 * 3.22px;
        top: 271 * 3.22px;
        width: 1 * 3.22px;
        height: 27 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section31 {
        z-index: 311;
        position: absolute;
        left: 0;
        top: 297 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section32 {
        z-index: 318;
        position: absolute;
        left: 52 * 3.22px;
        top: 324 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section33 {
        z-index: 323;
        position: absolute;
        left: 52 * 3.22px;
        top: 351 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background: #999999;
      }
      .section34 {
        z-index: 325;
        position: absolute;
        left: 52 * 3.22px;
        top: 405 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background: #999999;
      }
    }
    .layer11 {
      z-index: 236;
      height: 190 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 16 * 3.22px;
      width: 507 * 3.22px;
      align-items: center;
      position: relative;
      .section35 {
        z-index: auto;
        width: 482 * 3.22px;
        height: 190 * 3.22px;
        justify-content: space-between;
        .box19 {
          z-index: auto;
          width: 28 * 3.22px;
          height: 180 * 3.22px;
          margin-top: 3 * 3.22px;
          .txt37 {
            z-index: 237;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .txt38 {
            z-index: 299;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .info42 {
            z-index: 300;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 17 * 3.22px;
          }
          .txt39 {
            z-index: 301;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .txt40 {
            z-index: 302;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .word59 {
            z-index: 303;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .info43 {
            z-index: 304;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
        }
        .box20 {
          z-index: 241;
          width: 1 * 3.22px;
          height: 190 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .box21 {
          z-index: auto;
          width: 269 * 3.22px;
          height: 180 * 3.22px;
          margin-top: 3 * 3.22px;
          .info44 {
            z-index: 238;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: left;
            align-self: center;
          }
          .txt41 {
            z-index: 250;
            width: 269 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 11 * 3.22px;
          }
          .word60 {
            z-index: 249;
            width: 269 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 17 * 3.22px;
          }
          .word61 {
            z-index: 251;
            width: 269 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .txt42 {
            z-index: 274;
            width: 269 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word62 {
            z-index: 283;
            width: 269 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .info45 {
            z-index: 291;
            width: 269 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
        }
        .box22 {
          z-index: 242;
          width: 1 * 3.22px;
          height: 190 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .box23 {
          z-index: auto;
          width: 81 * 3.22px;
          height: 180 * 3.22px;
          margin-top: 3 * 3.22px;
          .word63 {
            z-index: 239;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .word64 {
            z-index: 271;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 11 * 3.22px;
          }
          .word65 {
            z-index: 264;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 17 * 3.22px;
          }
          .word66 {
            z-index: 272;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
          .word67 {
            z-index: 281;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
          .info46 {
            z-index: 290;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
          .word68 {
            z-index: 298;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
        }
        .box24 {
          z-index: 243;
          width: 1 * 3.22px;
          height: 190 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .box25 {
          z-index: auto;
          width: 28 * 3.22px;
          height: 182 * 3.22px;
          margin-top: 3 * 3.22px;
          .word69 {
            z-index: 240;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .label4 {
            z-index: 265;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 9 * 3.22px;
          }
          .icon5 {
            z-index: 252;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 13 * 3.22px;
          }
          .icon6 {
            z-index: 258;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .icon7 {
            z-index: 275;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label5 {
            z-index: 284;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .icon8 {
            z-index: 292;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
        }
      }
      .section36 {
        z-index: 248;
        position: absolute;
        left: 0;
        top: 26 * 3.22px;
        width: 506 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section37 {
        z-index: 247;
        position: absolute;
        left: 0;
        top: 53 * 3.22px;
        width: 506 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
  }
  .main17 {
    z-index: 76;
    position: absolute;
    left: 390 * 3.22px;
    top: 173 * 3.22px;
    width: 1 * 3.22px;
    height: 84 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main18 {
    z-index: 77;
    position: absolute;
    left: 496 * 3.22px;
    top: 173 * 3.22px;
    width: 1 * 3.22px;
    height: 84 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main19 {
    z-index: 9;
    position: absolute;
    left: 496 * 3.22px;
    top: 272 * 3.22px;
    width: 1 * 3.22px;
    height: 27 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main20 {
    z-index: 157;
    position: absolute;
    left: 97 * 3.22px;
    top: 1045 * 3.22px;
    width: 455 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main21 {
    z-index: 208;
    position: absolute;
    left: 496 * 3.22px;
    top: 1304 * 3.22px;
    width: 1 * 3.22px;
    height: 71 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main22 {
    z-index: 340;
    position: absolute;
    left: 496 * 3.22px;
    top: 2096 * 3.22px;
    width: 1 * 3.22px;
    height: 134 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main23 {
    z-index: 326;
    position: absolute;
    left: 97 * 3.22px;
    top: 2204 * 3.22px;
    width: 455 * 3.22px;
    height: 1 * 3.22px;
    background: #999999;
  }
  .main24 {
    z-index: 245;
    position: absolute;
    left: 45 * 3.22px;
    top: 2329 * 3.22px;
    width: 507 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main25 {
    z-index: 246;
    position: absolute;
    left: 45 * 3.22px;
    top: 2356 * 3.22px;
    width: 507 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main26 {
    z-index: 273;
    position: absolute;
    left: 45 * 3.22px;
    top: 2383 * 3.22px;
    width: 507 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main27 {
    z-index: 282;
    position: absolute;
    left: 45 * 3.22px;
    top: 2410 * 3.22px;
    width: 507 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
}
</style>