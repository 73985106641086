<template>
  <div class="newTestReports">
    <!-- <img
      @click="goBack"
      v-if="/android/.test(this.ua) && $route.query.token === undefined"
      src="../../../assets/img/close.png"
      alt=""
      class="colse"
    /> -->
    <img
      @click="goBack"
      src="../../../assets/img/close.png"
      alt=""
      class="colse"
    />
    <!-- 打印按钮 -->
    <!--<div-->
    <!--class="title_right"-->
    <!--@mouseover="mouseOver"-->
    <!--@mouseleave="mouseLeave"-->
    <!--@click="pdfs"-->
    <!--v-if="/android/.test(this.ua) ? false : true"-->
    <!--&gt;-->
    <!--<img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />-->
    <!--</div>-->
    <!-- 下载按钮 -->
    <!-- <div
      class="title_right"
      @click="pdfs"
      v-if="/android/.test(this.ua) ? false : true"
    >
      <img src="../../../assets/img/down_icon.png" alt="" />
    </div> -->
    <!-- <div>导出PDF</div> -->
    <div class="newTestReport" id="newTestReport" ref="newTestReport">
      <div class="title">
        <!--左侧二维码暂时注释-->
        <div class="title_left" style="visibility: hidden">
          <dl>
            <dt><img :src="scaleReport.qRCode" alt="" /></dt>
            <dd>
              <!-- <img src="../../assets/img/report/erweima.png" alt="" /> -->
              <p>扫描二维码</p>
              <p>关注“上谷健康”公众号</p>
              <p>查看报告</p>
            </dd>
          </dl>
        </div>
        <div class="title_content">
          <p>{{ scaleReport.hospitalName }}</p>
          <p>{{ scaleReport.scaleName }}</p>
        </div>
        <div></div>
      </div>
      <div class="model_top">
        <div class="model_top_div">
          <div class="model_top_div_one">
            <div class="model_top_item">
              <div class="model_top_item_label">
                <span> 姓名：{{ scaleReport.patientName }} </span>
              </div>
            </div>
            <div class="model_top_item">
              <div class="model_top_item_label">
                <span> 性别：{{ scaleReport.sexStr }}</span>
              </div>
              <div class="model_top_item_value"></div>
            </div>
            <div class="model_top_item">
              <div class="model_top_item_label">
                <span> 年龄：{{ scaleReport.age }}岁 </span>
              </div>
            </div>
            <div class="model_top_item">
              <div class="model_top_item_label">
                <span> 教育年限：{{ scaleReport.educationYears }}年 </span>
              </div>
            </div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">
              <p
                :class="
                  scaleReport.profession.length !== 0 &&
                  scaleReport.profession.length > 8
                    ? 'widt'
                    : ''
                "
              >
                职业： {{ scaleReport.profession }}
              </p>
            </div>
          </div>
        </div>
        <div class="model_top_div">
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 编号：{{ scaleReport.serialNumber }} </span>
            </div>
          </div>
          <!-- <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 测评人：{{ scaleReport.cpDoctorName }} </span>
            </div>
          </div> -->
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 测评时间：{{ scaleReport.cpTimeStr }} </span>
            </div>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>维度</td>
            <td>子维度</td>
            <td>得分</td>
            <td>得分范围</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in scaleReport.dimensionalityList">
            <tr :key="index">
              <td
                :rowspan="item.dimensions.length"
                style="display: table-cell; vertical-align: middle"
              >
                {{ item.name }}
              </td>
              <td>{{ item.dimensions[0].name }}</td>
              <td>{{ item.dimensions[0].score }}</td>
              <td>{{ item.dimensions[0].scoreRange }}</td>
            </tr>
            <tr
              v-for="(ele, inx) in item.dimensions.length - 1"
              :key="index + '-' + inx"
            >
              <td>{{ item.dimensions[ele].name }}</td>
              <td>{{ item.dimensions[ele].score }}</td>
              <td>{{ item.dimensions[ele].scoreRange }}</td>
            </tr>
          </template>
          <tr>
            <td colspan="2">总分</td>
            <td>{{ scaleReport.totalPoints }}</td>
            <td>{{ scaleReport.pointsRange }}</td>
          </tr>
        </tbody>
      </table>
      <div class="modal-desc-content" v-if="scaleReport.reportComment !== ''">
        <div v-html="scaleReport.reportComment"></div>
      </div>
      <div class="modal-desc test-explain bor target-node-item">
        <div v-html="scaleReport.scaleReport"></div>
      </div>
      <div class="androidBox" v-if="/android/.test(this.ua)"></div>
      <!--第二部分标题-->
      <div class="title title2">
        <div class="title_left" style="visibility: hidden">
          <dl>
            <dt><img :src="scaleReport.qRCode" alt="" /></dt>
            <dd>
              <!-- <img src="../../assets/img/report/erweima.png" alt="" /> -->
              <p>扫描二维码</p>
              <p>关注“上谷健康”公众号</p>
              <p>查看报告</p>
            </dd>
          </dl>
        </div>
        <div class="title_content">
          <p>{{ scaleReport.hospitalName }}</p>
          <p>{{ scaleReport.scaleName }}</p>
        </div>
        <div></div>
      </div>
      <!--总分解释-->
      <div class="modal-desc target-node-item textBox">
        <!-- <div
          v-html="item.content"
          v-for="(item, index) in scaleReport.resultContents2"
          :key="index"
        ></div> -->
        <div v-html="scaleReport.totalScoreDesc"></div>
        <!-- <div
          v-html="item.content"
          v-for="(item, index) in scaleReport.resultContents2"
          :key="index"
        ></div> -->
        <div v-html="scaleReport.dimensionsOfDesc"></div>
      </div>

      <div class="modal-descsay target-node-item">
        <h1>建议</h1>
        <!-- <div
          v-html="item.content"
          v-for="(item, index) in scaleReport.resultContents2"
          :key="index"
        ></div> -->
        <div v-html="scaleReport.suggest"></div>
      </div>
      <!-- <echarts></echarts> -->
      <div class="footerpage">
        <p>{{ scaleReport.signatureName }}：____________</p>
      </div>
      <!-- <div class="modal-desc">
        <h1>脑健康指导建议</h1>
        <div
          v-html="item.content"
          v-for="(item, index) in scaleReport.adviceContents"
          :key="index"
        ></div>
      </div> -->
      <!-- <div class="pageFooter">
        <p>{{ scaleReport.noticeText }}</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import htmlToPdf from "@/assets/js/htmlToPdf";
// import echarts from "./newreports/echarts.vue";
import "../../../assets/font/songti/font.css";
export default {
  // components: { echarts },
  // inject: ["reload"],
  data() {
    return {
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",

      masterId: "",
      utype: "D",
      scaleReport: {
        profession: [],
      }, // 接口数据
      ua: "",
    };
  },
  computed: {
    // times() {
    //   let time = "";
    //   if (this.scaleReport.time !== undefined) {
    //     console.log(this.scaleReport.time + "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    //     time = this.scaleReport.time.substring(
    //       0,
    //       this.scaleReport.time.lastIndexOf(":")
    //     );
    //   }
    //   return time;
    // },
    // 这里需要把store 动态的数据放到computed里面才会同步更新视图
    token() {
      return this.$store.state.token;
    },
  },
  created() {
    if (this.$route.query.token !== undefined) {
      sessionStorage.setItem("access_token", this.$route.query.token);
    }
  },
  mounted() {
    // this.ua = navigator.userAgent.toLowerCase();
    this.masterId = this.$route.query.masterId;
    // masterId = 63ad13b53ee945c6980a11d75e95dd4e
    this.$http
      .get(
        `report/getOptimizeResult?masterId=${this.masterId}&utype=${this.utype}`
      )
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          console.log(res.data);
          this.scaleReport = res.data;
          if (
            this.scaleReport.profession === "" ||
            this.scaleReport.profession === undefined ||
            this.scaleReport.profession === null
          ) {
            this.scaleReport.profession = "--";
          }
          // let ua = navigator.userAgent.toLowerCase();
          // if (/android/.test(ua)) {
          //   window.JSBridge.goPrint(
          //     window.location.origin +
          //       "/newhamdreport?token=" +
          //       this.token +
          //       "&masterId=" +
          //       this.$route.query.masterId
          //   );
          // }
          let vm = this;
          const A4_WIDTH = 592.28;
          const A4_HEIGHT = 841.89;
          vm.$nextTick(async () => {
            let target = vm.$refs.newTestReport;
            let pageHeight = (target.scrollWidth / A4_WIDTH) * A4_HEIGHT;
            let lableListID =
              document.getElementsByClassName("target-node-item");
            console.log(lableListID);
            for (let i = 0; i < lableListID.length; i++) {
              let multiple = Math.ceil(
                (lableListID[i].offsetTop + lableListID[i].offsetHeight) /
                  pageHeight
              );
              if (this.isSplit(lableListID, i, multiple * pageHeight)) {
                let divParent = lableListID[i].parentNode; // 获取该div的父节点
                let newNode = document.createElement("div");
                newNode.style.background = "#ffffff";
                let _H =
                  multiple * pageHeight -
                  (lableListID[i].offsetTop + lableListID[i].offsetHeight);
                newNode.style.height = _H + 20 + "px";
                let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
                // 判断兄弟节点是否存在
                if (next) {
                  // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
                  divParent.insertBefore(newNode, next);
                } else {
                  // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
                  divParent.appendChild(newNode);
                }
              }
            }
          });
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    // 处理导出pdf截断问题
    isSplit(nodes, index, pageHeight) {
      if (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      ) {
        return true;
      }
      return false;
    },
    goBack() {
      // let ua = navigator.userAgent.toLowerCase();
      // if (/android/.test(ua)) {
      //   window.JSBridge.goPrint("");
      //   this.$router.go(-1);
      // }
      this.$router.go(-1);
    },
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t, document.body.style.zoom);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector("#newTestReport"),
          `${this.scaleReport.patientName}_${this.scaleReport.scaleName}`
        );
      }
    },
  },
};
</script>


<style lang='scss'>
/*stration*/
.newTestReports {
  width: 100%;
  position: relative;
  .colse {
    position: absolute;
    right: 39px;
    top: 39px;
    width: 71px;
    height: 71px;
  }
  .title_right {
    position: absolute;
    top: 200px;
    right: 200px;
    img {
      width: 71px;
      height: 71px;
    }
  }
}

#newTestReport {
  font-family: "songti";
  padding: 113px 142px 129px 142px;
  color: #333333;
  .widt {
    /*width: 404px;*/
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .androidBox {
    height: 335px;
  }
  .title2 {
    margin-bottom: 90px;
  }
  .title {
    font-family: "songti";
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_left {
      dl {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        dt {
          img {
            width: 129px;
            height: 129px;
          }
        }
        dd {
          // img {
          //   width: calc(109 * 2px);
          //   height: calc(70 * 2px);
          //   margin: calc(5 * 2px) 0 0 calc(3 * 2px);
          // }
          p {
            white-space: nowrap;
            margin-top: 2px;
            margin-left: 10px;
            font-size: 23px;
          }
        }
      }
    }

    .title_content {
      font-family: "songti";
      text-align: center;
      margin-left: -400px;
      margin-top: 35px;
      p {
        line-height: 1;
      }
      p:nth-child(1) {
        font-size: 65px;
        font-family: "songti" !important;
      }
      p:nth-child(2) {
        margin-top: 26px;
        font-size: 52px;
        font-family: "songti" !important;
      }
    }
  }

  .model_top {
    font-family: "songti";
    margin-top: 90px;
    padding-bottom: 26px;
    border-bottom: 3px solid #999;
    .model_top_div {
      display: flex;
      justify-content: space-between;
      .model_top_item {
        .model_top_item_label {
          font-size: 39px;
          color: #000;
          p {
            span {
              width: 3px;
            }
          }
        }
      }
    }

    .model_top_div:nth-child(1) {
      margin-bottom: 14px;
      .model_top_div_one {
        display: flex;
        .model_top_item {
          margin-left: 41px;
        }
        .model_top_item:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }
  table {
    font-family: "songti";
    width: 100%;
    margin-top: 39px;
    border-bottom: none;
    tr {
      td {
        text-align: center;
        display: table-cell;
        vertical-align: middle;
      }
    }

    thead {
      tr {
        td {
          border-top: solid 3px #999;
          border-left: 3px solid #999999;
          border-bottom: 3px solid #999999;
          padding: 10px 65px;
          font-size: 45px;
          font-weight: 400;
          color: #000;
        }
        td:last-child {
          border-right: 3px solid #999999;
        }
      }
    }

    tbody {
      tr {
        td {
          border-left: 3px solid #999999;
          border-bottom: 3px solid #999999;
          font-size: 37px;
          color: #000;
          line-height: 60px;
          padding: 12px 65px;
        }
        td:last-child {
          border-right: 3px solid #999999;
        }
      }
      tr:last-child {
        td:nth-child(1) {
          border-right: none;
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
  //   .modal-desc-content {
  //     margin-top: 8*2px;
  //     border-bottom: 2*2px solid #ccc;
  //     padding-bottom: 8*2px;
  //     font-size: 12*2px;
  //     div {
  //       line-height: 23*2px;
  //     }
  //   }
  /*测评结果说明样式*/
  .test-explain {
    height: 326px;
  }
  .textBox {
    p {
      margin-left: 103px;
      font-weight: 300;
      color: #000000;
    }
    ul {
      margin-left: 103px;
      font-weight: 300;
      color: #000000;
    }
  }
  .modal-desc {
    font-family: "songti";
    // margin-top: 20*2px;
    border: 3px solid #999;
    font-size: 39px;
    margin-top: -4px;
    margin-bottom: 52px;
    padding: 19px 34px 52px 39px;
    h1 {
      width: 100%;
      display: block;
      font-size: 39px;
      color: #333333;
      font-weight: 400;
    }
    div {
      margin-top: 13px;
      line-height: 60px;
    }
  }
  .bor {
    border-top: none;
  }
  .modal-descsay {
    font-family: "songti";
    color: #000000;
    border: 3px solid #999;

    h1 {
      font-size: 45px;
      border-bottom: 3px solid #999;
      font-weight: 400;
      padding: 10px 39px;
    }
    div {
      font-size: 45px;
      line-height: 68px;
      padding: 13px 39px 52px 39px;
      p {
        text-indent: 2em;
      }
    }
  }
  .footerpage {
    font-family: "songti";
    margin-top: 90px;
    font-size: 39px;
    text-align: right;
  }
  // .pageFooter {
  //   margin-top: calc(20 * 2px);
  //   font-size: calc(14 * 2px);
  //   text-align: center;
  //   color: #777;
  // }
}
</style>
