<template>
  <div>
    <div
      class="label1"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="pdfs"
    >
      <img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />
    </div>
    <div class="page flex-col">
      <div class="wrap1 flex-col">
        <div class="mod1 flex-row">
          <span class="word1">{{ constants.hospitalName }}</span>
        </div>
        <span class="txt1">{{ constants.title }}</span>
        <div class="mod2 flex-row">
          <span class="word2">姓名：{{ constants.name }}</span>
          <span class="word3">性别：{{ constants.sex }}</span>
          <span class="info1">年龄：{{ constants.age }}岁</span>
          <span class="word4">教育年限：{{ constants.eduTotal }}年</span>
          <span class="txt2">职业：{{ constants.job }}</span>
        </div>
        <div class="mod3 flex-row">
          <span class="info2">编号：{{ constants.reportNo }}</span>
          <span class="word5">测评时间：{{ constants.reportDate }}</span>
        </div>
        <div class="mod4 flex-col"></div>
        <div class="mod5 flex-col">
          <div class="wrap2 flex-col">
            <div class="section1 flex-row">
              <span class="txt3">序号</span>
              <span class="info3">题目</span>
              <span class="word6">实际回答</span>
              <span class="word7">结果</span>
            </div>
            <div class="section2 flex-col"></div>
            <div class="section3 flex-row">
              <span class="info4">1</span>
              <span class="txt4">现在是哪一年？</span>
              <span class="info5">{{ itmes.srmmse1Value }}年</span>
              <img
                class="label2"
                :src="itmes.srmmse1 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section4 flex-row">
              <span class="info6">2</span>
              <span class="word8">现在是什么季节？</span>
              <span class="txt5">{{
                itmes.srmmse2Value === "0"
                  ? "春季"
                  : itmes.srmmse2Value === "1"
                  ? "夏季"
                  : itmes.srmmse2Value === "2"
                  ? "秋季"
                  : itmes.srmmse2Value === "3"
                  ? "冬季"
                  : ""
              }}</span>
              <img
                class="label2"
                :src="itmes.srmmse2 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section5 flex-row">
              <span class="txt6">3</span>
              <span class="info7">现在是几月份？</span>
              <span class="txt7">{{ itmes.srmmse3Value }}月</span>
              <img
                class="label2"
                :src="itmes.srmmse3 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section6 flex-row">
              <span class="txt8">4</span>
              <span class="txt9">今天是几号？</span>
              <span class="info8">{{ itmes.srmmse4Value }}号</span>
              <img
                class="label2"
                :src="itmes.srmmse4 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section7 flex-row">
              <span class="word9">5</span>
              <span class="word10">今天是星期几？</span>
              <span class="info9"
                >星期{{
                  itmes.srmmse5Value === "1"
                    ? "一"
                    : itmes.srmmse5Value === "2"
                    ? "二"
                    : itmes.srmmse5Value === "3"
                    ? "三"
                    : itmes.srmmse5Value === "4"
                    ? "四"
                    : itmes.srmmse5Value === "5"
                    ? "五"
                    : itmes.srmmse5Value === "6"
                    ? "六"
                    : "日"
                }}</span
              >
              <img
                class="label2"
                :src="itmes.srmmse5 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section8 flex-row">
              <span class="info10">6</span>
              <span class="word11">这是什么城市？</span>
              <span class="info11">{{
                itmes.srmmse6 === 1 ? "是" : "否"
              }}</span>
              <img
                class="label2"
                :src="itmes.srmmse6 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section9 flex-col"></div>
            <div class="section10 flex-row">
              <span class="txt10">7</span>
              <span class="word12">这是什么区(县)？</span>
              <span class="info12">{{
                itmes.srmmse7 === 1 ? "是" : "否"
              }}</span>
              <img
                class="label2"
                :src="itmes.srmmse7 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section11 flex-col"></div>
            <div class="section12 flex-row">
              <span class="word13">8</span>
              <span class="txt11">这是什么街道(乡)？</span>
              <span class="word14">{{
                itmes.srmmse8 === 1 ? "是" : "否"
              }}</span>
              <img
                class="label2"
                :src="itmes.srmmse8 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section13 flex-row">
              <span class="word15">9</span>
              <span class="info13">这是第几楼？</span>
              <span class="word16">{{
                itmes.srmmse9 === 1 ? "是" : "否"
              }}</span>
              <img
                class="label2"
                :src="itmes.srmmse9 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section14 flex-row">
              <span class="word17">10</span>
              <span class="word18">这是什么地方？</span>
              <span class="info14">{{
                itmes.srmmse10 === 1 ? "是" : "否"
              }}</span>
              <img
                class="label2"
                :src="itmes.srmmse10 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section15 flex-col"></div>
            <div class="section16 flex-row">
              <span class="txt12">11</span>
              <span class="infoBox1">
                现在您将听到三个词，全部听完之后请您重复说一遍。您要记住这三个词，过一会还要问您
                <br />
                答案：皮球，国旗，树木
              </span>
              <div class="mod6 flex-col">
                <div class="mod7 flex-row">
                  <span class="word19">{{ itmes.srmmse11s1Str }}</span>
                  <img
                    class="label2"
                    :src="itmes.srmmse11s1 === 1 ? this.dui : this.cuo"
                    alt=""
                  />
                </div>
                <div class="mod8 flex-col"></div>
                <div class="mod9 flex-row">
                  <span class="info15">{{ itmes.srmmse12s1Str }}</span>
                  <img
                    class="label2"
                    :src="itmes.srmmse11s2 === 1 ? this.dui : this.cuo"
                    alt=""
                  />
                </div>
                <div class="mod10 flex-col"></div>
                <div class="mod11 flex-row">
                  <span class="txt13">{{ itmes.srmmse13s1Str }}</span>
                  <img
                    class="label2"
                    :src="itmes.srmmse11s3 === 1 ? this.dui : this.cuo"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <span class="info16">
              现在请您从100减去7，把答案填到相应的位置。得到的数字再减7，如此一直下去，直到出现“停止”。如果您准备好了，点击“继续”开始答题
            </span>
            <div class="section17 flex-col"></div>
            <div class="section18 flex-row">
              <span class="word20">100-7=93</span>
              <span class="word21">{{ itmes.srmmse12s1Value }}</span>
              <img
                class="label2"
                :src="itmes.srmmse12s1 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section19 flex-row">
              <span class="word22">12</span>
              <span class="word23">再减7</span>
              <span class="word24">{{ itmes.srmmse12s2Value }}</span>
              <img
                class="label2"
                :src="itmes.srmmse12s2 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section20 flex-row">
              <span class="txt14">再减7</span>
              <span class="info17">{{ itmes.srmmse12s3Value }}</span>
              <img
                class="label2"
                :src="itmes.srmmse12s3 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section21 flex-col"></div>
            <div class="section22 flex-row">
              <span class="word25">再减7</span>
              <span class="word26">{{ itmes.srmmse12s4Value }}</span>
              <img
                class="label2"
                :src="itmes.srmmse12s4 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section23 flex-col"></div>
            <div class="section24 flex-row">
              <span class="txt15">再减7</span>
              <span class="info18">{{ itmes.srmmse12s5Value }}</span>
              <img
                class="label2"
                :src="itmes.srmmse12s5 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
            <div class="section25 flex-col"></div>
            <span class="word27">请您说出下方物品的名称</span>
            <div class="section26 flex-col"></div>
            <div class="section27 flex-row">
              <span class="info19">13</span>
              <span class="word28">词1（手表）</span>
              <span class="word29">{{
                itmes.srmmse13s1 === 1 ? "是" : "否"
              }}</span>
              <img
                class="label2"
                :src="itmes.srmmse13s1 === 1 ? this.dui : this.cuo"
                alt=""
              />
            </div>
          </div>
          <div class="wrap3 flex-col"></div>
          <div class="wrap4 flex-col"></div>
          <div class="wrap5 flex-col"></div>
          <div class="wrap6 flex-col"></div>
          <div class="wrap7 flex-col"></div>
          <div class="wrap8 flex-col"></div>
          <div class="wrap9 flex-col"></div>
          <div class="wrap10 flex-col"></div>
          <div class="wrap11 flex-col"></div>
        </div>
        <span class="word30">{{ constants.hospitalName }}</span>
        <span class="txt16">{{ constants.title }}</span>
        <div class="mod12 flex-col">
          <div class="section28 flex-row">
            <div class="bd1 flex-col">
              <span class="word31">14</span>
              <span class="txt17">15</span>
              <span class="txt18">16</span>
              <span class="txt19">17</span>
              <span class="txt20">18</span>
              <span class="word32">19</span>
            </div>
            <div class="bd2 flex-col"></div>
            <div class="bd3 flex-col">
              <div class="main1 flex-row">
                <span class="info20">词2（铅笔）</span>
                <span class="info21">{{
                  itmes.srmmse13s2 === 1 ? "是" : "否"
                }}</span>
                <img
                  class="label2"
                  :src="itmes.srmmse13s2 === 1 ? this.dui : this.cuo"
                  alt=""
                />
              </div>
              <div class="main2 flex-row">
                <span class="word33">
                  下面您将听到一句话，听完后请您清楚的重复一遍
                </span>
                <span class="txt21">{{
                  itmes.srmmse14 === 1 ? "是" : "否"
                }}</span>
                <img
                  class="label2"
                  :src="itmes.srmmse14 === 1 ? this.dui : this.cuo"
                  alt=""
                />
              </div>
              <div class="main3 flex-row">
                <span class="paragraph1">
                  请您念一念下面这句话，并按照这句话的意思去做
                  <br />
                  答案：闭上眼睛
                </span>
                <span class="word34">{{
                  itmes.srmmse15 === 1 ? "是" : "否"
                }}</span>
                <img
                  class="label2"
                  :src="itmes.srmmse15 === 1 ? this.dui : this.cuo"
                  alt=""
                />
              </div>
              <span class="word35">
                下面您将听到一段录音，全部听完后按照这句话的要求做动作
              </span>
              <div class="main4 flex-row">
                <span class="word36">用右手拿纸</span>
                <span class="word37">{{
                  itmes.srmmse16s1 === 1 ? "是" : "否"
                }}</span>
                <img
                  class="label2"
                  :src="itmes.srmmse16s1 === 1 ? this.dui : this.cuo"
                  alt=""
                />
              </div>
              <div class="main5 flex-row">
                <span class="word38">用双手把纸对折</span>
                <span class="word39">{{
                  itmes.srmmse16s2 === 1 ? "是" : "否"
                }}</span>
                <img
                  class="label2"
                  :src="itmes.srmmse16s2 === 1 ? this.dui : this.cuo"
                  alt=""
                />
              </div>
              <div class="main6 flex-row">
                <span class="word40">把纸放在左腿上</span>
                <span class="word41">{{
                  itmes.srmmse16s3 === 1 ? "是" : "否"
                }}</span>
                <img
                  class="label2"
                  :src="itmes.srmmse16s3 === 1 ? this.dui : this.cuo"
                  alt=""
                />
              </div>
              <div class="main7 flex-row">
                <span class="info22">请您说一句话，要有主、谓语</span>
                <span class="info23">{{
                  itmes.srmmse17s2 === 1 ? "是" : "否"
                }}</span>
                <img
                  class="label2"
                  :src="itmes.srmmse17s2 === 1 ? this.dui : this.cuo"
                  alt=""
                />
              </div>
              <span class="word42">请您照下图的样子，在空白出画出来</span>
              <div class="main8 flex-row">
                <span class="word43">两边五边形</span>
                <span class="info24">{{
                  itmes.srmmse18s1 === 1 ? "是" : "否"
                }}</span>
                <img
                  class="label2"
                  :src="itmes.srmmse18s1 === 1 ? this.dui : this.cuo"
                  alt=""
                />
              </div>
              <div class="main9 flex-row">
                <span class="info25">对角交叉形成四边形</span>
                <span class="word44">{{
                  itmes.srmmse18s2 === 1 ? "是" : "否"
                }}</span>
                <img
                  class="label2"
                  :src="itmes.srmmse18s2 === 1 ? this.dui : this.cuo"
                  alt=""
                />
              </div>
              <div class="main10 flex-row">
                <span class="paragraph2">
                  现在您将听到三个词，全部听完之后请您重复说一遍。您要记住这三个词，过一会还要问您
                  <br />
                  答案：皮球，国旗，树木
                </span>
                <div class="layer1 flex-col">
                  <div class="mod13 flex-row">
                    <span class="info26">{{ itmes.srmmse11s1Str }}</span>
                    <img
                      class="label2"
                      :src="itmes.srmmse19s1 === 1 ? this.dui : this.cuo"
                      alt=""
                    />
                  </div>
                  <div class="mod14 flex-col"></div>
                  <div class="mod15 flex-row">
                    <span class="word45">{{ itmes.srmmse12s1Str }}</span>
                    <img
                      class="label2"
                      :src="itmes.srmmse19s2 === 1 ? this.dui : this.cuo"
                      alt=""
                    />
                  </div>
                  <div class="mod16 flex-col"></div>
                  <div class="mod17 flex-row">
                    <span class="word46">{{ itmes.srmmse13s1Str }}</span>
                    <img
                      class="label2"
                      :src="itmes.srmmse19s3 === 1 ? this.dui : this.cuo"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section29 flex-col"></div>
          <div class="section30 flex-col"></div>
          <div class="section31 flex-col"></div>
          <div class="section32 flex-col"></div>
          <div class="section33 flex-col"></div>
          <div class="section34 flex-col"></div>
          <div class="section35 flex-col"></div>
          <div class="section36 flex-col"></div>
          <div class="section37 flex-col"></div>
          <div class="section38 flex-col"></div>
          <div class="section39 flex-col"></div>
          <div class="section40 flex-col"></div>
          <div class="section41 flex-col"></div>
          <div class="section42 flex-col"></div>
          <div class="section43 flex-col"></div>
          <div class="section44 flex-col"></div>
          <div class="section45 flex-col"></div>
        </div>
      </div>
      <div class="wrap12 flex-col"></div>
      <div class="wrap13 flex-col"></div>
      <div class="wrap14 flex-col"></div>
      <div class="wrap15 flex-col"></div>
      <div class="wrap16 flex-col"></div>
      <div class="wrap17 flex-col"></div>
      <div class="wrap18 flex-col"></div>
      <div class="wrap19 flex-col"></div>
    </div>
  </div>
</template>
<script>
import htmlToPdf from "@/assets/js/htmlToPdf";
import "../../../assets/font/songti/font.css";
export default {
  data() {
    return {
      constants: {},
      itmes: "",
      dui: require("../../../assets/img/dui.png"),
      cuo: require("../../../assets/img/cuo.png"),
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",
    };
  },
  methods: {
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t, document.body.style.zoom);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector(".page"),
          `${this.constants.name}_${this.constants.title}(回显)`
        );
      }
    },
  },
  mounted() {
    // ${this.$route.query.masterId}
    let afterUrl = window.location.search.substring(1);
    let path = afterUrl.substring(afterUrl.indexOf("=") + 1);
    this.$http
      .get(`report/getViewResult?masterId=${path}`)
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          this.constants = res.data;
          this.itmes = res.data.object;
          if (
            this.constants.job === "" ||
            this.constants.job === undefined ||
            this.constants.job === null
          ) {
            this.constants.job = "--";
          }
          console.log(this.itmes);
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style scoped lang='scss'>
/*stration*/
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: "songti";
}
button {
  margin: 0;
  padding: 0;
  border: 1 * 3.22px solid transparent;
  outline: none;
  background-color: transparent;
}
.label2 {
  z-index: 20;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  margin-left: 32 * 3.22px;
}
button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.label1 {
  position: absolute;
  top: 200px;
  right: 200px;
  z-index: 352;
  img {
    width: 22 * 3.22px;
    height: 22 * 3.22px;
  }
}
.page {
  z-index: 1;
  position: relative;
  width: 595 * 3.22px;
  height: 1587 * 3.22px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  justify-content: flex-start;
  padding-top: 48 * 3.22px;
  align-items: center;
  .wrap1 {
    z-index: auto;
    width: 507 * 3.22px;
    height: 1303 * 3.22px;
    .mod1 {
      z-index: auto;
      width: 363 * 3.22px;
      height: 22 * 3.22px;
      margin-left: 144 * 3.22px;
      justify-content: space-between;
      .word1 {
        z-index: 209;
        width: 220 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 20 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 20 * 3.22px;
        text-align: center;
      }
    }
    .txt1 {
      z-index: 210;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 6 * 3.22px;
    }
    .mod2 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 28 * 3.22px;
      .word2 {
        z-index: 347;
        width: 72 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .word3 {
        z-index: 348;
        width: 48 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .info1 {
        z-index: 349;
        width: 60 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .word4 {
        z-index: 350;
        width: 84 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 28 * 3.22px;
      }
      .txt2 {
        z-index: 351;
        width: 454px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 15 * 3.22px;
      }
    }
    .mod3 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 8 * 3.22px;
      justify-content: space-between;
      .info2 {
        z-index: 346;
        width: 96 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .word5 {
        z-index: 345;
        width: 156 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
      }
    }
    .mod4 {
      z-index: 344;
      width: 507 * 3.22px;
      height: 1 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-top: 8 * 3.22px;
    }
    .mod5 {
      z-index: 2;
      height: 609 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 12 * 3.22px;
      width: 507 * 3.22px;
      justify-content: center;
      position: relative;
      .wrap2 {
        z-index: auto;
        width: 507 * 3.22px;
        height: 601 * 3.22px;
        .section1 {
          z-index: auto;
          width: 482 * 3.22px;
          height: 20 * 3.22px;
          margin-left: 12 * 3.22px;
          .txt3 {
            z-index: 13;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .info3 {
            z-index: 14;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: left;
            margin-left: 145 * 3.22px;
          }
          .word6 {
            z-index: 15;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
            margin-left: 146 * 3.22px;
          }
          .word7 {
            z-index: 16;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
            margin-left: 26 * 3.22px;
          }
        }
        .section2 {
          z-index: 3;
          width: 507 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: 3 * 3.22px;
        }
        .section3 {
          z-index: auto;
          width: 465 * 3.22px;
          height: 16 * 3.22px;
          margin: 4 * 3.22px 0 0 23 * 3.22px;
          .info4 {
            z-index: 18;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 3 * 3.22px;
          }
          .txt4 {
            z-index: 17;
            width: 84 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 3 * 3.22px 0 0 36 * 3.22px;
          }
          .info5 {
            z-index: 19;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 3 * 3.22px 0 0 210 * 3.22px;
          }
        }
        .section4 {
          z-index: auto;
          width: 465 * 3.22px;
          height: 16 * 3.22px;
          margin: 12 * 3.22px 0 0 23 * 3.22px;
          .info6 {
            z-index: 136;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .word8 {
            z-index: 135;
            width: 96 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 36 * 3.22px;
          }
          .txt5 {
            z-index: 137;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 2 * 3.22px 0 0 198 * 3.22px;
          }
          .icon1 {
            z-index: 50;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section5 {
          z-index: auto;
          width: 464 * 3.22px;
          height: 16 * 3.22px;
          margin: 11 * 3.22px 0 0 24 * 3.22px;
          .txt6 {
            z-index: 140;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .info7 {
            z-index: 139;
            width: 84 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 36 * 3.22px;
          }
          .txt7 {
            z-index: 141;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 2 * 3.22px 0 0 209 * 3.22px;
          }
          .icon2 {
            z-index: 56;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section6 {
          z-index: auto;
          width: 472 * 3.22px;
          height: 16 * 3.22px;
          margin: 11 * 3.22px 0 0 16 * 3.22px;
          .txt8 {
            z-index: 144;
            width: 21 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .txt9 {
            z-index: 143;
            width: 84 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 28 * 3.22px;
          }
          .info8 {
            z-index: 145;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 2 * 3.22px 0 0 210 * 3.22px;
          }
          .icon3 {
            z-index: 62;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section7 {
          z-index: auto;
          width: 474 * 3.22px;
          height: 16 * 3.22px;
          margin: 11 * 3.22px 0 0 14 * 3.22px;
          .word9 {
            z-index: 148;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .word10 {
            z-index: 147;
            width: 96 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 27 * 3.22px;
          }
          .info9 {
            z-index: 149;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 2 * 3.22px 0 0 198 * 3.22px;
          }
          .icon4 {
            z-index: 68;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section8 {
          z-index: auto;
          width: 469 * 3.22px;
          height: 16 * 3.22px;
          margin: 11 * 3.22px 0 0 19 * 3.22px;
          .info10 {
            z-index: 152;
            width: 15 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .word11 {
            z-index: 151;
            width: 84 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 31 * 3.22px;
          }
          .info11 {
            z-index: 153;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 2 * 3.22px 0 0 210 * 3.22px;
          }
          .icon5 {
            z-index: 26;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section9 {
          z-index: 9;
          width: 507 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: 5 * 3.22px;
        }
        .section10 {
          z-index: auto;
          width: 465 * 3.22px;
          height: 16 * 3.22px;
          margin: 5 * 3.22px 0 0 23 * 3.22px;
          .txt10 {
            z-index: 168;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .word12 {
            z-index: 167;
            width: 96 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 36 * 3.22px;
          }
          .info12 {
            z-index: 169;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 2 * 3.22px 0 0 198 * 3.22px;
          }
          .label3 {
            z-index: 32;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section11 {
          z-index: 160;
          width: 507 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: 5 * 3.22px;
        }
        .section12 {
          z-index: auto;
          width: 465 * 3.22px;
          height: 16 * 3.22px;
          margin: 5 * 3.22px 0 0 23 * 3.22px;
          .word13 {
            z-index: 171;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .txt11 {
            z-index: 170;
            width: 108 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 36 * 3.22px;
          }
          .word14 {
            z-index: 172;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 2 * 3.22px 0 0 186 * 3.22px;
          }
          .icon6 {
            z-index: 74;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section13 {
          z-index: auto;
          width: 465 * 3.22px;
          height: 16 * 3.22px;
          margin: 11 * 3.22px 0 0 23 * 3.22px;
          .word15 {
            z-index: 174;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .info13 {
            z-index: 173;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 36 * 3.22px;
          }
          .word16 {
            z-index: 175;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 2 * 3.22px 0 0 222 * 3.22px;
          }
          .icon7 {
            z-index: 80;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section14 {
          z-index: auto;
          width: 471 * 3.22px;
          height: 16 * 3.22px;
          margin: 11 * 3.22px 0 0 17 * 3.22px;
          .word17 {
            z-index: 177;
            width: 18 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .word18 {
            z-index: 176;
            width: 84 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 30 * 3.22px;
          }
          .info14 {
            z-index: 178;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 2 * 3.22px 0 0 210 * 3.22px;
          }
          .icon8 {
            z-index: 86;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section15 {
          z-index: 163;
          width: 507 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: 5 * 3.22px;
        }
        .section16 {
          z-index: auto;
          width: 490 * 3.22px;
          height: 70 * 3.22px;
          margin: 5 * 3.22px 0 0 17 * 3.22px;
          .txt12 {
            z-index: 181;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 29 * 3.22px;
          }
          .infoBox1 {
            z-index: 179;
            width: 269 * 3.22px;
            height: 45 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 13 * 3.22px 0 0 29 * 3.22px;
          }
          .mod6 {
            z-index: auto;
            width: 160 * 3.22px;
            height: 70 * 3.22px;
            margin-left: 13 * 3.22px;
            .mod7 {
              z-index: auto;
              width: 129 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 12 * 3.22px;
              justify-content: space-between;
              .word19 {
                z-index: 180;
                width: 81 * 3.22px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 12 * 3.22px;
                font-family: "songti";
                white-space: nowrap;
                line-height: 12 * 3.22px;
                text-align: center;
                margin-top: 2 * 3.22px;
              }
              .label4 {
                z-index: 92;
                width: 16 * 3.22px;
                height: 16 * 3.22px;
              }
            }
            .mod8 {
              z-index: 165;
              width: 160 * 3.22px;
              height: 1 * 3.22px;
              background-color: rgba(153, 153, 153, 1);
              margin-top: 5 * 3.22px;
            }
            .mod9 {
              z-index: auto;
              width: 129 * 3.22px;
              height: 16 * 3.22px;
              justify-content: space-between;
              margin: 5 * 3.22px 0 0 12 * 3.22px;
              .info15 {
                z-index: 182;
                width: 81 * 3.22px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 12 * 3.22px;
                font-family: "songti";
                white-space: nowrap;
                line-height: 12 * 3.22px;
                text-align: center;
                margin-top: 2 * 3.22px;
              }
              .label5 {
                z-index: 38;
                width: 16 * 3.22px;
                height: 16 * 3.22px;
              }
            }
            .mod10 {
              z-index: 165;
              width: 160 * 3.22px;
              height: 1 * 3.22px;
              background-color: rgba(153, 153, 153, 1);
              margin-top: 5 * 3.22px;
            }
            .mod11 {
              z-index: auto;
              width: 129 * 3.22px;
              height: 16 * 3.22px;
              justify-content: space-between;
              margin: 5 * 3.22px 0 0 12 * 3.22px;
              .txt13 {
                z-index: 187;
                width: 81 * 3.22px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 12 * 3.22px;
                font-family: "songti";
                white-space: nowrap;
                line-height: 12 * 3.22px;
                text-align: center;
                margin-top: 2 * 3.22px;
              }
              .label6 {
                z-index: 98;
                width: 16 * 3.22px;
                height: 16 * 3.22px;
              }
            }
          }
        }
        .info16 {
          z-index: 188;
          width: 430 * 3.22px;
          height: 28 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          line-height: 14 * 3.22px;
          text-align: left;
          align-self: flex-end;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 13 * 3.22px 12 * 3.22px 0 0;
        }
        .section17 {
          z-index: 184;
          width: 454 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          align-self: flex-end;
          margin-top: 7 * 3.22px;
        }
        .section18 {
          z-index: auto;
          width: 423 * 3.22px;
          height: 16 * 3.22px;
          margin: 5 * 3.22px 0 0 65 * 3.22px;
          .word20 {
            z-index: 190;
            width: 104 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 1 * 3.22px;
          }
          .word21 {
            z-index: 191;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 1 * 3.22px 0 0 190 * 3.22px;
          }
          .label7 {
            z-index: 104;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section19 {
          z-index: auto;
          width: 471 * 3.22px;
          height: 20 * 3.22px;
          margin: 11 * 3.22px 0 0 17 * 3.22px;
          .word22 {
            z-index: 189;
            width: 18 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 8 * 3.22px;
          }
          .word23 {
            z-index: 192;
            width: 104 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 1 * 3.22px 0 0 30 * 3.22px;
          }
          .word24 {
            z-index: 193;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 1 * 3.22px 0 0 190 * 3.22px;
          }
          .label8 {
            z-index: 110;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section20 {
          z-index: auto;
          width: 423 * 3.22px;
          height: 16 * 3.22px;
          margin: 7 * 3.22px 0 0 65 * 3.22px;
          .txt14 {
            z-index: 194;
            width: 104 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 1 * 3.22px;
          }
          .info17 {
            z-index: 196;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 1 * 3.22px 0 0 190 * 3.22px;
          }
          .label9 {
            z-index: 116;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section21 {
          z-index: 10;
          width: 454 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          align-self: flex-end;
          margin-top: 5 * 3.22px;
        }
        .section22 {
          z-index: auto;
          width: 423 * 3.22px;
          height: 16 * 3.22px;
          margin: 5 * 3.22px 0 0 65 * 3.22px;
          .word25 {
            z-index: 154;
            width: 42 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 2 * 3.22px;
          }
          .word26 {
            z-index: 197;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 1 * 3.22px 0 0 252 * 3.22px;
          }
          .label10 {
            z-index: 122;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section23 {
          z-index: 11;
          width: 454 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          align-self: flex-end;
          margin-top: 5 * 3.22px;
        }
        .section24 {
          z-index: auto;
          width: 423 * 3.22px;
          height: 16 * 3.22px;
          margin: 5 * 3.22px 0 0 65 * 3.22px;
          .txt15 {
            z-index: 156;
            width: 42 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 2 * 3.22px;
          }
          .info18 {
            z-index: 198;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 1 * 3.22px 0 0 252 * 3.22px;
          }
          .icon9 {
            z-index: 128;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
        .section25 {
          z-index: 155;
          width: 507 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: 5 * 3.22px;
        }
        .word27 {
          z-index: 158;
          width: 132 * 3.22px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 12 * 3.22px;
          font-family: "songti";
          white-space: nowrap;
          line-height: 12 * 3.22px;
          text-align: left;
          align-self: flex-start;
          margin: 7 * 3.22px 0 0 65 * 3.22px;
        }
        .section26 {
          z-index: 157;
          width: 454 * 3.22px;
          height: 1 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          align-self: flex-end;
          margin-top: 7 * 3.22px;
        }
        .section27 {
          z-index: auto;
          width: 472 * 3.22px;
          height: 16 * 3.22px;
          margin: 5 * 3.22px 0 0 16 * 3.22px;
          .info19 {
            z-index: 195;
            width: 21 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 2 * 3.22px;
          }
          .word28 {
            z-index: 159;
            width: 66 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin: 2 * 3.22px 0 0 28 * 3.22px;
          }
          .word29 {
            z-index: 199;
            width: 81 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin: 1 * 3.22px 0 0 228 * 3.22px;
          }
          .label11 {
            z-index: 44;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-left: 32 * 3.22px;
          }
        }
      }
      .wrap3 {
        z-index: 4;
        position: absolute;
        left: 1 * 3.22px;
        top: 53 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .wrap4 {
        z-index: 5;
        position: absolute;
        left: 1 * 3.22px;
        top: 80 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .wrap5 {
        z-index: 6;
        position: absolute;
        left: 1 * 3.22px;
        top: 107 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .wrap6 {
        z-index: 7;
        position: absolute;
        left: 1 * 3.22px;
        top: 134 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .wrap7 {
        z-index: 8;
        position: absolute;
        left: 1 * 3.22px;
        top: 161 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .wrap8 {
        z-index: 205;
        position: absolute;
        left: 346 * 3.22px;
        top: 0;
        width: 1 * 3.22px;
        height: 377 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .wrap9 {
        z-index: 206;
        position: absolute;
        left: 452 * 3.22px;
        top: 0;
        width: 1 * 3.22px;
        height: 377 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .wrap10 {
        z-index: 161;
        position: absolute;
        left: 1 * 3.22px;
        top: 242 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .wrap11 {
        z-index: 162;
        position: absolute;
        left: 1 * 3.22px;
        top: 269 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
    .word30 {
      z-index: 213;
      width: 220 * 3.22px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 20 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 20 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 104 * 3.22px;
    }
    .txt16 {
      z-index: 214;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 8 * 3.22px;
    }
    .mod12 {
      z-index: 216;
      height: 393 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 28 * 3.22px;
      width: 507 * 3.22px;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      .section28 {
        z-index: auto;
        width: 493 * 3.22px;
        height: 392 * 3.22px;
        justify-content: space-between;
        .bd1 {
          z-index: auto;
          width: 24 * 3.22px;
          height: 325 * 3.22px;
          margin-top: 33 * 3.22px;
          .word31 {
            z-index: 244;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
          }
          .txt17 {
            z-index: 248;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 23 * 3.22px;
          }
          .txt18 {
            z-index: 252;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 64 * 3.22px;
          }
          .txt19 {
            z-index: 253;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 55 * 3.22px;
          }
          .txt20 {
            z-index: 305;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 42 * 3.22px;
          }
          .word32 {
            z-index: 312;
            width: 19 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 69 * 3.22px;
          }
        }
        .bd2 {
          z-index: 217;
          width: 1 * 3.22px;
          height: 392 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
        }
        .bd3 {
          z-index: auto;
          width: 442 * 3.22px;
          height: 383 * 3.22px;
          margin-top: 4 * 3.22px;
          .main1 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            .info20 {
              z-index: 222;
              width: 66 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .info21 {
              z-index: 223;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 228 * 3.22px;
            }
            .label12 {
              z-index: 224;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .main2 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
            justify-content: space-between;
            .word33 {
              z-index: 243;
              width: 265 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 14 * 3.22px;
              text-align: left;
              margin-top: 1 * 3.22px;
            }
            .txt21 {
              z-index: 245;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin-top: 2 * 3.22px;
              margin-left: 30 * 3.22px;
            }
            .label13 {
              z-index: 230;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .main3 {
            z-index: auto;
            width: 422 * 3.22px;
            height: 28 * 3.22px;
            justify-content: space-between;
            margin: 13 * 3.22px 0 0 1 * 3.22px;
            .paragraph1 {
              z-index: 247;
              width: 265 * 3.22px;
              height: 28 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              line-height: 14 * 3.22px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .word34 {
              z-index: 249;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin-top: 8 * 3.22px;
              margin-left: 30 * 3.22px;
            }
            .label14 {
              z-index: 236;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-top: 6 * 3.22px;
            }
            .label2 {
              z-index: 236;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-top: 6 * 3.22px;
            }
          }
          .word35 {
            z-index: 251;
            width: 430 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .main4 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 13 * 3.22px;
            .word36 {
              z-index: 295;
              width: 250 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .word37 {
              z-index: 296;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 44 * 3.22px;
            }
            .icon10 {
              z-index: 254;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .main5 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
            .word38 {
              z-index: 297;
              width: 250 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .word39 {
              z-index: 298;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 44 * 3.22px;
            }
            .icon11 {
              z-index: 260;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .main6 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
            .word40 {
              z-index: 299;
              width: 250 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .word41 {
              z-index: 301;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 44 * 3.22px;
            }
            .label15 {
              z-index: 266;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .main7 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
            .info22 {
              z-index: 300;
              width: 250 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .info23 {
              z-index: 302;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 44 * 3.22px;
            }
            .label16 {
              z-index: 284;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .word42 {
            z-index: 304;
            width: 430 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 13 * 3.22px;
          }
          .main8 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 13 * 3.22px;
            .word43 {
              z-index: 316;
              width: 250 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .info24 {
              z-index: 317;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 44 * 3.22px;
            }
            .label17 {
              z-index: 318;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .main9 {
            z-index: auto;
            width: 423 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
            .info25 {
              z-index: 336;
              width: 250 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: left;
              margin-top: 2 * 3.22px;
            }
            .word44 {
              z-index: 337;
              width: 81 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              white-space: nowrap;
              line-height: 12 * 3.22px;
              text-align: center;
              margin: 2 * 3.22px 0 0 44 * 3.22px;
            }
            .icon12 {
              z-index: 324;
              width: 16 * 3.22px;
              height: 16 * 3.22px;
              margin-left: 32 * 3.22px;
            }
          }
          .main10 {
            z-index: auto;
            width: 442 * 3.22px;
            height: 70 * 3.22px;
            margin-top: 11 * 3.22px;
            justify-content: space-between;
            .paragraph2 {
              z-index: 310;
              width: 269 * 3.22px;
              height: 49 * 3.22px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 12 * 3.22px;
              font-family: "songti";
              line-height: 14 * 3.22px;
              text-align: left;
              margin-top: 11 * 3.22px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .layer1 {
              z-index: auto;
              width: 160 * 3.22px;
              height: 70 * 3.22px;
              .mod13 {
                z-index: auto;
                width: 129 * 3.22px;
                height: 16 * 3.22px;
                margin-left: 12 * 3.22px;
                justify-content: space-between;
                .info26 {
                  z-index: 311;
                  width: 81 * 3.22px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(0, 0, 0, 1);
                  font-size: 12 * 3.22px;
                  font-family: "songti";
                  white-space: nowrap;
                  line-height: 12 * 3.22px;
                  text-align: center;
                  margin-top: 2 * 3.22px;
                }
                .label18 {
                  z-index: 330;
                  width: 16 * 3.22px;
                  height: 16 * 3.22px;
                }
              }
              .mod14 {
                z-index: 307;
                width: 160 * 3.22px;
                height: 1 * 3.22px;
                background-color: rgba(153, 153, 153, 1);
                margin-top: 5 * 3.22px;
              }
              .mod15 {
                z-index: auto;
                width: 129 * 3.22px;
                height: 16 * 3.22px;
                justify-content: space-between;
                margin: 5 * 3.22px 0 0 12 * 3.22px;
                .word45 {
                  z-index: 313;
                  width: 81 * 3.22px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(0, 0, 0, 1);
                  font-size: 12 * 3.22px;
                  font-family: "songti";
                  white-space: nowrap;
                  line-height: 12 * 3.22px;
                  text-align: center;
                  margin-top: 2 * 3.22px;
                }
                .label19 {
                  z-index: 272;
                  width: 16 * 3.22px;
                  height: 16 * 3.22px;
                }
              }
              .mod16 {
                z-index: 308;
                width: 160 * 3.22px;
                height: 1 * 3.22px;
                background-color: rgba(153, 153, 153, 1);
                margin-top: 5 * 3.22px;
              }
              .mod17 {
                z-index: auto;
                width: 129 * 3.22px;
                height: 16 * 3.22px;
                justify-content: space-between;
                margin: 5 * 3.22px 0 0 12 * 3.22px;
                .word46 {
                  z-index: 314;
                  width: 81 * 3.22px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(0, 0, 0, 1);
                  font-size: 12 * 3.22px;
                  font-family: "songti";
                  white-space: nowrap;
                  line-height: 12 * 3.22px;
                  text-align: center;
                  margin-top: 2 * 3.22px;
                }
                .label20 {
                  z-index: 278;
                  width: 16 * 3.22px;
                  height: 16 * 3.22px;
                }
              }
            }
          }
        }
      }
      .section29 {
        z-index: 218;
        position: absolute;
        left: 0;
        top: 26 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section30 {
        z-index: 338;
        position: absolute;
        left: 346 * 3.22px;
        top: 1 * 3.22px;
        width: 1 * 3.22px;
        height: 96 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section31 {
        z-index: 339;
        position: absolute;
        left: 452 * 3.22px;
        top: 1 * 3.22px;
        width: 1 * 3.22px;
        height: 96 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section32 {
        z-index: 219;
        position: absolute;
        left: 0;
        top: 53 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section33 {
        z-index: 220;
        position: absolute;
        left: 0;
        top: 96 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section34 {
        z-index: 221;
        position: absolute;
        left: 52 * 3.22px;
        top: 123 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section35 {
        z-index: 290;
        position: absolute;
        left: 52 * 3.22px;
        top: 150 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section36 {
        z-index: 291;
        position: absolute;
        left: 52 * 3.22px;
        top: 177 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section37 {
        z-index: 340;
        position: absolute;
        left: 346 * 3.22px;
        top: 123 * 3.22px;
        width: 1 * 3.22px;
        height: 109 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section38 {
        z-index: 341;
        position: absolute;
        left: 452 * 3.22px;
        top: 123 * 3.22px;
        width: 1 * 3.22px;
        height: 109 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section39 {
        z-index: 292;
        position: absolute;
        left: 0;
        top: 204 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section40 {
        z-index: 293;
        position: absolute;
        left: 0;
        top: 231 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section41 {
        z-index: 303;
        position: absolute;
        left: 52 * 3.22px;
        top: 258 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section42 {
        z-index: 306;
        position: absolute;
        left: 52 * 3.22px;
        top: 285 * 3.22px;
        width: 455 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section43 {
        z-index: 309;
        position: absolute;
        left: 0;
        top: 312 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section44 {
        z-index: 342;
        position: absolute;
        left: 346 * 3.22px;
        top: 258 * 3.22px;
        width: 1 * 3.22px;
        height: 135 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .section45 {
        z-index: 343;
        position: absolute;
        left: 452 * 3.22px;
        top: 258 * 3.22px;
        width: 1 * 3.22px;
        height: 135 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
  }
  .wrap12 {
    z-index: 204;
    position: absolute;
    left: 96 * 3.22px;
    top: 557.4px;
    width: 1 * 3.22px;
    height: 609 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .wrap13 {
    z-index: 183;
    position: absolute;
    left: 45 * 3.22px;
    top: 550 * 3.22px;
    width: 507 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .wrap14 {
    z-index: 185;
    position: absolute;
    left: 98 * 3.22px;
    top: 620 * 3.22px;
    width: 454 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .wrap15 {
    z-index: 186;
    position: absolute;
    left: 98 * 3.22px;
    top: 648 * 3.22px;
    width: 454 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .wrap16 {
    z-index: 200;
    position: absolute;
    left: 390 * 3.22px;
    top: 595 * 3.22px;
    width: 1 * 3.22px;
    height: 136 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .wrap17 {
    z-index: 201;
    position: absolute;
    left: 496 * 3.22px;
    top: 595 * 3.22px;
    width: 1 * 3.22px;
    height: 136 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .wrap18 {
    z-index: 202;
    position: absolute;
    left: 390 * 3.22px;
    top: 755 * 3.22px;
    width: 1 * 3.22px;
    height: 27 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .wrap19 {
    z-index: 203;
    position: absolute;
    left: 496 * 3.22px;
    top: 755 * 3.22px;
    width: 1 * 3.22px;
    height: 27 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
}
</style>
