<template>
    <div class="layout">
        <Layout>
            <Header :style="{position: 'fixed', width: '100%', background: '#fff'}">

<!--                <Menu mode="horizontal" theme="light" active-name="1">-->
<!--                    <MenuItem name="1">-->
<!--                        <Icon type="ios-paper" />-->
<!--                        内容管理-->
<!--                    </MenuItem>-->
<!--                    <MenuItem name="2">-->
<!--                        <Icon type="ios-people" />-->
<!--                        用户管理-->
<!--                    </MenuItem>-->

<!--                    <MenuItem name="4">-->
<!--                        <Icon type="ios-construct" />-->
<!--                        综合设置-->
<!--                    </MenuItem>-->
<!--                </Menu>-->

                <Menu mode="horizontal" theme="light" active-name="1">
                    <div class="layout-logo"></div>
                    <div class="layout-nav">
                        <MenuItem name="1">
                            <Icon type="ios-navigate"></Icon>
                            Item 1
                        </MenuItem>
                        <MenuItem name="2">
                            <Icon type="ios-keypad"></Icon>
                            Item 2
                        </MenuItem>
                        <MenuItem name="3">
                            <Icon type="ios-analytics"></Icon>
                            Item 3
                        </MenuItem>
                        <MenuItem name="4">
                            <Icon type="ios-paper"></Icon>
                            Item 4
                        </MenuItem>
                        <Submenu name="5">
                            <template slot="title">
                                <Icon type="ios-stats" />
                                统计分析
                            </template>
                            <MenuGroup title="使用">
                                <MenuItem name="3-1">新增和启动</MenuItem>
                                <MenuItem name="3-2">活跃分析</MenuItem>
                                <MenuItem name="3-3">时段分析</MenuItem>
                            </MenuGroup>
                            <MenuGroup title="留存">
                                <MenuItem name="3-4">用户留存</MenuItem>
                                <MenuItem name="3-5">流失用户</MenuItem>
                            </MenuGroup>
                        </Submenu>
                    </div>
                </Menu>
            </Header>

            <Layout style="height:calc(100vh - 64px); margin-top: 64px">
                <Sider hide-trigger :style="{background: '#fff'}">
                    <Menu active-name="0" theme="light" width="auto" :open-names="[]">
                        <MenuItem name="0">
                            <Icon type="ios-navigate"></Icon>工作区域
                        </MenuItem>
                        <Submenu name="1">
                            <template slot="title">
                                <Icon type="ios-navigate"></Icon>用户管理
                            </template>
                            <MenuItem name="1-1" >用户列表</MenuItem>
                            <MenuItem name="1-2">用户统计</MenuItem>
                        </Submenu>
                    </Menu>
                </Sider>
                <Layout :style="{padding: '0 24px 24px'}">
                                        <Breadcrumb :style="{margin: '24px 0'}">
                                            <BreadcrumbItem>Home</BreadcrumbItem>
                                            <BreadcrumbItem>Components</BreadcrumbItem>
                                            <BreadcrumbItem>Layout</BreadcrumbItem>
                                        </Breadcrumb>
                                        <Content :style="{padding: '24px', minHeight: '280px', background: '#fff'}">
                                            <Content></Content>
                                        </Content>
                </Layout>
            </Layout>
        </Layout>
    </div>
</template>
<script>
    export default {

    }
</script>
<style scoped>
    .layout{
        /*border: 1px solid #d7dde4;*/
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }
    .layout-logo{
        width: 100px;
        height: 30px;
        background: #5b6270;
        border-radius: 3px;
        float: left;
        position: relative;
        top: 15px;
        left: 20px;
    }
    .layout-nav{
        width: 720px;
        margin: 0 auto;
        margin-right: 20px;
    }
    .layout-footer-center{
        text-align: center;
    }
    .ivu-menu-horizontal{
        height: auto;
        line-height: 63px;
    }
</style>
