<template>
  <div class="dome" ref="dome">
    <div class="main">
      <p>您的脑健康指数为：{{ score }}<span>分</span></p>
      <div class="one">
        <section class="ruler">
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm">
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
            <section class="mm"></section>
          </section>
          <section class="cm"></section>
        </section>
        <div class="div" ref="colo" :style="'width:' + score + '%;'"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import html2canvas from "html2canvas";
// import { getCookiePid } from "../../../../assets/js/session.sg";
export default {
  name: "dome",
  props: ["score"],
  data() {
    return {
      // getCookiePids: "",
    };
  },
  // mounted() {
  //   this.getCookiePids = getCookiePid();
  //   if (this.score) {
  //     this.toImage(10);
  //   }
  // },
    watch: {
        score (val) {
            console.log(parseFloat(this.score));
            if (parseFloat(this.score) <= 17) {
                this.$refs.colo.className = "coloo";
            } else if (parseFloat(this.score) > 17 && parseFloat(this.score) <= 66) {
                this.$refs.colo.className = "coloos";
            } else if (parseFloat(this.score) > 66 && parseFloat(this.score) <= 85) {
                this.$refs.colo.className = "coloosTree";
            } else if (parseFloat(this.score) > 85 && parseFloat(this.score) < 100) {
                this.$refs.colo.className = "csa";
            } else if (parseFloat(this.score) === 100) {
                this.$refs.colo.className = "csal";
            }
        }
    },
  methods: {
    // toImage() {
    //   let _this = this;
    //   let opts = { useCORS: true };
    //   html2canvas(this.$refs.dome, opts).then(function (canvas) {
    //     let imgUri = canvas.toDataURL("image/jpeg", 2); // 获取生成的图片的url
    //     console.log(imgUri, _this.getCookiePids);
    //     let data = {
    //       file: imgUri,
    //       userId: _this.getCookiePids,
    //     };
    //     _this.$http
    //       .post(
    //         "/system/file/uploadBase64",
    //         _this.$http.adornData(data, false, "form")
    //       )
    //       .then((res) => {
    //         if (res.code === 1) {
    //           console.log(res);
    //         } else {
    //           alert(res.msg);
    //         }
    //       })
    //       .catch((err) => {
    //         alert("服务");
    //       });
    //   });
    // },
  },
};
</script>

<style scoped lang='scss'>
/*dome*/
* {
  margin: 0;
  padding: 0;
}
img {
  width: 100%;
  height: 100%;
}
.dome {
  width: 100%;
  height: 100%;
  font-size: 32px;
  .main {
    padding: 0 0 20px 0;
    p {
      text-align: center;
      font-size: 39px;
      font-weight: 400;
      margin-bottom: 10px;
      color: #000000;
      span {
        font-size: 39px;
        font-weight: 400;
      }
    }
  }
}
.one {
  margin-top: 39px;
  position: relative;
  /*width: 270px;*/
  flex: 1;
}
.ruler {
  position: relative;
  /*width: 270px;*/
  width: 100%;
  height: 45px;
  position: absolute;
  left: 0;
  background: linear-gradient(
    270deg,
    rgba(14, 201, 154, 0.25) 0%,
    rgba(190, 191, 83, 0.25) 59%,
    rgba(226, 132, 12, 0.25) 84%,
    rgba(218, 99, 75, 0.25) 100%
  );

  border-radius: 45px;
}
/*.div {*/
  /*height: 45px;*/

  /*background: linear-gradient(*/
    /*270deg,*/
    /*#0ec99a 0%,*/
    /*#bebf53 59%,*/
    /*#e2840c 84%,*/
    /*#da634b 100%*/
  /*);*/

  /*opacity: 0.8;*/
  /*border-radius: 45px;*/
  /*position: absolute;*/
  /*left: 0;*/
/*}*/
.coloo {
    height: 45px;
    background: linear-gradient(
        270deg,
        rgba(226, 131, 12, 1) 0%,
        rgba(218, 99, 75, 1) 100%
    );
    opacity: 0.8;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    position: absolute;
    left: 0;
}
.coloos {
    height: 45px;

    background: linear-gradient(
        270deg,
        rgba(216, 204, 26, 1) 0%,
        rgba(226, 131, 12, 1) 60%,
        rgba(218, 99, 75, 1) 100%
    );

    opacity: 0.8;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    position: absolute;
    left: 0;
}
.coloosTree {
    height: 45px;

    background: linear-gradient(
        270deg,
        rgba(73, 197, 95, 1) 0%,
        rgba(216, 204, 26, 1) 20%,
        rgba(226, 131, 12, 1) 80%,
        rgba(218, 99, 75, 1) 100%
    );

    opacity: 0.8;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    position: absolute;
    left: 0;
}
.csa {
    height: 45px;

    background: linear-gradient(
        270deg,
        rgba(14, 201, 154, 1) 0%,
        rgba(73, 197, 95, 1) 15%,
        rgba(216, 204, 26, 1) 34%,
        rgba(226, 131, 12, 1) 83%,
        rgba(218, 99, 75, 1) 100%
    );

    opacity: 0.8;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    position: absolute;
    left: 0;
}
.csal {
    height: 45px;

    background: linear-gradient(
        270deg,
        rgba(14, 201, 154, 1) 0%,
        rgba(73, 197, 95, 1) 15%,
        rgba(216, 204, 26, 1) 34%,
        rgba(226, 131, 12, 1) 83%,
        rgba(218, 99, 75, 1) 100%
    );

    opacity: 0.8;
    border-radius: 45px;
    position: absolute;
    left: 0;
}
.ruler .cm,
.ruler .mm {
  position: absolute;
  border-left: 1px solid #fff;
  height: 10px;
  width: 10%;
  bottom: 0;
}
.ruler .cm,
.ruler .mm:last-child {
  position: absolute;
  border-left: 1px solid #fff;
  height: 6px;
  width: 0%;
  bottom: 0;
  z-index: 100;
}
.ruler .cm:first-child {
  .mm:last-child {
    border: none;
    height: 0;
  }
}
.ruler .cm:after {
  position: absolute;
  left: -5px;
  font-size: 12px;
}
.ruler .mm {
  height: 30px;
}
/*.ruler .mm:nth-of-type(5) {*/
/*height: 10px;*/
/*}*/
.ruler .cm:nth-of-type(1) {
  left: 0%;
  border-left: none;
}
.ruler .cm:nth-of-type(1):after {
  content: "0";
  color: #b2b2b2;
  margin-left: 5px;
  font-size: 39px;
}
.ruler .cm:nth-of-type(2) {
  left: 10%;
}
.ruler .cm:nth-of-type(2):after {
  content: "10";
  color: #b2b2b2;
  font-size: 39px;
}
/*.ruler .cm:nth-of-type(2):after {*/
/*content: "1";*/
/*}*/
.ruler .cm:nth-of-type(3) {
  left: 20%;
}
.ruler .cm:nth-of-type(3):after {
  content: "20";
  color: #b2b2b2;
  font-size: 39px;
}
.ruler .cm:nth-of-type(4) {
  left: 30%;
}
.ruler .cm:nth-of-type(4):after {
  content: "30";
  color: #b2b2b2;
  font-size: 39px;
}
.ruler .cm:nth-of-type(5) {
  left: 40%;
}
.ruler .cm:nth-of-type(5):after {
  content: "40";
  color: #b2b2b2;
  font-size: 39px;
}
.ruler .cm:nth-of-type(6) {
  left: 50%;
}
.ruler .cm:nth-of-type(6):after {
  content: "50";
  color: #b2b2b2;
  font-size: 39px;
}
/*.ruler .cm:nth-of-type(6):after {*/
/*content: "5";*/
/*}*/
.ruler .cm:nth-of-type(7) {
  left: 60%;
}
.ruler .cm:nth-of-type(7):after {
  content: "60";
  color: #b2b2b2;
  font-size: 39px;
}
.ruler .cm:nth-of-type(8) {
  left: 70%;
}
.ruler .cm:nth-of-type(8):after {
  content: "70";
  color: #b2b2b2;
  font-size: 39px;
}
/*.ruler .cm:nth-of-type(8):after {*/
/*content: "7";*/
/*}*/
.ruler .cm:nth-of-type(9) {
  left: 80%;
}
.ruler .cm:nth-of-type(9):after {
  content: "80";
  color: #b2b2b2;
  font-size: 39px;
}
.ruler .cm:nth-of-type(10) {
  left: 90%;
}
.ruler .cm:nth-of-type(10):after {
  content: "90";
  color: #b2b2b2;
  margin-left: -3px;
  font-size: 39px;
}
.ruler .cm:nth-of-type(11) {
  left: 100%;
  border-left: none;
}
.ruler .cm:nth-of-type(11):after {
  content: "100";
  color: #b2b2b2;
  margin-left: -16px;
  font-size: 39px;
}
/*.ruler .cm:nth-of-type(10):after {*/
/*content: "9";*/
/*}*/
/*.ruler .cm:nth-of-type(11) {*/
/*left: 100%;*/
/*}*/
/*.ruler .cm:nth-of-type(11):after {*/
/*content: "100";*/
/*}*/
.ruler .mm:nth-of-type(1) {
  left: 0%;
}
.ruler .mm:nth-of-type(2) {
  left: 10%;
}
.ruler .mm:nth-of-type(3) {
  left: 20%;
}
.ruler .mm:nth-of-type(4) {
  left: 30%;
}
.ruler .mm:nth-of-type(5) {
  left: 40%;
}
.ruler .mm:nth-of-type(6) {
  left: 50%;
}
.ruler .mm:nth-of-type(7) {
  left: 60%;
}
.ruler .mm:nth-of-type(8) {
  left: 70%;
}
.ruler .mm:nth-of-type(9) {
  left: 80%;
}
.ruler .mm:nth-of-type(10) {
  left: 90%;
}
.ruler .mm:nth-of-type(11) {
  left: 100%;
}
</style>
