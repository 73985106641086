<template>
  <div>
    <div
      class="image"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="pdfs"
    >
      <img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />
    </div>
    <div class="page flex-col">
      <div class="group1 flex-col">
        <div class="main1 flex-row">
          <span class="word1">{{ constants.hospitalName }}</span>
        </div>
        <span class="info1">{{ constants.title }}</span>
        <div class="main2 flex-row">
          <span class="info2">姓名：{{ constants.name }}</span>
          <span class="info3">性别：{{ constants.sex }}</span>
          <span class="info4">年龄：{{ constants.age }}岁</span>
          <span class="txt1">教育年限：{{ constants.eduTotal }}年</span>
          <span class="txt2">职业：{{ constants.job }}</span>
        </div>
        <div class="main3 flex-row">
          <span class="word2">编号：{{ constants.reportNo }}</span>
          <span class="word3">测评时间：{{ constants.reportDate }}</span>
        </div>
        <div class="main4 flex-col"></div>
        <div class="main5 flex-col">
          <div class="main6 flex-row">
            <div class="outer1 flex-col">
              <span class="txt3">序号</span>
              <span class="word4">1</span>
              <span class="txt4">2</span>
              <span class="txt5">3</span>
              <span class="txt6">4</span>
              <span class="word5">5</span>
              <span class="txt7">6</span>
              <span class="word6">7</span>
              <span class="info5">8</span>
              <span class="txt8">9</span>
              <span class="info6">10</span>
              <span class="info7">11</span>
              <span class="info8">12</span>
              <span class="word7">13</span>
              <span class="info9">14</span>
            </div>
            <div class="outer2 flex-col"></div>
            <div class="outer3 flex-col">
              <span class="info10">题目</span>
              <span class="info11">忧郁情绪</span>
              <span class="info12">有罪感</span>
              <span class="word8">自杀</span>
              <span class="word9">入睡困难</span>
              <span class="txt9">睡眠不深</span>
              <span class="txt10">早醒</span>
              <span class="info13">工作和兴趣</span>
              <span class="word10">阻滞（缓慢）</span>
              <span class="word11">激越</span>
              <span class="word12">精神性焦虑</span>
              <span class="word13">躯体性焦虑</span>
              <span class="txt11">胃肠道症状</span>
              <span class="info14">全身症状</span>
              <span class="txt12">性症状</span>
            </div>
            <div class="outer4 flex-col"></div>
            <div class="outer5 flex-col">
              <img
                class="label2"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo1 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label3"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo2 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon1"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo3 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label4"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo4 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon2"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo5 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon3"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo6 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label5"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo7 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon4"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo8 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label6"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo9 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label7"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo10 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon5"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo11 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon6"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo12 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon7"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo13 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon8"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo14 === 0 ? xuanzhong : weixuanzhong"
              />
            </div>
            <div class="outer6 flex-col">
              <span class="word14">无</span>
              <span class="word15">无</span>
              <span class="word16">无</span>
              <span class="word17">无</span>
              <span class="info15">无</span>
              <span class="word18">无</span>
              <span class="info16">无</span>
              <span class="word19">无</span>
              <span class="word20">无</span>
              <span class="word21">无</span>
              <span class="txt13">无</span>
              <span class="txt14">无</span>
              <span class="txt15">无</span>
              <span class="word22">无</span>
            </div>
            <div class="outer7 flex-col">
              <img
                class="label8"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo1 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label9"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo1 === 3 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon9"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo2 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label10"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo2 === 3 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon10"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo3 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label11"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo3 === 3 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label12"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo4 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label13"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo5 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon11"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo6 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon12"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo7 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label14"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo7 === 3 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon13"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo8 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label15"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo8 === 3 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label16"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo9 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label17"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo9 === 3 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon14"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo10 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon15"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo10 === 3 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label18"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo11 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label19"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo11 === 3 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon16"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo12 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon17"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo13 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label20"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo14 === 1 ? xuanzhong : weixuanzhong"
              />
            </div>
            <div class="outer8 flex-col">
              <span class="word23">实际回答</span>
              <span class="word24">问到才说</span>
              <span class="word25">表情音调中流露</span>
              <span class="txt16">责备自己</span>
              <span class="word26">有罪恶妄想</span>
              <span class="word27">感到生活没意义</span>
              <span class="info17">有自杀念头</span>
              <span class="txt17">有时有</span>
              <span class="word28">轻度</span>
              <span class="word29">醒后能睡</span>
              <span class="word30">问到才说</span>
              <span class="info18">活动少、效率降</span>
              <span class="word31">轻度缓慢</span>
              <span class="word32">交谈困难</span>
              <span class="txt18">有点心神不定</span>
              <span class="word33">不能静坐</span>
              <span class="word34">问到才说</span>
              <span class="word35">表情音调中流露</span>
              <span class="info19">轻度</span>
              <span class="txt19">重度</span>
              <span class="info20">食欲减退</span>
              <span class="word36">疼痛或疲倦</span>
              <span class="txt20">性欲减退</span>
            </div>
            <div class="outer9 flex-col">
              <img
                class="label21"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo1 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label22"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo1 === 4 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label23"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo2 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label24"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo2 === 4 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon18"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo3 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label25"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo3 === 4 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label26"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo4 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label27"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo5 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label28"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo6 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon19"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo7 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label29"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo7 === 4 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label30"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo8 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label31"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo8 === 4 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon20"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo9 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label32"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo9 === 4 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label33"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo10 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon21"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo10 === 4 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label34"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo11 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon22"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo11 === 4 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label35"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo12 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label36"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo13 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label37"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo14 === 2 ? xuanzhong : weixuanzhong"
              />
            </div>
            <div class="outer10 flex-col">
              <span class="info21">自发表达忧郁</span>
              <span class="word37">言行急著</span>
              <span class="txt21">反复思考自己过失</span>
              <span class="info22">威胁幻觉</span>
              <span class="word38">常想与死相关的事</span>
              <span class="word39">有严重自杀行为</span>
              <span class="txt22">每晚有</span>
              <span class="word40">严重</span>
              <span class="word41">醒后不能睡</span>
              <span class="info23">自发表达兴趣减退</span>
              <span class="word42">停止工作</span>
              <span class="word43">明显缓慢</span>
              <span class="word44">木僵</span>
              <span class="word45">明显心神不定</span>
              <span class="word46">小动作多</span>
              <span class="word47">自发表达</span>
              <span class="info24">明显惊恐</span>
              <span class="txt23">中度</span>
              <span class="word48">影响生活</span>
              <span class="txt24">需要用消化药</span>
              <span class="word49">症状明显</span>
              <span class="info25">症状明显</span>
            </div>
          </div>
          <div class="main7 flex-col"></div>
          <div class="main8 flex-col"></div>
          <div class="main9 flex-col"></div>
          <div class="main10 flex-col"></div>
          <div class="main11 flex-col"></div>
          <div class="main12 flex-col"></div>
          <div class="main13 flex-col"></div>
          <div class="main14 flex-col"></div>
          <div class="main15 flex-col"></div>
          <div class="main16 flex-col"></div>
          <div class="main17 flex-col"></div>
          <div class="main18 flex-col"></div>
          <div class="main19 flex-col"></div>
          <div class="main20 flex-col"></div>
        </div>
        <span class="txt25">{{ constants.hospitalName }}</span>
        <span class="txt26">{{ constants.title }}</span>
        <div class="main21 flex-col">
          <div class="layer1 flex-row">
            <div class="main22 flex-col">
              <span class="word50">15</span>
              <span class="word51">16</span>
              <span class="word52">17</span>
            </div>
            <div class="main23 flex-col"></div>
            <div class="main24 flex-col">
              <span class="word53">疑病</span>
              <span class="txt27">体重减轻</span>
              <span class="txt28">自知力</span>
            </div>
            <div class="main25 flex-col"></div>
            <div class="main26 flex-col">
              <img
                class="label38"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo15 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon23"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo16 === 0 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon24"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo17 === 0 ? xuanzhong : weixuanzhong"
              />
            </div>
            <div class="main27 flex-col">
              <span class="word54">无</span>
              <span class="word55">无</span>
              <span class="info26">无</span>
            </div>
            <div class="main28 flex-col">
              <img
                class="icon25"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo15 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label39"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo15 === 3 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label40"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo16 === 1 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label41"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo17 === 1 ? xuanzhong : weixuanzhong"
              />
            </div>
            <div class="main29 flex-col">
              <span class="word56">过分关注身体</span>
              <span class="info27">有疑病妄想</span>
              <span class="txt29">可能有</span>
              <span class="word57">承认有病</span>
            </div>
            <div class="main30 flex-col">
              <img
                class="label42"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo15 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon26"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo15 === 4 ? xuanzhong : weixuanzhong"
              />
              <img
                class="label43"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo16 === 2 ? xuanzhong : weixuanzhong"
              />
              <img
                class="icon27"
                referrerpolicy="no-referrer"
                :src="itmes.hamiltonNo17 === 2 ? xuanzhong : weixuanzhong"
              />
            </div>
            <div class="main31 flex-col">
              <span class="info28">反复考虑健康问题</span>
              <span class="word58">同时有幻觉</span>
              <span class="word59">肯定有</span>
              <span class="word60">否认有病</span>
            </div>
          </div>
          <div class="layer2 flex-col"></div>
          <div class="layer3 flex-col"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../assets/font/songti/font.css";
import htmlToPdf from "@/assets/js/htmlToPdf";
export default {
  data() {
    return {
      constants: {},
      itmes: "",
      xuanzhong: require("../../../assets/img/xuanzhong.png"),
      weixuanzhong: require("../../../assets/img/weixuanzhong.png"),
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",
    };
  },
  methods: {
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t, document.body.style.zoom);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector(".page"),
          `${this.constants.name}_${this.constants.title}(回显)`
        );
      }
    },
  },
  mounted() {
    // ${this.$route.query.masterId}
    let afterUrl = window.location.search.substring(1);
    let path = afterUrl.substring(afterUrl.indexOf("=") + 1);
    this.$http
      .get(`report/getViewResult?masterId=${path}`)
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          this.constants = res.data;
          this.itmes = res.data.object;
          if (
            this.constants.job === "" ||
            this.constants.job === undefined ||
            this.constants.job === null
          ) {
            this.constants.job = "--";
          }
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style scoped lang='scss'>
/*stration*/
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: "songti";
}
button {
  margin: 0;
  padding: 0;
  border: 1 * 3.22px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.image {
  position: absolute;
  top: 200px;
  right: 200px;
  z-index: 352;
  img {
    width: 22 * 3.22px;
    height: 22 * 3.22px;
  }
}

.page {
  z-index: 1;
  position: relative;
  width: 595 * 3.22px;
  height: 1353 * 3.22px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  justify-content: flex-start;
  padding-top: 48 * 3.22px;
  align-items: center;
  .group1 {
    z-index: auto;
    width: 507 * 3.22px;
    height: 988 * 3.22px;
    .main1 {
      z-index: auto;
      width: 363 * 3.22px;
      height: 22 * 3.22px;
      margin-left: 144 * 3.22px;
      justify-content: space-between;
      .word1 {
        z-index: 47;
        width: 220 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 20 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 20 * 3.22px;
        text-align: center;
      }
      .label1 {
        z-index: 49;
        width: 22 * 3.22px;
        height: 22 * 3.22px;
      }
    }
    .info1 {
      z-index: 48;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 6 * 3.22px;
    }
    .main2 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 28 * 3.22px;
      .info2 {
        z-index: 54;
        width: 72 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .info3 {
        z-index: 55;
        width: 48 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .info4 {
        z-index: 56;
        width: 60 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .txt1 {
        z-index: 57;
        width: 84 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 28 * 3.22px;
      }
      .txt2 {
        z-index: 58;
        width: 454px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 15 * 3.22px;
      }
    }
    .main3 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 8 * 3.22px;
      justify-content: space-between;
      .word2 {
        z-index: 53;
        width: 96 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .word3 {
        z-index: 52;
        width: 156 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
      }
    }
    .main4 {
      z-index: 51;
      width: 507 * 3.22px;
      height: 1 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-top: 8 * 3.22px;
    }
    .main5 {
      z-index: 2;
      height: 586 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 12 * 3.22px;
      width: 507 * 3.22px;
      align-items: flex-start;
      padding-left: 12 * 3.22px;
      position: relative;
      .main6 {
        z-index: auto;
        width: 460 * 3.22px;
        height: 586 * 3.22px;
        .outer1 {
          z-index: auto;
          width: 28 * 3.22px;
          height: 568 * 3.22px;
          margin-top: 11 * 3.22px;
          .txt3 {
            z-index: 8;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .word4 {
            z-index: 13;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 30 * 3.22px;
          }
          .txt4 {
            z-index: 14;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 36 * 3.22px;
          }
          .txt5 {
            z-index: 19;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 36 * 3.22px;
          }
          .txt6 {
            z-index: 20;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 25 * 3.22px;
          }
          .word5 {
            z-index: 45;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 13 * 3.22px;
          }
          .txt7 {
            z-index: 23;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 17 * 3.22px;
          }
          .word6 {
            z-index: 26;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 26 * 3.22px;
          }
          .info5 {
            z-index: 29;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 36 * 3.22px;
          }
          .txt8 {
            z-index: 32;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 36 * 3.22px;
          }
          .info6 {
            z-index: 35;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 36 * 3.22px;
          }
          .info7 {
            z-index: 40;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 36 * 3.22px;
          }
          .info8 {
            z-index: 42;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 25 * 3.22px;
          }
          .word7 {
            z-index: 451;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 13 * 3.22px;
          }
          .info9 {
            z-index: 452;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
        }
        .outer2 {
          z-index: 3;
          width: 1 * 3.22px;
          height: 586 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-left: 12 * 3.22px;
        }
        .outer3 {
          z-index: auto;
          width: 72 * 3.22px;
          height: 568 * 3.22px;
          margin: 11 * 3.22px 0 0 12 * 3.22px;
          .info10 {
            z-index: 9;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: left;
            align-self: center;
          }
          .info11 {
            z-index: 11;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 30 * 3.22px;
          }
          .info12 {
            z-index: 12;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .word8 {
            z-index: 17;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .word9 {
            z-index: 18;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 25 * 3.22px;
          }
          .txt9 {
            z-index: 44;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 13 * 3.22px;
          }
          .txt10 {
            z-index: 22;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 17 * 3.22px;
          }
          .info13 {
            z-index: 25;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 26 * 3.22px;
          }
          .word10 {
            z-index: 28;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .word11 {
            z-index: 31;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .word12 {
            z-index: 34;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .word13 {
            z-index: 39;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .txt11 {
            z-index: 41;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 25 * 3.22px;
          }
          .info14 {
            z-index: 449;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 13 * 3.22px;
          }
          .txt12 {
            z-index: 450;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
        }
        .outer4 {
          z-index: 4;
          width: 1 * 3.22px;
          height: 586 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-left: 12 * 3.22px;
        }
        .outer5 {
          z-index: auto;
          width: 16 * 3.22px;
          height: 533 * 3.22px;
          margin: 48 * 3.22px 0 0 12 * 3.22px;
          .label2 {
            z-index: 61;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
          }
          .label3 {
            z-index: 67;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 32 * 3.22px;
          }
          .icon1 {
            z-index: 73;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 32 * 3.22px;
          }
          .label4 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 32 * 3.22px;
          }
          .icon2 {
            z-index: 127;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon3 {
            z-index: 148;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label5 {
            z-index: 169;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
          .icon4 {
            z-index: 202;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 32 * 3.22px;
          }
          .label6 {
            z-index: 235;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 32 * 3.22px;
          }
          .label7 {
            z-index: 268;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 32 * 3.22px;
          }
          .icon5 {
            z-index: 301;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 32 * 3.22px;
          }
          .icon6 {
            z-index: 334;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 30 * 3.22px;
          }
          .icon7 {
            z-index: 355;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 9 * 3.22px;
          }
          .icon8 {
            z-index: 376;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
        }
        .outer6 {
          z-index: auto;
          width: 12 * 3.22px;
          height: 529 * 3.22px;
          margin: 50 * 3.22px 0 0 2 * 3.22px;
          .word14 {
            z-index: 60;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .word15 {
            z-index: 66;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .word16 {
            z-index: 72;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .word17 {
            z-index: 105;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .info15 {
            z-index: 126;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word18 {
            z-index: 147;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .info16 {
            z-index: 168;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word19 {
            z-index: 201;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .word20 {
            z-index: 234;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .word21 {
            z-index: 267;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .txt13 {
            z-index: 300;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .txt14 {
            z-index: 333;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .txt15 {
            z-index: 354;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 13 * 3.22px;
          }
          .word22 {
            z-index: 375;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
        }
        .outer7 {
          z-index: auto;
          width: 16 * 3.22px;
          height: 533 * 3.22px;
          margin: 48 * 3.22px 0 0 32 * 3.22px;
          .label8 {
            z-index: 429;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
          }
          .label9 {
            z-index: 444;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon9 {
            z-index: 403;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label10 {
            z-index: 424;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon10 {
            z-index: 91;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label11 {
            z-index: 79;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label12 {
            z-index: 112;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label13 {
            z-index: 133;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon11 {
            z-index: 154;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 10 * 3.22px;
          }
          .icon12 {
            z-index: 190;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 9 * 3.22px;
          }
          .label14 {
            z-index: 175;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon13 {
            z-index: 223;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label15 {
            z-index: 208;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label16 {
            z-index: 256;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label17 {
            z-index: 241;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon14 {
            z-index: 286;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon15 {
            z-index: 274;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label18 {
            z-index: 319;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label19 {
            z-index: 307;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon16 {
            z-index: 340;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon17 {
            z-index: 364;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 9 * 3.22px;
          }
          .label20 {
            z-index: 385;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
        }
        .outer8 {
          z-index: auto;
          width: 114 * 3.22px;
          height: 568 * 3.22px;
          margin: 11 * 3.22px 0 0 2 * 3.22px;
          .word23 {
            z-index: 10;
            width: 56 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: left;
            align-self: flex-end;
          }
          .word24 {
            z-index: 433;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 19 * 3.22px;
          }
          .word25 {
            z-index: 448;
            width: 84 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .txt16 {
            z-index: 402;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word26 {
            z-index: 423;
            width: 60 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .word27 {
            z-index: 90;
            width: 84 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .info17 {
            z-index: 78;
            width: 60 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .txt17 {
            z-index: 111;
            width: 36 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word28 {
            z-index: 132;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word29 {
            z-index: 153;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word30 {
            z-index: 189;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .info18 {
            z-index: 174;
            width: 84 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .word31 {
            z-index: 222;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word32 {
            z-index: 207;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .txt18 {
            z-index: 255;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word33 {
            z-index: 240;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .word34 {
            z-index: 285;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word35 {
            z-index: 273;
            width: 84 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .info19 {
            z-index: 318;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .txt19 {
            z-index: 306;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .info20 {
            z-index: 339;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word36 {
            z-index: 363;
            width: 60 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 13 * 3.22px;
          }
          .txt20 {
            z-index: 384;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
        }
        .outer9 {
          z-index: auto;
          width: 16 * 3.22px;
          height: 533 * 3.22px;
          margin: 48 * 3.22px 0 0 2 * 3.22px;
          .label21 {
            z-index: 435;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
          }
          .label22 {
            z-index: 417;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label23 {
            z-index: 409;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label24 {
            z-index: 397;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon18 {
            z-index: 97;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label25 {
            z-index: 85;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label26 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 9 * 3.22px;
          }
          .label27 {
            z-index: 139;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label28 {
            z-index: 163;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon19 {
            z-index: 196;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label29 {
            z-index: 184;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label30 {
            z-index: 229;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label31 {
            z-index: 214;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .icon20 {
            z-index: 262;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label32 {
            z-index: 250;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label33 {
            z-index: 292;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon21 {
            z-index: 280;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label34 {
            z-index: 325;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon22 {
            z-index: 313;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label35 {
            z-index: 346;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label36 {
            z-index: 370;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 9 * 3.22px;
          }
          .label37 {
            z-index: 391;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
        }
        .outer10 {
          z-index: auto;
          width: 96 * 3.22px;
          height: 529 * 3.22px;
          margin: 50 * 3.22px 0 0 2 * 3.22px;
          .info21 {
            z-index: 442;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
          }
          .word37 {
            z-index: 421;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .txt21 {
            z-index: 408;
            width: 96 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .info22 {
            z-index: 396;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .word38 {
            z-index: 96;
            width: 96 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word39 {
            z-index: 84;
            width: 90 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .txt22 {
            z-index: 120;
            width: 36 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word40 {
            z-index: 138;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word41 {
            z-index: 162;
            width: 60 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .info23 {
            z-index: 195;
            width: 96 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word42 {
            z-index: 183;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .word43 {
            z-index: 228;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word44 {
            z-index: 213;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .word45 {
            z-index: 261;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word46 {
            z-index: 249;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .word47 {
            z-index: 291;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .info24 {
            z-index: 279;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .txt23 {
            z-index: 324;
            width: 24 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word48 {
            z-index: 312;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .txt24 {
            z-index: 345;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word49 {
            z-index: 369;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 13 * 3.22px;
          }
          .info25 {
            z-index: 390;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
        }
      }
      .main7 {
        z-index: 5;
        position: absolute;
        left: 0;
        top: 42 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main8 {
        z-index: 6;
        position: absolute;
        left: 0;
        top: 90 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main9 {
        z-index: 7;
        position: absolute;
        left: 0;
        top: 138 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main10 {
        z-index: 15;
        position: absolute;
        left: 0;
        top: 186 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main11 {
        z-index: 16;
        position: absolute;
        left: 0;
        top: 213 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main12 {
        z-index: 43;
        position: absolute;
        left: 0;
        top: 240 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main13 {
        z-index: 21;
        position: absolute;
        left: 0;
        top: 267 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main14 {
        z-index: 24;
        position: absolute;
        left: 0;
        top: 315 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main15 {
        z-index: 27;
        position: absolute;
        left: 0;
        top: 363 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main16 {
        z-index: 30;
        position: absolute;
        left: 0;
        top: 411 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main17 {
        z-index: 33;
        position: absolute;
        left: 0;
        top: 459 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main18 {
        z-index: 36;
        position: absolute;
        left: 0;
        top: 507 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main19 {
        z-index: 37;
        position: absolute;
        left: 0;
        top: 532 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .main20 {
        z-index: 38;
        position: absolute;
        left: 0;
        top: 559 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
    .txt25 {
      z-index: 460;
      width: 220 * 3.22px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 20 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 20 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 104 * 3.22px;
    }
    .txt26 {
      z-index: 461;
      width: 166 * 3.22px;
      display: flex;
      justify-content: center;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 8 * 3.22px;
    }
    .main21 {
      z-index: 454;
      height: 101 * 3.22px;
      border-width: 1 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 28 * 3.22px;
      width: 507 * 3.22px;
      align-items: flex-start;
      padding-left: 20 * 3.22px;
      position: relative;
      .layer1 {
        z-index: auto;
        width: 452 * 3.22px;
        height: 101 * 3.22px;
        .main22 {
          z-index: auto;
          width: 12 * 3.22px;
          height: 76 * 3.22px;
          margin-top: 18 * 3.22px;
          .word50 {
            z-index: 529;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
          }
          .word51 {
            z-index: 530;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 25 * 3.22px;
          }
          .word52 {
            z-index: 531;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            margin-top: 15 * 3.22px;
          }
        }
        .main23 {
          z-index: 455;
          width: 1 * 3.22px;
          height: 101 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-left: 20 * 3.22px;
        }
        .main24 {
          z-index: auto;
          width: 72 * 3.22px;
          height: 76 * 3.22px;
          margin: 18 * 3.22px 0 0 12 * 3.22px;
          .word53 {
            z-index: 462;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .txt27 {
            z-index: 527;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 25 * 3.22px;
          }
          .txt28 {
            z-index: 528;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
        }
        .main25 {
          z-index: 456;
          width: 1 * 3.22px;
          height: 101 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-left: 12 * 3.22px;
        }
        .main26 {
          z-index: auto;
          width: 16 * 3.22px;
          height: 91 * 3.22px;
          margin: 5 * 3.22px 0 0 12 * 3.22px;
          .label38 {
            z-index: 469;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
          }
          .icon23 {
            z-index: 496;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 32 * 3.22px;
          }
          .icon24 {
            z-index: 515;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
        }
        .main27 {
          z-index: auto;
          width: 12 * 3.22px;
          height: 87 * 3.22px;
          margin: 7 * 3.22px 0 0 2 * 3.22px;
          .word54 {
            z-index: 464;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .word55 {
            z-index: 493;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 36 * 3.22px;
          }
          .info26 {
            z-index: 512;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
        }
        .main28 {
          z-index: auto;
          width: 16 * 3.22px;
          height: 91 * 3.22px;
          margin: 5 * 3.22px 0 0 32 * 3.22px;
          .icon25 {
            z-index: 484;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
          }
          .label39 {
            z-index: 476;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label40 {
            z-index: 500;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .label41 {
            z-index: 519;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
        }
        .main29 {
          z-index: auto;
          width: 72 * 3.22px;
          height: 87 * 3.22px;
          margin: 7 * 3.22px 0 0 2 * 3.22px;
          .word56 {
            z-index: 467;
            width: 72 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .info27 {
            z-index: 465;
            width: 60 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .txt29 {
            z-index: 494;
            width: 36 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word57 {
            z-index: 513;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
        }
        .main30 {
          z-index: auto;
          width: 16 * 3.22px;
          height: 91 * 3.22px;
          margin: 5 * 3.22px 0 0 44 * 3.22px;
          .label42 {
            z-index: 488;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
          }
          .icon26 {
            z-index: 480;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 5 * 3.22px;
          }
          .label43 {
            z-index: 504;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
          .icon27 {
            z-index: 523;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            margin-top: 11 * 3.22px;
          }
        }
        .main31 {
          z-index: auto;
          width: 96 * 3.22px;
          height: 87 * 3.22px;
          margin: 7 * 3.22px 0 0 2 * 3.22px;
          .info28 {
            z-index: 468;
            width: 96 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
          }
          .word58 {
            z-index: 466;
            width: 60 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 9 * 3.22px;
          }
          .word59 {
            z-index: 495;
            width: 36 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
          .word60 {
            z-index: 514;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15 * 3.22px;
          }
        }
      }
      .layer2 {
        z-index: 457;
        position: absolute;
        left: 0;
        top: 47 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
      .layer3 {
        z-index: 458;
        position: absolute;
        left: 0;
        top: 74 * 3.22px;
        width: 507 * 3.22px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
  }
}
</style>
