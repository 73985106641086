<template>
  <div>
    <div
      class="image"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="pdfs"
    >
      <img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />
    </div>
    <div class="page flex-col">
      <div class="outer1 flex-col">
        <div class="mod1 flex-row">
          <span class="info1">{{ constants.hospitalName }}</span>
        </div>
        <span class="word1">{{ constants.title }}</span>
        <div class="mod2 flex-row">
          <span class="word2">姓名：{{ constants.name }}</span>
          <span class="info2">性别：{{ constants.sex }}</span>
          <span class="word3">年龄：{{ constants.age }}岁</span>
          <span class="word4">教育年限：{{ constants.eduTotal }}年</span>
          <span class="info3">职业：{{ constants.job }}</span>
        </div>
        <div class="mod3 flex-row">
          <span class="txt1">编号：{{ constants.reportNo }}</span>
          <span class="txt2">测评时间：{{ constants.reportDate }}</span>
        </div>
        <div class="mod4 flex-col"></div>
        <div class="mod5 flex-col">
          <div class="section1 flex-row">
            <div class="box1 flex-col">
              <span class="word5">序号</span>
              <span class="word6">1</span>
              <span class="info4">2</span>
              <span class="txt3">3</span>
              <span class="word7">4</span>
              <span class="info5">5</span>
              <span class="word8">6</span>
              <span class="txt4">7</span>
              <span class="info6">8</span>
              <span class="word9">9</span>
              <span class="info7">10</span>
              <span class="word10">11</span>
            </div>
            <div class="box2 flex-col"></div>
            <div class="box3 flex-col">
              <span class="info8">题目</span>
              <span class="txt5">
                焦虑心境：担心、担忧，感到有最坏的事情将要发生，容易被激惹。
              </span>
              <span class="word11">
                紧张：紧张感、易疲劳、不能放松，情绪反应，易哭、颤抖、感到不安。
              </span>
              <span class="info9">
                害怕：害怕黑暗、陌生人、一人独处、动物、乘车或旅行及人多的场合。
              </span>
              <span class="txt6">
                失眠：难以入睡、易醒、睡得不深、多梦、梦魇、夜惊、睡醒后感到疲倦。
              </span>
              <span class="txt7">认知功能障碍：注意力不能集中，记忆力差。</span>
              <span class="word12">
                抑郁心境：丧失兴趣、对以往爱好的事务缺乏快感、忧郁、早醒、昼重夜轻。
              </span>
              <span class="txt8">
                躯体性焦虑（肌肉系统症状）：肌肉酸痛、活动不灵活、肌肉经常抽动、肢体抽动、牙齿打颤、声音发抖。
              </span>
              <span class="word13">
                感觉系统症状：视物模糊、发冷发热、软弱无力感、浑身刺痛。
              </span>
              <span class="info10">
                心血管系统症状：心动过速、心悸、胸痛、血管跳动感、昏倒感、心博脱漏。
              </span>
              <span class="info11">
                呼吸系统症状：时常感到胸闷、窒息感、叹息、呼吸困难。
              </span>
              <span class="word14">
                胃肠消化道症状：吞咽困难、嗳气、食欲不佳、消化不良（进食后腹痛、胃部烧灼痛、腹胀、恶心、胃部饱胀感）、肠鸣、腹泻、体重减轻、便秘。
              </span>
            </div>
            <div class="box4 flex-col"></div>
            <div class="box5 flex-col">
              <span class="txt9">无症状</span>
              <div class="label2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo1 === 0"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo2 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo3 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo4 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo5 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo6 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img7">
                <img
                  v-if="itmes.hamaNo7 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img8">
                <img
                  v-if="itmes.hamaNo8 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo9 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo10 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img11">
                <img
                  v-if="itmes.hamaNo11 === 0"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
            </div>
            <div class="box6 flex-col"></div>
            <div class="box7 flex-col">
              <span class="paragraph1">
                症状
                <br />
                轻
              </span>
              <div class="label2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo1 === 1"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo2 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo3 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo4 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo5 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo6 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img7">
                <img
                  v-if="itmes.hamaNo7 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img8">
                <img
                  v-if="itmes.hamaNo8 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo9 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo10 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img11">
                <img
                  v-if="itmes.hamaNo11 === 1"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
            </div>
            <div class="box8 flex-col"></div>
            <div class="box9 flex-col">
              <span class="info12">中等</span>
              <div class="label2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo1 === 2"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo2 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo3 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo4 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo5 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo6 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img7">
                <img
                  v-if="itmes.hamaNo7 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img8">
                <img
                  v-if="itmes.hamaNo8 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo9 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo10 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img11">
                <img
                  v-if="itmes.hamaNo11 === 2"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
            </div>
            <div class="box10 flex-col"></div>
            <div class="box11 flex-col">
              <span class="infoBox1">
                症状
                <br />
                重
              </span>
              <div class="label2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo1 === 3"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo2 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo3 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo4 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo5 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo6 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img7">
                <img
                  v-if="itmes.hamaNo7 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img8">
                <img
                  v-if="itmes.hamaNo8 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo9 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo10 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img11">
                <img
                  v-if="itmes.hamaNo11 === 3"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
            </div>
            <div class="box12 flex-col"></div>
            <div class="box13 flex-col">
              <span class="infoBox2">
                症状
                <br />
                极重
              </span>
              <div class="label2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo1 === 4"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo2 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo3 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo4 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo5 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo6 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img7">
                <img
                  v-if="itmes.hamaNo7 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img8">
                <img
                  v-if="itmes.hamaNo8 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo9 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1">
                <img
                  v-if="itmes.hamaNo10 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
              <div class="icon1 img11">
                <img
                  v-if="itmes.hamaNo11 === 4"
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                />
              </div>
            </div>
          </div>
          <div class="section2 flex-col"></div>
          <div class="section3 flex-col"></div>
          <div class="section4 flex-col"></div>
          <div class="section5 flex-col"></div>
          <div class="section6 flex-col"></div>
          <div class="section7 flex-col"></div>
          <div class="section8 flex-col"></div>
          <div class="section9 flex-col"></div>
          <div class="section10 flex-col"></div>
          <div class="section11 flex-col"></div>
          <div class="section12 flex-col"></div>
        </div>
        <span class="word15">{{ constants.hospitalName }}</span>
        <span class="word16">{{ constants.title }}</span>
        <div class="mod6 flex-col">
          <div class="box14 flex-row">
            <div class="main1 flex-col">
              <span class="word17">12</span>
              <span class="info13">13</span>
              <span class="txt10">14</span>
            </div>
            <div class="main2 flex-col"></div>
            <div class="main3 flex-col">
              <span class="txt11">
                生殖、泌尿系统症状：尿意频繁、尿急、停经、性冷淡、过早射精、勃起不能、阳痿。
              </span>
              <span class="info14">
                植物神经系统症状：口干、潮红、苍白、易出汗、易起“鸡皮疙瘩”、紧张性头痛、毛发竖起。
              </span>
              <span class="word18">
                与人谈话时的行为表现：紧张、不能松弛、忐忑不安、咬手指、紧握拳、摸弄手帕、面肌抽动、不停顿足、手发抖、皱眉、表情僵硬、肌张力高、叹息样呼吸、面色苍白；吞咽、频繁打呃、安静时心率快、呼吸加快（20次/分钟以上）、腱反射亢进、震颤、瞳孔放大、眼睑跳动、易出汗、眼球突出。
              </span>
            </div>
            <div class="main4 flex-col"></div>
            <div class="mainbox1 flex-col">
              <div class="label5">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo12 === 0"
                />
              </div>
              <div class="label6">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo13 === 0"
                />
              </div>
              <div class="label7">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo14Commonly === 0"
                />
              </div>
            </div>
            <div class="main5 flex-col"></div>
            <div class="mainbox2 flex-col">
              <div class="label5-1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo12 === 1"
                />
              </div>
              <div class="label6-1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo13 === 1"
                />
              </div>
              <div class="label7-1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo14Commonly === 1"
                />
              </div>
            </div>
            <div class="main6 flex-col"></div>
            <div class="mainbox3 flex-col">
              <div class="label5-2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo12 === 2"
                />
              </div>
              <div class="label6-2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo13 === 2"
                />
              </div>
              <div class="label7-2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo14Commonly === 2"
                />
              </div>
            </div>
            <div class="main7 flex-col"></div>
            <div class="mainbox4 flex-col">
              <div class="label5-3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo12 === 3"
                />
              </div>
              <div class="label6-3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo13 === 3"
                />
              </div>
              <div class="label7-3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo14Commonly === 3"
                />
              </div>
            </div>
            <div class="main8 flex-col"></div>
            <div class="mainbox5 flex-col">
              <div class="label5-4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo12 === 4"
                />
              </div>
              <div class="label6-4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo13 === 4"
                />
              </div>
              <div class="label7-4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="itmes.hamaNo14Commonly === 4"
                />
              </div>
            </div>
          </div>
          <div class="box15 flex-col"></div>
          <div class="box16 flex-col"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../../assets/font/songti/font.css";
import htmlToPdf from "@/assets/js/htmlToPdf";
export default {
  data() {
    return {
      constants: {},
      itmes: "",
      dui: require("../../../assets/img/dui.png"),
      cuo: require("../../../assets/img/cuo.png"),
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",
    };
  },
  methods: {
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t, document.body.style.zoom);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector(".page"),
          `${this.constants.name}_${this.constants.title}(回显)`
        );
      }
    },
  },
  mounted() {
    // ${this.$route.query.masterId}
    let afterUrl = window.location.search.substring(1);
    let path = afterUrl.substring(afterUrl.indexOf("=") + 1);
    this.$http
      .get(`report/getViewResult?masterId=${path}`)
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          this.constants = res.data;
          this.itmes = res.data.object;
          if (
            this.constants.job === "" ||
            this.constants.job === undefined ||
            this.constants.job === null
          ) {
            this.constants.job = "--";
          }
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped lang='scss'>
/*stration*/
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: "songti";
}
button {
  margin: 0;
  padding: 0;
  border: 1 * 3.22px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.image {
  position: absolute;
  top: 200px;
  right: 200px;
  z-index: 352;
  img {
    width: 22 * 3.22px;
    height: 22 * 3.22px;
  }
}
.page {
  z-index: 1;
  position: relative;
  width: 595 * 3.22px;
  height: 1470 * 3.22px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  justify-content: flex-start;
  padding-top: 48 * 3.22px;
  align-items: center;
}

.outer1 {
  z-index: auto;
  width: 507 * 3.22px;
  height: 1095 * 3.22px;
}

.mod1 {
  z-index: auto;
  width: 363 * 3.22px;
  height: 22 * 3.22px;
  margin-left: 144 * 3.22px;
  justify-content: space-between;
}

.info1 {
  z-index: 3;
  width: 220 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 20 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 20 * 3.22px;
  text-align: center;
}

.label1 {
  z-index: 5;
  width: 22 * 3.22px;
  height: 22 * 3.22px;
}

.word1 {
  z-index: 4;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 16 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 6 * 3.22px;
}

.mod2 {
  z-index: auto;
  width: 507 * 3.22px;
  height: 12 * 3.22px;
  margin-top: 28 * 3.22px;
}

.word2 {
  z-index: 10;
  width: 72 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: left;
}

.info2 {
  z-index: 11;
  width: 48 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: left;
  margin-left: 28 * 3.22px;
}

.word3 {
  z-index: 12;
  width: 60 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: left;
  margin-left: 28 * 3.22px;
}

.word4 {
  z-index: 13;
  width: 84 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: right;
  margin-left: 28 * 3.22px;
}

.info3 {
  z-index: 14;
  width: 454px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: right;
  margin-left: 15 * 3.22px;
}

.mod3 {
  z-index: auto;
  width: 507 * 3.22px;
  height: 12 * 3.22px;
  margin-top: 8 * 3.22px;
  justify-content: space-between;
}

.txt1 {
  z-index: 9;
  width: 96 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: left;
}

.txt2 {
  z-index: 8;
  width: 156 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: right;
}

.mod4 {
  z-index: 7;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
  margin-top: 8 * 3.22px;
}

.mod5 {
  z-index: 15;
  height: 554 * 3.22px;
  border-width: 1 * 3.22px;
  border: 1 * 3.22px solid rgba(153, 153, 153, 1);
  background-color: rgba(255, 255, 255, 1);
  margin-top: 12 * 3.22px;
  width: 507 * 3.22px;
  align-items: flex-end;
  padding-right: 8 * 3.22px;
  position: relative;
}

.section1 {
  z-index: auto;
  width: 487 * 3.22px;
  height: 554 * 3.22px;
}

.box1 {
  z-index: auto;
  width: 28 * 3.22px;
  height: 514 * 3.22px;
  margin-top: 11 * 3.22px;
}

.word5 {
  z-index: 25;
  width: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 20 * 3.22px;
  text-align: center;
}

.word6 {
  z-index: 34;
  width: 6 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 27 * 3.22px;
}

.info4 {
  z-index: 35;
  width: 6 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 31 * 3.22px;
}

.txt3 {
  z-index: 50;
  width: 6 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 31 * 3.22px;
}

.word7 {
  z-index: 51;
  width: 6 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 31 * 3.22px;
}

.info5 {
  z-index: 121;
  width: 6 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 28 * 3.22px;
}

.word8 {
  z-index: 60;
  width: 6 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 31 * 3.22px;
}

.txt4 {
  z-index: 69;
  width: 6 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 38 * 3.22px;
}

.info6 {
  z-index: 86;
  width: 6 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 38 * 3.22px;
}

.word9 {
  z-index: 95;
  width: 6 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 31 * 3.22px;
}

.info7 {
  z-index: 104;
  width: 12 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 31 * 3.22px;
}

.word10 {
  z-index: 112;
  width: 12 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 12 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 45 * 3.22px;
}

.box2 {
  z-index: 16;
  width: 1 * 3.22px;
  height: 554 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
  margin-left: 12 * 3.22px;
}

.box3 {
  z-index: auto;
  width: 213 * 3.22px;
  height: 536 * 3.22px;
  margin: 11 * 3.22px 0 0 12 * 3.22px;
}

.info8 {
  z-index: 26;
  width: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 20 * 3.22px;
  text-align: left;
  align-self: center;
}

.txt5 {
  z-index: 32;
  width: 213 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 19 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word11 {
  z-index: 33;
  width: 213 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 15 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info9 {
  z-index: 49;
  width: 213 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 15 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt6 {
  z-index: 120;
  width: 213 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 15 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt7 {
  z-index: 59;
  width: 213 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 12 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word12 {
  z-index: 68;
  width: 213 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 15 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt8 {
  z-index: 76;
  width: 213 * 3.22px;
  height: 42 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 15 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word13 {
  z-index: 85;
  width: 213 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 15 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info10 {
  z-index: 94;
  width: 213 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 15 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info11 {
  z-index: 103;
  width: 213 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 15 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word14 {
  z-index: 111;
  width: 213 * 3.22px;
  height: 56 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: left;
  margin-top: 15 * 3.22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box4 {
  z-index: 17;
  width: 1 * 3.22px;
  height: 554 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
  margin-left: 12 * 3.22px;
}

.box5 {
  z-index: auto;
  width: 36 * 3.22px;
  height: 101 * 3.22px;
  margin: 14 * 3.22px 0 0 8 * 3.22px;
}

.txt9 {
  z-index: 27;
  width: 36 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 14 * 3.22px;
  text-align: center;
}

.label2 {
  z-index: 36;
  align-self: center;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  margin-top: 28 * 3.22px;
  img {
    width: 16 * 3.22px;
    height: 16 * 3.22px;
  }
}

.icon1 {
  z-index: 42;
  align-self: center;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  margin-top: 27 * 3.22px;
  img {
    width: 16 * 3.22px;
    height: 16 * 3.22px;
  }
}
.img7 {
  margin-top: 29 * 3.22px !important;
}
.img8 {
  margin-top: 35 * 3.22px !important;
}
.img11 {
  margin-top: 44 * 3.22px !important;
}
.box6 {
  z-index: 18;
  width: 1 * 3.22px;
  height: 554 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
  margin-left: 8 * 3.22px;
}

.box7 {
  z-index: auto;
  width: 24 * 3.22px;
  height: 234 * 3.22px;
  margin: 7 * 3.22px 0 0 8 * 3.22px;
}

.paragraph1 {
  z-index: 28;
  width: 24 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon2 {
  z-index: 52;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  align-self: center;
  margin-top: 107 * 3.22px;
}

.icon3 {
  z-index: 122;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  align-self: center;
  margin-top: 27 * 3.22px;
}

.icon4 {
  z-index: 61;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  align-self: center;
  margin-top: 24 * 3.22px;
}

.box8 {
  z-index: 19;
  width: 1 * 3.22px;
  height: 554 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
  margin-left: 8 * 3.22px;
}

.box9 {
  z-index: auto;
  width: 24 * 3.22px;
  height: 271 * 3.22px;
  margin: 13 * 3.22px 0 0 8 * 3.22px;
}

.info12 {
  z-index: 29;
  width: 24 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 17 * 3.22px;
  text-align: center;
}

.icon5 {
  z-index: 70;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  align-self: center;
  margin-top: 238 * 3.22px;
}

.box10 {
  z-index: 20;
  width: 1 * 3.22px;
  height: 554 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
  margin-left: 8 * 3.22px;
}

.box11 {
  z-index: auto;
  width: 24 * 3.22px;
  height: 466 * 3.22px;
  margin: 4 * 3.22px 0 0 8 * 3.22px;
}

.infoBox1 {
  z-index: 30;
  width: 24 * 3.22px;
  height: 34 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 17 * 3.22px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label3 {
  z-index: 77;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  align-self: center;
  margin-top: 280 * 3.22px;
}

.icon6 {
  z-index: 87;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  align-self: center;
  margin-top: 34 * 3.22px;
}

.icon7 {
  z-index: 96;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  align-self: center;
  margin-top: 27 * 3.22px;
}

.icon8 {
  z-index: 105;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  align-self: center;
  margin-top: 27 * 3.22px;
}

.box12 {
  z-index: 21;
  width: 1 * 3.22px;
  height: 554 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
  margin-left: 8 * 3.22px;
}

.box13 {
  z-index: auto;
  width: 24 * 3.22px;
  height: 520 * 3.22px;
  margin: 7 * 3.22px 0 0 8 * 3.22px;
}

.infoBox2 {
  z-index: 31;
  width: 24 * 3.22px;
  height: 28 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12 * 3.22px;
  font-family: "songti";
  line-height: 14 * 3.22px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label4 {
  z-index: 113;
  width: 16 * 3.22px;
  height: 16 * 3.22px;
  align-self: center;
  margin-top: 476 * 3.22px;
}

.section2 {
  z-index: 22;
  position: absolute;
  left: 0;
  top: 42 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section3 {
  z-index: 23;
  position: absolute;
  left: 0;
  top: 85 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section4 {
  z-index: 24;
  position: absolute;
  left: 0;
  top: 128 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section5 {
  z-index: 48;
  position: absolute;
  left: 0;
  top: 171 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section6 {
  z-index: 119;
  position: absolute;
  left: 0;
  top: 214 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section7 {
  z-index: 58;
  position: absolute;
  left: 0;
  top: 254 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section8 {
  z-index: 67;
  position: absolute;
  left: 0;
  top: 297 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section9 {
  z-index: 83;
  position: absolute;
  left: 0;
  top: 354 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section10 {
  z-index: 84;
  position: absolute;
  left: 0;
  top: 397 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section11 {
  z-index: 93;
  position: absolute;
  left: 0;
  top: 440 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.section12 {
  z-index: 102;
  position: absolute;
  left: 0;
  top: 483 * 3.22px;
  width: 507 * 3.22px;
  height: 1 * 3.22px;
  background-color: rgba(153, 153, 153, 1);
}

.word15 {
  z-index: 130;
  width: 220 * 3.22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 20 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 20 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 134 * 3.22px;
}

.word16 {
  z-index: 131;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16 * 3.22px;
  font-family: "songti";
  white-space: nowrap;
  line-height: 16 * 3.22px;
  text-align: center;
  align-self: center;
  margin-top: 8 * 3.22px;
}

.mod6 {
  z-index: 132;
  height: 240 * 3.22px;
  border-width: 1 * 3.22px;
  border: 1 * 3.22px solid rgba(153, 153, 153, 1);
  background-color: rgba(255, 255, 255, 1);
  margin-top: 28 * 3.22px;
  width: 507 * 3.22px;
  align-items: flex-end;
  padding-right: 12 * 3.22px;
  position: relative;
  .box14 {
    z-index: auto;
    width: 475 * 3.22px;
    height: 240 * 3.22px;
    .main1 {
      z-index: auto;
      width: 12 * 3.22px;
      height: 161 * 3.22px;
      margin-top: 22 * 3.22px;
      .word17 {
        z-index: 144;
        width: 12 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: center;
      }
      .info13 {
        z-index: 145;
        width: 12 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: center;
        margin-top: 45 * 3.22px;
      }
      .txt10 {
        z-index: 146;
        width: 12 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: center;
        margin-top: 80 * 3.22px;
      }
    }
    .main2 {
      z-index: 133;
      width: 1 * 3.22px;
      height: 240 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-left: 20 * 3.22px;
    }
    .main3 {
      z-index: auto;
      width: 213 * 3.22px;
      height: 226 * 3.22px;
      margin: 7 * 3.22px 0 0 12 * 3.22px;
      .txt11 {
        z-index: 141;
        width: 213 * 3.22px;
        height: 42 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        line-height: 14 * 3.22px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .info14 {
        z-index: 142;
        width: 213 * 3.22px;
        height: 42 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        line-height: 14 * 3.22px;
        text-align: left;
        margin-top: 15 * 3.22px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .word18 {
        z-index: 143;
        width: 213 * 3.22px;
        height: 112 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        line-height: 14 * 3.22px;
        text-align: left;
        margin-top: 15 * 3.22px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .main4 {
      z-index: 134;
      width: 1 * 3.22px;
      height: 240 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-left: 12 * 3.22px;
    }
    .mainbox1 {
      z-index: auto;
      width: 36 * 3.22px;
      height: 240 * 3.22px;
      margin: 14 * 3.22px 0 0 8 * 3.22px;
      display: flex;
      align-items: center;
      div {
        img {
          width: 16 * 3.22px;
          height: 16 * 3.22px;
        }
      }
      .label5 {
        z-index: 147;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
      }
      .label6 {
        z-index: 153;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 46 * 3.22px;
      }
      .label7 {
        z-index: 159;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 76 * 3.22px;
      }
    }
    .main5 {
      z-index: 135;
      width: 1 * 3.22px;
      height: 240 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-left: 8 * 3.22px;
    }
    .mainbox2 {
      z-index: auto;
      width: 24 * 3.22px;
      height: 240 * 3.22px;
      margin: 7 * 3.22px 0 0 8 * 3.22px;
      display: flex;
      align-items: center;
      div {
        img {
          width: 16 * 3.22px;
          height: 16 * 3.22px;
          margin-top: 8 * 3.22px;
        }
      }
      .label5-1 {
        z-index: 147;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
      }
      .label6-1 {
        z-index: 153;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 46 * 3.22px;
      }
      .label7-1 {
        z-index: 159;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 76 * 3.22px;
      }
    }
    .main6 {
      z-index: 136;
      width: 1 * 3.22px;
      height: 240 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-left: 8 * 3.22px;
    }
    .mainbox3 {
      z-index: auto;
      width: 24 * 3.22px;
      height: 240 * 3.22px;
      margin: 7 * 3.22px 0 0 8 * 3.22px;
      display: flex;
      align-items: center;
      div {
        img {
          width: 16 * 3.22px;
          height: 16 * 3.22px;
          margin-top: 8 * 3.22px;
        }
      }
      .label5-2 {
        z-index: 147;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
      }
      .label6-2 {
        z-index: 153;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 46 * 3.22px;
      }
      .label7-2 {
        z-index: 159;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 76 * 3.22px;
      }
    }
    .main7 {
      z-index: 137;
      width: 1 * 3.22px;
      height: 240 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-left: 8 * 3.22px;
    }
    .mainbox4 {
      z-index: auto;
      width: 24 * 3.22px;
      height: 240 * 3.22px;
      margin: 7 * 3.22px 0 0 8 * 3.22px;
      display: flex;
      align-items: center;
      div {
        img {
          width: 16 * 3.22px;
          height: 16 * 3.22px;
          margin-top: 8 * 3.22px;
        }
      }
      .label5-3 {
        z-index: 147;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
      }
      .label6-3 {
        z-index: 153;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 46 * 3.22px;
      }
      .label7-3 {
        z-index: 159;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 76 * 3.22px;
      }
    }
    .main8 {
      z-index: 138;
      width: 1 * 3.22px;
      height: 240 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-left: 8 * 3.22px;
    }
    .mainbox5 {
      z-index: auto;
      width: 16 * 3.22px;
      height: 165 * 3.22px;
      margin: 7 * 3.22px 0 0 12 * 3.22px;
      display: flex;
      align-items: center;
      div {
        img {
          width: 16 * 3.22px;
          height: 16 * 3.22px;
          margin-top: 8 * 3.22px;
        }
      }
      .label5-4 {
        z-index: 147;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
      }
      .label6-4 {
        z-index: 153;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 46 * 3.22px;
      }
      .label7-4 {
        z-index: 159;
        width: 16 * 3.22px;
        height: 16 * 3.22px;
        margin-top: 76 * 3.22px;
      }
    }
  }
  .box15 {
    z-index: 139;
    position: absolute;
    left: 0;
    top: 56 * 3.22px;
    width: 507 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .box16 {
    z-index: 140;
    position: absolute;
    left: 0;
    top: 113 * 3.22px;
    width: 507 * 3.22px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
}
</style>
