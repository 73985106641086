<template>
  <div class="pubhealthreports">
    <!-- <img
      @click="goBack"
      v-if="/android/.test(this.ua) && $route.query.token === undefined"
      src="../../../assets/img/close.png"
      alt=""
      class="colse"
    /> -->
    <img
      @click="goBack"
      src="../../../assets/img/close.png"
      alt=""
      class="colse"
    />
    <!-- v-print="'#newTestReport'" -->
    <!-- <div
      class="title_right"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="pdfs"
      v-if="/android/.test(this.ua) ? false : true"
    >
      <img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />
    </div> -->
    <div class="pubhealthreport" id="pubhealthreport" ref="pubhealthreport">
      <div class="title">
        <div class="title_left">
          <dl>
            <dt><img :src="resultDetail.qrCodeImgString" alt="" /></dt>
            <dd>
              <p>扫描二维码</p>
              <!--<p>关注“上谷健康”公众号</p>-->
              <p>查看报告</p>
            </dd>
          </dl>
        </div>
        <div class="title_content">
          <!--<p>{{ resultDetail.hospitalName }}</p>-->
          <p>{{ resultDetail.reportTitle }}</p>
        </div>
        <div></div>
      </div>
      <div class="model_top">
        <div class="model_top_div">
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 姓名：{{ resultDetail.name }}</span>
            </div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 性别：{{ resultDetail.gender }}</span>
            </div>
            <div class="model_top_item_value"></div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 年龄：{{ resultDetail.age }}岁 </span>
            </div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 教育年限：{{ resultDetail.eduTotal }}年 </span>
            </div>
          </div>
        </div>
        <div class="model_top_div">
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 编号：{{ resultDetail.reportNo }} </span>
            </div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 主试人：{{ resultDetail.doctorName }} </span>
            </div>
          </div>
          <div class="model_top_item">
            <div class="model_top_item_label">
              <span> 测评时间：{{ times }} </span>
            </div>
          </div>
        </div>
      </div>
        <!--总体评价-->
        <div class="all">
            <h1 class="subTitles">总体评价</h1>
            <div>
                <!--assessResult  1 认知正常   2 认知下降   3 认知损伤-->
                <!--结果解释
                    1  认知正常
                    1.1 resultDetail.detailContents[0].result === 正常   结果解释：您本次测评结果显示为认知正常，说明您尚不存在患有认知障碍的风险。
                    1.2 resultDetail.detailContents[0].result === 异常 结果解释：您本次测评结果显示认知功能基本正常，但存在主观认知下降。
                    2 结果解释：您本次测评结果显示为认知下降，说明您存在患有认知障碍的风险。
                    3 结果解释：您本次测评结果显示为认知损伤，说明您存在患有认知障碍的风险。
                -->
                <h1 :class="resultDetail.assessResult === 1 ? 'text1' : (resultDetail.assessResult === 2 ? 'text2' : (resultDetail.assessResult === 3 ? 'text3' : ''))">{{resultDetail.assessResult === 1 ? '认知正常' : (resultDetail.assessResult === 2 ? '认知下降' : (resultDetail.assessResult === 3 ? '认知损伤' : ''))}}</h1>
                <p>{{resultDetail.assessResult === 2 ? '结果解释：您本次测评结果显示为认知下降，说明您存在患有认知障碍的风险。' : (resultDetail.assessResult === 3 ? '结果解释：您本次测评结果显示为认知损伤，说明您存在患有认知障碍的风险。' : (resultDetail.assessResult === 1 && resultDetail.detailContents[0].result === '正常' ? '结果解释：您本次测评结果显示为认知正常，说明您尚不存在患有认知障碍的风险。' : (resultDetail.assessResult === 1 && resultDetail.detailContents[0].result === '异常' ? '结果解释：您本次测评结果显示认知功能基本正常，但存在主观认知下降。' : '')))}}</p>
            </div>
        </div>
        <div class="explains">
            <h1>脑健康指数</h1>
            <div>
                <dividrule :score="resultDetail.brainHealthIndex"></dividrule>
                <p>脑健康指数越高 表明老年人的脑健康状况越好</p>
            </div>
        </div>
      <table v-for="(item, index) in resultDetail.detailContents" :key="index" class="target-node-item">
        <thead>
          <tr>
            <td colspan="3">{{ item.content }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>得分</td>
            <td>参考值</td>
            <td>结果</td>
          </tr>
          <tr>
            <td>{{ item.score }}</td>
            <td>{{ item.reference }}</td>
            <td>{{ item.result }}</td>
          </tr>
          <tr>
            <td colspan="3" style="text-align: left">
              得分解释：{{ item.scoreExplanation }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="navsay" :class="android ? 'tableMarTop' : ''">
        <h1>脑健康测评说明</h1>
        <div>
          <p
            v-for="(item, index) in resultDetail.resultContents"
            :key="index"
            v-html="item.content"
          ></p>
        </div>
      </div>

      <div class="desc">
        <h1>脑健康指导建议</h1>
        <div>
          <p
            v-for="(item, index) in resultDetail.adviceContents"
            :key="index"
            v-html="item.content"
          ></p>
        </div>
      </div>
      <div class="footerpage">
        <p>测评人：____________</p>
      </div>
      <!-- <div class="footerdesc">
        <p>
          注：本测评结果仅供临床参考，不作为临床诊断。若您已出现脑部疾病症状，请尽快前往专业医疗机构进行诊疗
        </p>
        <p>
          脑健康测评服务机构：北京师范大学认知神经科学与学习国家重点实验室
          北京师范大学老年脑健康研究中心
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import htmlToPdf from "@/assets/js/htmlToPdf";
// import echarts from "./newreports/echarts.vue";
import "../../../assets/font/songti/font.css";
import dividrule from "./DividRule.vue";
export default {
  // components: { echarts },
  components: {
    dividrule,
  },
  data() {
    return {
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",

      masterId: "",
      utype: "D",
      resultDetail: {
        qrCodeImgString: "",
      }, // 接口数据
      ua: "",
      android: "",
    };
  },
  computed: {
    times() {
      // let time = "";
      // if (this.resultDetail.reportDate !== undefined) {
      //   console.log(
      //     this.resultDetail.reportDate + "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
      //   );
      //   time = this.resultDetail.reportDate.substring(
      //     0,
      //     this.resultDetail.reportDate.lastIndexOf(":")
      //   );
      // }
      // return time;
        return this.resultDetail.reportDate
    },
    token() {
      return this.$store.state.token;
    },
  },
  created() {
    if (this.$route.query.token !== undefined) {
      sessionStorage.setItem("access_token", this.$route.query.token);
    }
    this.ua = navigator.userAgent.toLowerCase();
    this.android = /android/.test(navigator.userAgent.toLowerCase());
  },
  mounted() {
    this.masterId = this.$route.query.masterId;
    let data = {
      masterId: this.masterId,
      utype: this.utype,
    };
    this.$http
      .post(
        "report/getResult",
        this.$http.adornData(data, false, "json"),
        this.$http.jsonContent()
      )
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          console.log(res.data);
          this.resultDetail = res.data;
          // let ua = navigator.userAgent.toLowerCase();
          // if (/android/.test(ua)) {
          //   window.JSBridge.goPrint(
          //     window.location.origin +
          //       "/pubhealthreport?token=" +
          //       this.token +
          //       "&masterId=" +
          //       this.$route.query.masterId
          //   );
          // }
          let vm = this;
          const A4_WIDTH = 592.28;
          const A4_HEIGHT = 841.89;
          vm.$nextTick(async () => {
            let target = vm.$refs.pubhealthreport;
            let pageHeight = (target.scrollWidth / A4_WIDTH) * A4_HEIGHT;
            let lableListID =
              document.getElementsByClassName("target-node-item");
            console.log(lableListID);
            for (let i = 0; i < lableListID.length; i++) {
              let multiple = Math.ceil(
                (lableListID[i].offsetTop + lableListID[i].offsetHeight) /
                  pageHeight
              );
              if (this.isSplit(lableListID, i, multiple * pageHeight)) {
                let divParent = lableListID[i].parentNode; // 获取该div的父节点
                let newNode = document.createElement("div");
                newNode.style.background = "#ffffff";
                let _H =
                  multiple * pageHeight -
                  (lableListID[i].offsetTop + lableListID[i].offsetHeight);
                newNode.style.height = _H + 50 + "px";
                let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
                // 判断兄弟节点是否存在
                if (next) {
                  // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
                  divParent.insertBefore(newNode, next);
                } else {
                  // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
                  divParent.appendChild(newNode);
                }
              }
            }
          });
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    // 处理导出pdf截断问题
    isSplit(nodes, index, pageHeight) {
      if (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      ) {
        return true;
      }
      return false;
    },
    goBack() {
      // let ua = navigator.userAgent.toLowerCase();
      // if (/android/.test(ua)) {
      //   window.JSBridge.goPrint("");
      //   this.$router.go(-1);
      // }
      this.$router.go(-1);
    },
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t, document.body.style.zoom);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector("#pubhealthreport"),
          `${this.resultDetail.name}_${this.resultDetail.reportTitle}`
        );
      }
    },
  },
};
</script>


<style scoped lang='scss'>
/*stration*/
.tableMarTop {
  margin-top: 680px !important;
}
.pubhealthreports {
  position: relative;
  .colse {
    position: absolute;
    right: 39px;
    top: 39px;
    width: 71px;
    height: 71px;
  }
  .title_right {
    position: absolute;
    top: 200px;
    right: 200px;
    img {
      width: 88px;
      height: 88px;
    }
  }
}

#pubhealthreport {
  font-family: "songti";
  padding: 155px 142px 129px 142px;
  color: #333333;
  .widt {
    width: 404px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .title {
    font-family: "songti";
    height: 143px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_left {
      height: 129px;
      dl {
        display: flex;
        width: 452px;
        dt {
          img {
            width: 129px;
            height: 129px;
          }
        }
        dd {
            display: flex;
            flex-direction: column;
            justify-content: center;
          p {
            white-space: nowrap;
            margin-top: 2px;
            margin-left: 10px;
            font-size: 26px;
          }
        }
      }
    }

    .title_content {
      font-family: "songti";
      text-align: center;
      margin-left: -400px;
      p:nth-child(1) {
        font-size: 65px;
        font-family: "songti" !important;
      }
      p:nth-child(2) {
        margin-top: 26px;
        font-size: 52px;
        font-family: "songti" !important;
      }
    }
  }

  .model_top {
    font-family: "songti";
    margin-top: 90px;
    padding-bottom: 26px;
    border-bottom: 3px solid #999;
    .model_top_div {
      display: flex;
      justify-content: space-between;
      .model_top_item {
        .model_top_item_label {
          font-size: 39px;
          color: #000;
          p {
            span {
              width: 3px;
            }
          }
        }
      }
    }

    .model_top_div:nth-child(1) {
      margin-bottom: 14px;
      .model_top_div_one {
        display: flex;
        .model_top_item {
          margin-left: 81px;
        }
        .model_top_item:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }
  table {
    font-family: "songti";
    width: 100%;
    margin-top: 50px;
    border-bottom: none;
    // tr {
    //   td {
    //     text-align: center;
    //     display: table-cell;
    //     vertical-align: middle;
    //   }
    // }

    thead {
      tr {
        td {
          border-top: solid 3px #999;
          border-left: 3px solid #999999;
          border-bottom: 3px solid #999999;
          padding: 10px 65px;
          font-size: 45px;
          font-weight: 400;
          color: #000;
        }
        td:last-child {
          border-right: 3px solid #999999;
        }
      }
    }

    tbody {
      tr {
        td {
          border-left: 3px solid #999999;
          border-bottom: 3px solid #999999;
          font-size: 39px;
          color: #000;
          line-height: 60px;
          padding: 12px 65px;
          text-align: center;
        }
        td:last-child {
          border-right: 3px solid #999999;
        }
      }
      tr:last-child {
        td:nth-child(1) {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
  table:nth-child(1) {
    margin-top: 39px;
  }
  .navsay {
    margin-top: 52px;
    border: 3px solid #999;
    font-size: 39px;
    padding-bottom: 52px;
    h1 {
      padding: 19px 48px;
      border-bottom: 3px solid #999;
      font-size: 45px;
      color: #000;
      font-weight: 500;
    }
    div {
      margin-top: 19px;
      padding: 0 39px;
    }
  }
.all{
    margin-top: 52px;
    border: 3px solid #999;
    font-size: 39px;
    padding-bottom: 52px;
.subTitles {
    padding: 19px 48px;
    border-bottom: 3px solid #999;
    font-size: 45px;
    color: #000;
    font-weight: 500;
}
div{
h1{
    margin-top: 52px;
    font-size: 45px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
}
.text1{
    color: #00A57A;
}
.text2{
    color: #E19D2E;
}
.text3{
    color: #E85E17;
}
p{
    margin-top: 26px;
    font-size: 39px;
    font-weight: 400;
    color: #000000;
    line-height: 39px;
    text-align: center;
}
}
}
  .explains {
    margin-top: 52px;
    border: 3px solid #999;
    font-size: 39px;
    padding-bottom: 52px;
    h1 {
      padding: 19px 48px;
      border-bottom: 3px solid #999;
      font-size: 45px;
      color: #000;
      font-weight: 500;
    }
    div {
      padding: 52px 150px;
      p {
        text-align: center;
        margin-top: 39px;
        font-size: 39px;
        color: #777777;
      }
    }
  }
  .desc {
    margin-top: 52px;
    border: 3px solid #999;
    font-size: 39px;
    padding-bottom: 52px;
    h1 {
      padding: 19px 48px;
      border-bottom: 3px solid #999;
      font-size: 45px;
      color: #000;
      font-weight: 500;
    }
    div {
      margin-top: 19px;
      padding: 0 39px;
    }
  }

  //   .modal-desc {
  //     font-family: "songti";
  //     // margin-top: 20*2px;
  //     border: 3px solid #999;
  //     font-size: 39px;
  //     margin-top: -4px;
  //     margin-bottom: 52px;
  //     padding: 19px 34px 52px 39px;
  //     h1 {
  //       width: 100%;
  //       display: block;
  //       font-size: 39px;
  //       color: #333333;
  //       font-weight: 400;
  //     }
  //     div {
  //       margin-top: 13px;
  //       line-height: 60px;
  //     }
  //   }
  .bor {
    border-top: none;
  }
  .modal-descsay {
    font-family: "songti";
    color: #000000;
    border: 3px solid #999;

    h1 {
      font-size: 45px;
      border-bottom: 3px solid #999;
      font-weight: 400;
      padding: 10px 39px;
    }
    div {
      font-size: 45px;
      line-height: 68px;
      padding: 13px 39px 52px 39px;
    }
  }
  .footerpage {
    margin-top: 90px;
    font-size: 39px;
    text-align: right;
  }
  .footerdesc {
    margin-top: 452px;
    border-top: 3px dashed #999999;
    padding: 29px 15px;
    font-size: 32px;
    color: #777777;
    p {
      white-space: nowrap;
    }
  }
}
</style>
