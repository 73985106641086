<template>
  <div>
    <div
      class="image"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="pdfs"
    >
      <img :src="this.moreShow === 'enter' ? dayinImg : dayinMoreImg" alt="" />
    </div>
    <div class="page flex-col">
      <div class="main1 flex-col">
        <div class="group1 flex-row">
          <span class="txt1">{{ constants.hospitalName }}</span>
        </div>
        <span class="txt2">{{ constants.title }}</span>
        <div class="group2 flex-row">
          <span class="txt3">姓名：{{ constants.name }}</span>
          <span class="word1">性别：{{ constants.sex }}</span>
          <span class="word2">年龄：{{ constants.age }}岁</span>
          <span class="word3">教育年限：{{ constants.eduTotal }}年</span>
          <p class="word4">职业：{{ constants.job }}</p>
        </div>
        <div class="group3 flex-row">
          <span class="word5">编号：{{ constants.reportNo }}</span>
          <span class="word6">测评时间：{{ constants.reportDate }}</span>
        </div>
        <div class="group4 flex-col"></div>
        <div class="group5 flex-col">
          <div class="main2 flex-row">
            <div class="box1 flex-col">
              <span class="word7">序号</span>
              <span class="word8">1</span>
              <span class="info1">2</span>
              <span class="word9">3</span>
              <span class="word10">4</span>
              <span class="txt4">5</span>
              <span class="txt5">6</span>
              <span class="word11">7</span>
              <span class="word12">8</span>
              <span class="info2">9</span>
              <span class="word13">10</span>
              <span class="txt6">11</span>
              <span class="info3">12</span>
              <span class="word14">13</span>
              <span class="word15">14</span>
            </div>
            <div class="box2 flex-col"></div>
            <div class="box3 flex-col">
              <span class="info4">题目</span>
              <span class="word16">
                自己搭乘公共汽车（知道乘哪一路车，并能独自去）
              </span>
              <span class="info5">行走</span>
              <span class="txt7">自己做饭（包括生火）</span>
              <span class="word17">做家务</span>
              <span class="word18">吃药（能记住按时服药，并能正确服药）</span>
              <span class="word19">吃饭</span>
              <span class="info6">穿、脱衣服</span>
              <span class="info7">洗漱</span>
              <span class="word20">洗衣服</span>
              <span class="word21">洗澡</span>
              <span class="info8">购物</span>
              <span class="word22">上厕所</span>
              <span class="txt8">打电话</span>
              <span class="txt9">管理个人钱财</span>
            </div>
            <div class="box4 flex-col"></div>
            <div class="box5 flex-col">
              <span class="paragraph1">
                自己
                <br />
                完全可以
              </span>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM1 === 1"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM2 === 1"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM3 === 1"
                />
              </div>
              <div class="icon5">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM4 === 1"
                />
              </div>
              <div class="icon6">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM5 === 1"
                />
              </div>
              <div class="icon7">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM6 === 1"
                />
              </div>
              <div class="icon8">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM7 === 1"
                />
              </div>
              <div class="icon9">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM8 === 1"
                />
              </div>
              <div class="icon10">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM9 === 1"
                />
              </div>
              <div class="icon11">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM10 === 1"
                />
              </div>
              <div class="icon12">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM11 === 1"
                />
              </div>
              <div class="icon13">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM12 === 1"
                />
              </div>
              <div class="icon14">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM13 === 1"
                />
              </div>
              <div class="icon15">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM14 === 1"
                />
              </div>
            </div>
            <div class="box6 flex-col"></div>
            <div class="box7 flex-col">
              <span class="infoBox1">
                有些困难
                <br />
                自己尚能完成
              </span>
              <div class="label1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM1 === 2"
                />
              </div>
              <div class="label2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM2 === 2"
                />
              </div>
              <div class="label3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM3 === 2"
                />
              </div>
              <div class="label4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM4 === 2"
                />
              </div>
              <div class="label5">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM5 === 2"
                />
              </div>
              <div class="label6">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM6 === 2"
                />
              </div>
              <div class="label7">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM7 === 2"
                />
              </div>
              <div class="label8">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM8 === 2"
                />
              </div>
              <div class="label9">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM9 === 2"
                />
              </div>
              <div class="label10">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM10 === 2"
                />
              </div>
              <div class="label11">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM11 === 2"
                />
              </div>
              <div class="label12">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM12 === 2"
                />
              </div>
              <div class="label13">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM13 === 2"
                />
              </div>
              <div class="label14">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM14 === 2"
                />
              </div>
            </div>
            <div class="box8 flex-col"></div>
            <div class="box9 flex-col">
              <span class="word23">需要帮助</span>
              <div class="icon1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM1 === 3"
                />
              </div>
              <div class="icon2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM2 === 3"
                />
              </div>
              <div class="icon3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM3 === 3"
                />
              </div>
              <div class="icon4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM4 === 3"
                />
              </div>
              <div class="icon5">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM5 === 3"
                />
              </div>
              <div class="icon6">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM6 === 3"
                />
              </div>
              <div class="icon7">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM7 === 3"
                />
              </div>
              <div class="icon8">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM8 === 3"
                />
              </div>
              <div class="icon9">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM9 === 3"
                />
              </div>
              <div class="icon10">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM10 === 3"
                />
              </div>
              <div class="icon11">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM11 === 3"
                />
              </div>
              <div class="icon12">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM12 === 3"
                />
              </div>
              <div class="icon13">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM13 === 3"
                />
              </div>
              <div class="icon14">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM14 === 3"
                />
              </div>
            </div>
            <div class="box10 flex-col"></div>
            <div class="box11 flex-col">
              <span class="word24">根本不能做</span>
              <div class="label1">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM1 === 4"
                />
              </div>
              <div class="label2">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM2 === 4"
                />
              </div>
              <div class="label3">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM3 === 4"
                />
              </div>
              <div class="label4">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM4 === 4"
                />
              </div>
              <div class="label5">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM5 === 4"
                />
              </div>
              <div class="label6">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM6 === 4"
                />
              </div>
              <div class="label7">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM7 === 4"
                />
              </div>
              <div class="label8">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM8 === 4"
                />
              </div>
              <div class="label9">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM9 === 4"
                />
              </div>
              <div class="label10">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM10 === 4"
                />
              </div>
              <div class="label11">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM11 === 4"
                />
              </div>
              <div class="label12">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM12 === 4"
                />
              </div>
              <div class="label13">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM13 === 4"
                />
              </div>
              <div class="label14">
                <img
                  referrerpolicy="no-referrer"
                  :src="this.dui"
                  v-if="constants.object.adlM14 === 4"
                />
              </div>
            </div>
          </div>
          <div class="main3 flex-col"></div>
        </div>
      </div>
      <div class="main4 flex-col"></div>
      <div class="main5 flex-col"></div>
      <div class="main6 flex-col"></div>
      <div class="main7 flex-col"></div>
      <div class="main8 flex-col"></div>
      <div class="main9 flex-col"></div>
      <div class="main10 flex-col"></div>
      <div class="main11 flex-col"></div>
      <div class="main12 flex-col"></div>
      <div class="main13 flex-col"></div>
      <div class="main14 flex-col"></div>
      <div class="main15 flex-col"></div>
      <div class="main16 flex-col"></div>
    </div>
  </div>
</template>
<script>
import "../../../assets/font/songti/font.css";
import htmlToPdf from "@/assets/js/htmlToPdf";
export default {
  data() {
    return {
      constants: [],
      dui: require("../../../assets/img/dui.png"),
      dayinImg: require("../../../assets/img/dayin.png"),
      dayinMoreImg: require("../../../assets/img/dayinMore.png"),
      moreShow: "enter",
    };
  },
  methods: {
    // 鼠标划入
    mouseOver() {
      this.moreShow = "leave";
    },
    // 鼠标划出
    mouseLeave() {
      this.moreShow = "enter";
    },
    pdfs() {
      let t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      console.log(t, document.body.style.zoom);
      if (t !== 1 && t !== 1.5 && t !== 1.25) {
        // 如果进行了缩放，也就是不是1
        document.body.style.zoom = 1; // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
        alert(
          '您的浏览器目前处于缩放状态，页面和打印可能会出现错位现象，建议100%大小显示！ 快速恢复请按 Ctrl + "0"'
        );
      } else {
        // 导出PDF
        htmlToPdf.downloadPDF(
          document.querySelector(".page"),
          `${this.constants.name}_${this.constants.title}(回显)`
        );
      }
    },
  },
  mounted() {
    // console.log(this.$route.query.masterId);
    let afterUrl = window.location.search.substring(1);
    let path = afterUrl.substring(afterUrl.indexOf("=") + 1);
    this.$http
      .get(`report/getViewResult?masterId=${path}`)
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          // todo
          this.constants = res.data;
          if (
            this.constants.job === "" ||
            this.constants.job === undefined ||
            this.constants.job === null
          ) {
            this.constants.job = "--";
          }
        } else {
          this.$Message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style scoped lang='scss'>
/*stration*/
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: "songti";
}
button {
  margin: 0;
  padding: 0;
  border: 1 * 3.22px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.image {
  position: absolute;
  top: 200px;
  right: 200px;
  z-index: 352;
  img {
    width: 22 * 3.22px;
    height: 22 * 3.22px;
  }
}
.page {
  z-index: 1;
  position: relative;
  width: 595 * 3.22px;
  height: 842 * 3.22px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  justify-content: flex-start;
  padding-top: 48 * 3.22px;
  align-items: center;
  .main1 {
    z-index: auto;
    width: 507 * 3.22px;
    height: 621 * 3.22px;
    .group1 {
      z-index: auto;
      width: 363 * 3.22px;
      height: 22 * 3.22px;
      margin-left: 144 * 3.22px;
      justify-content: space-between;
      .txt1 {
        z-index: 127;
        width: 220 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 20 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 20 * 3.22px;
        text-align: center;
      }
      .icon1 {
        z-index: 129;
        width: 22 * 3.22px;
        height: 22 * 3.22px;
      }
    }
    .txt2 {
      z-index: 128;
      width: 160 * 3.22px;
      display: flex;
      justify-content: center;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 16 * 3.22px;
      font-family: "songti";
      white-space: nowrap;
      line-height: 16 * 3.22px;
      text-align: center;
      align-self: center;
      margin-top: 6 * 3.22px;
    }
    .group2 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 28 * 3.22px;
      .txt3 {
        z-index: 134;
        width: 72 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .word1 {
        z-index: 135;
        width: 48 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .word2 {
        z-index: 136;
        width: 60 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
        margin-left: 28 * 3.22px;
      }
      .word3 {
        z-index: 137;
        width: 84 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 28 * 3.22px;
      }
      .word4 {
        z-index: 138;
        width: 454px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
        margin-left: 15 * 3.22px;
      }
    }
    .group3 {
      z-index: auto;
      width: 507 * 3.22px;
      height: 12 * 3.22px;
      margin-top: 8 * 3.22px;
      justify-content: space-between;
      .word5 {
        z-index: 133;
        width: 96 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: left;
      }
      .word6 {
        z-index: 132;
        width: 156 * 3.22px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 12 * 3.22px;
        font-family: "songti";
        white-space: nowrap;
        line-height: 12 * 3.22px;
        text-align: right;
      }
    }
    .group4 {
      z-index: 131;
      width: 507 * 3.22px;
      height: 1 * 3.22px;
      background-color: rgba(153, 153, 153, 1);
      margin-top: 8 * 3.22px;
    }
    .group5 {
      z-index: 2;
      height: 496 * 3.22px;
      border: 1 * 3.22px solid rgba(153, 153, 153, 1);
      background-color: rgba(255, 255, 255, 1);
      margin-top: 12 * 3.22px;
      width: 507 * 3.22px;
      align-items: center;
      position: relative;
      .main2 {
        z-index: auto;
        width: 483 * 3.22px;
        height: 496 * 3.22px;
        justify-content: space-between;
        .box1 {
          z-index: auto;
          width: 28 * 3.22px;
          height: 478 * 3.22px;
          margin-top: 11 * 3.22px;
          .word7 {
            z-index: 11;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: center;
          }
          .word8 {
            z-index: 19;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 34 * 3.22px;
          }
          .info1 {
            z-index: 20;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 30 * 3.22px;
          }
          .word9 {
            z-index: 35;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 23 * 3.22px;
          }
          .word10 {
            z-index: 36;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 23 * 3.22px;
          }
          .txt4 {
            z-index: 120;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 30 * 3.22px;
          }
          .txt5 {
            z-index: 49;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 30 * 3.22px;
          }
          .word11 {
            z-index: 57;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .word12 {
            z-index: 65;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .info2 {
            z-index: 73;
            width: 6 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .word13 {
            z-index: 81;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .txt6 {
            z-index: 89;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .info3 {
            z-index: 97;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .word14 {
            z-index: 105;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
          .word15 {
            z-index: 112;
            width: 12 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: center;
            align-self: center;
            margin-top: 15 * 3.22px;
          }
        }
        .box2 {
          z-index: 3;
          width: 1 * 3.22px;
          height: 496 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: -3px;
        }
        .box3 {
          z-index: auto;
          width: 102 * 3.22px;
          height: 478 * 3.22px;
          margin-top: 11 * 3.22px;
          .info4 {
            z-index: 12;
            width: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 14 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 20 * 3.22px;
            text-align: left;
            align-self: center;
          }
          .word16 {
            z-index: 17;
            width: 102 * 3.22px;
            height: 42 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 19 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .info5 {
            z-index: 18;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 14 * 3.22px;
          }
          .txt7 {
            z-index: 33;
            width: 102 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 14 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .word17 {
            z-index: 34;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 14 * 3.22px;
          }
          .word18 {
            z-index: 119;
            width: 102 * 3.22px;
            height: 42 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: left;
            margin-top: 14 * 3.22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .word19 {
            z-index: 48;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .info6 {
            z-index: 56;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .info7 {
            z-index: 64;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word20 {
            z-index: 72;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word21 {
            z-index: 80;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .info8 {
            z-index: 88;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .word22 {
            z-index: 96;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .txt8 {
            z-index: 104;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
          .txt9 {
            z-index: 111;
            width: 102 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 12 * 3.22px;
            text-align: left;
            margin-top: 15 * 3.22px;
          }
        }
        .box4 {
          z-index: 4;
          width: 1 * 3.22px;
          height: 496 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: -3px;
        }
        .box5 {
          z-index: auto;
          width: 48 * 3.22px;
          height: 349 * 3.22px;
          margin-top: 7 * 3.22px;
          .paragraph1 {
            z-index: 13;
            width: 48 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon2 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 28 * 3.22px;
          }
          .icon3 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 28 * 3.22px;
          }
          .icon4 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 18 * 3.22px;
          }
          .icon5 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 18 * 3.22px;
          }
          .icon6 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 28 * 3.22px;
          }
          .icon7 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 25 * 3.22px;
          }
          .icon8 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
          .icon9 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
          .icon10 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 12 * 3.22px;
          }
          .icon11 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 12 * 3.22px;
          }
          .icon12 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
          .icon13 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 12 * 3.22px;
          }
          .icon14 {
            z-index: 21;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
          .icon15 {
            z-index: 50;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
        }
        .box6 {
          z-index: 5;
          width: 1 * 3.22px;
          height: 496 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: -3px;
        }
        .box7 {
          z-index: auto;
          width: 72 * 3.22px;
          height: 226 * 3.22px;
          margin-top: 7 * 3.22px;
          .infoBox1 {
            z-index: 14;
            width: 72 * 3.22px;
            height: 28 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            line-height: 14 * 3.22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .label1 {
            z-index: 26;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 30 * 3.22px;
          }
          .icon7 {
            z-index: 37;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 19 * 3.22px;
          }
          .label2 {
            z-index: 42;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 24 * 3.22px;
          }
          .label3 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 20 * 3.22px;
          }
          .label4 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 18 * 3.22px;
          }
          .label5 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 28 * 3.22px;
          }
          .label6 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 25 * 3.22px;
          }
          .label7 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
          .label8 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
          .label9 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 12 * 3.22px;
          }
          .label10 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 12 * 3.22px;
          }
          .label11 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label12 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label13 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label14 {
            z-index: 121;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
        }
        .box8 {
          z-index: 6;
          width: 1 * 3.22px;
          height: 496 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: -3px;
        }
        .box9 {
          z-index: auto;
          width: 48 * 3.22px;
          height: 370 * 3.22px;
          margin-top: 13 * 3.22px;
          .word23 {
            z-index: 15;
            width: 48 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 17 * 3.22px;
            text-align: center;
          }
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .icon1 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 33 * 3.22px;
          }
          .icon2 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 26 * 3.22px;
          }
          .icon3 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 20 * 3.22px;
          }
          .icon4 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 18 * 3.22px;
          }
          .icon5 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 28 * 3.22px;
          }
          .icon6 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 25 * 3.22px;
          }
          .icon7 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
          .icon8 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 12 * 3.22px;
          }
          .icon9 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
          .icon10 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 12 * 3.22px;
          }
          .icon11 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 12 * 3.22px;
          }
          .icon12 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
          .icon13 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .icon14 {
            z-index: 82;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 10 * 3.22px;
          }
        }
        .box10 {
          z-index: 7;
          width: 1 * 3.22px;
          height: 496 * 3.22px;
          background-color: rgba(153, 153, 153, 1);
          margin-top: -3px;
        }
        .box11 {
          z-index: auto;
          width: 60 * 3.22px;
          height: 478 * 3.22px;
          margin-top: 13 * 3.22px;
          .word24 {
            z-index: 16;
            width: 60 * 3.22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 12 * 3.22px;
            font-family: "songti";
            white-space: nowrap;
            line-height: 17 * 3.22px;
            text-align: center;
          }
          div {
            img {
              width: 16 * 3.22px;
              height: 16 * 3.22px;
            }
          }
          .label1 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 33 * 3.22px;
          }
          .label2 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 25 * 3.22px;
          }
          .label3 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 20 * 3.22px;
          }
          .label4 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 18 * 3.22px;
          }
          .label5 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 28 * 3.22px;
          }
          .label6 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 24 * 3.22px;
          }
          .label7 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 13 * 3.22px;
          }
          .label8 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label9 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label10 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label11 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label12 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label13 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
          .label14 {
            z-index: 106;
            width: 16 * 3.22px;
            height: 16 * 3.22px;
            align-self: center;
            margin-top: 11 * 3.22px;
          }
        }
      }
      .main3 {
        z-index: 8;
        position: absolute;
        left: 0;
        top: 42 * 3.22px;
        width: 1628.54px;
        height: 1 * 3.22px;
        background-color: rgba(153, 153, 153, 1);
      }
    }
  }
  .main4 {
    z-index: 9;
    position: absolute;
    left: 45 * 3.22px;
    top: 272 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main5 {
    z-index: 10;
    position: absolute;
    left: 45 * 3.22px;
    top: 299 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main6 {
    z-index: 31;
    position: absolute;
    left: 45 * 3.22px;
    top: 342 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main7 {
    z-index: 32;
    position: absolute;
    left: 45 * 3.22px;
    top: 369 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main8 {
    z-index: 118;
    position: absolute;
    left: 45 * 3.22px;
    top: 426 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main9 {
    z-index: 47;
    position: absolute;
    left: 45 * 3.22px;
    top: 453 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main10 {
    z-index: 55;
    position: absolute;
    left: 45 * 3.22px;
    top: 480 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main11 {
    z-index: 63;
    position: absolute;
    left: 45 * 3.22px;
    top: 507 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main12 {
    z-index: 71;
    position: absolute;
    left: 45 * 3.22px;
    top: 534 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main13 {
    z-index: 79;
    position: absolute;
    left: 45 * 3.22px;
    top: 561 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main14 {
    z-index: 87;
    position: absolute;
    left: 45 * 3.22px;
    top: 588 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main15 {
    z-index: 95;
    position: absolute;
    left: 45 * 3.22px;
    top: 615 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
  .main16 {
    z-index: 103;
    position: absolute;
    left: 45 * 3.22px;
    top: 642 * 3.22px;
    width: 1628.54px;
    height: 1 * 3.22px;
    background-color: rgba(153, 153, 153, 1);
  }
}
</style>
